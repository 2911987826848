import { useEffect, useState } from "react"
import { HcpProfileData } from "../../tp-core-types/HcpProfileData"
import DateService from "../../utilities/dateService"
import ProfileGrid from "../ProfileGrid/ProfileGrid"
import "./ProfileEmploymentHistory.scss"
import ProfileGridMobile from "../ProfileGrid/ProfileGridMobile"
import { ProfileEmploymentHistoryData } from "../../tp-core-types/EmploymentHistory"
import ProfileEmploymentHistoryModal from "./ProfileEmploymentHistoryModal"

type Props = {
    profileData: HcpProfileData,
    onChange: () => void
}

export default function ProfileEmploymentHistory(props: Props){
    
    const sortEmploymentRecords = (records: ProfileEmploymentHistoryData[]) => {
        const defaultStartDate = new Date('1000-1-1');
        const defaultEndDate = new Date('2888-1-1');

        return records.sort((a, b) => {
            const recordAStartDate = new Date(a.startDate || defaultStartDate);
            const recordBStartDate = new Date(b.startDate || defaultStartDate);
            const newEndDateA =  new Date(a.endDate || defaultEndDate);
            const newEndDateB = new Date(b.endDate || defaultEndDate);

            if(newEndDateB.valueOf() === newEndDateA.valueOf()) {
                return recordAStartDate.valueOf() < recordBStartDate.valueOf() ? 1 : -1;
            }
            return newEndDateA.valueOf() < newEndDateB.valueOf() ? 1 : -1;
        });
    }

    const [showNewRecordModal, setShowNewRecordModal] = useState<boolean>(false);
    const [employmentHistoryInfo, setEmploymentHistoryInfo] = useState<ProfileEmploymentHistoryData[]>(sortEmploymentRecords(props.profileData.employmentHistories));

    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }

    useEffect(() => setEmploymentHistoryInfo(sortEmploymentRecords(props.profileData.employmentHistories)), [props.profileData.employmentHistories]);
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[]);

    const openNewRecordModal = () => {
        setShowNewRecordModal(true)
    }  
    
    const handleSave = async (newData: ProfileEmploymentHistoryData) => {
        setShowNewRecordModal(false);
        props.onChange();        
    }

    return(
        <div id="profile-employment-history">
            {!isMobile && 
            <ProfileGrid 
                button={ { label: "New Employment Record", callback: openNewRecordModal }}
                noDataMessage="No employment history to display. Contact your recruiter for more information."
                containerHeader={"Employment History"} 
                itemHeaders={["Start/End", "Facility", "Profession and Specialty", "Employment Type"]} 
                items={employmentHistoryInfo.map((record) => {
                    return {
                        rows:
                            [
                            <div className="employment-history-dates" data-testid="emp-history-dates">
                                {DateService.getMonthDayYearByDate(record.startDate)?.month} {DateService.getMonthDayYearByDate(record.startDate)?.year} - {DateService.getMonthDayYearByDate(record.endDate)?.month ?? "Present"} {DateService.getMonthDayYearByDate(record.endDate)?.year ?? ""}
                            </div>,
                            <div className="employment-history-facility">{record.facilityName} {record.city && <>- {record.city}, {record.state}</>}</div>,
                            record.profession && <div className="emp-history-container">{record.profession}{record.specialties && record.specialties.length > 0 ? ':' : ''}
                                {record.specialties && <ul className="emp-history-ul">
                                    {record.specialties?.map((specialty, i) => {
                                        return (
                                            <li key={`${i}_${specialty}`}>{specialty}</li>
                                        )
                                    })}
                                </ul>}
                                {record.unitsFloated && <div className="emp-history-mobile-other">Other Responsibilities: 
                                    <ul className="emp-history-ul">
                                            {record.unitsFloated.split(",").map((unit, i) => {
                                                return (
                                                    <li key={`${i}_${unit}`}>{unit}</li>
                                                )
                                            }) || "None"}
                                    </ul>
                                            </div>
                                            
                                        } 
                                        
                            </div>,
                            <div className="employment-history-type-container">
                                <div className="employment-history-type">{record.employmentType}</div>
                                <div>
                                    {record.agencyName &&
                                    <div>Staffing Agency: {record.agencyName}</div>
                                    }
                                </div>
                            </div>
                            ]
                        }
                    })
                } />
            }
            {isMobile && 
            <ProfileGridMobile 
            noDataMessage="No employment history to display. Contact your recruiter for more information."
            parentContainerHeader={"Employment History"} 
            button={ { label: "New Employment Record", callback: openNewRecordModal }}
            containers={
                employmentHistoryInfo.map(record => {
                    return {
                        header: `${record.facilityName} ${record.city}, ${record.state}`,                        
                        data: {
                            headers: ['Start/End', "Profession and Specialty", "Employment Type"],
                            id: record.employmentHistoryId?.toString(),
                            items: [
                                <div data-testid="emp-history-dates">{record.startDate ? `${DateService.getMonthDayYearByDate(record.startDate)?.month} ${DateService.getMonthDayYearByDate(record.startDate)?.year} - ${DateService.getMonthDayYearByDate(record.endDate)?.month ?? "Present"} ${DateService.getMonthDayYearByDate(record.endDate)?.year ?? ""}` : "-"}</div>,
                                record.profession ? <>
                                    <div>{record.profession}</div>
                                    <div>{record.specialties && 
                                        record.specialties?.map((specialty, i) => {
                                            return (
                                                <li key={`${i}_${specialty}`}>{specialty}</li>
                                            )
                                        })                               
                                        }</div>
                                        {record.unitsFloated && <div className="emp-history-mobile-other">Other Responsibilities: 
                                            {record.unitsFloated.split(",").map((unit, i) => {
                                                return (
                                                    <li key={`${i}_${unit}`}>{unit}</li>
                                                )
                                            }) || "None"}
                                            </div>
                                        }                                
                                </>: "-",
                                record.employmentType
                            ]
                        }
                    }
                })
            } 
            />}
            {showNewRecordModal && <ProfileEmploymentHistoryModal 
                cancelCallback={() => setShowNewRecordModal(false)} 
                saveCallback={handleSave} 
                recordNumber={props.profileData.employmentHistories?.length + 1} 
            />}
        </div>
    )
}