import { useEffect, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import "./Identification.scss";
import DatePickerWrapper from "../../date-picker-wrapper/DatePickerWrapper";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { HcpIdentification } from "../../tp-core-types/HcpIdentification";
import ErrorMesage from "../../error-message/inlilne-error-message/ErrorMessage";

export default function Identification() {
    const today = new Date();

    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
    const [socialSecurityNumber, setSocialSecurity] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<Date | null | undefined>(null);
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);

    function isValidSocialSecurityNumber()
    {
        const ssn = parseInt(socialSecurityNumber)
        return socialSecurityNumber !== ""
            //&& ssn.toString().length === socialSecurityNumber.trim().length
            && ssn !== null 
            && ssn !== undefined
            && !isNaN(ssn) 
            && socialSecurityNumber.length === 9
    }

    function isValidDate()
    {
        return (dateOfBirth === null || dateOfBirth === undefined) ? false : dateOfBirth < new Date();
    }

    async function handleSave() {
        if(isFormComplete) {
            const identification: HcpIdentification = {
                SocialSecurityNumber: socialSecurityNumber,
                DateOfBirth: dateOfBirth as Date
            }
            await TPCoreAPI.postHcpIdentification(identification)
        }
    }

    function stripNonNumbers(e: any) {
        const regex = new RegExp(/^\d+$/);
        if(regex.test(e.target.value) || e.target.value === ""){
            setSocialSecurity(e.target.value);
        }
    }

    function ssnFocus(e: any) {
        e.target.type = 'text';
    }

    function ssnBlur(e: any) {
        e.target.type = 'password';
        !isValidSocialSecurityNumber() 
            ? setShowValidationMessage(true) 
            : setShowValidationMessage(false);
    }

    useEffect(() =>{
        if(isValidSocialSecurityNumber() && isValidDate())
        {
            setIsFormComplete(true);
        }
        else{
            setIsFormComplete(false);
        }

    },[socialSecurityNumber, dateOfBirth])

    return (
        <>
            <div id="identification-info">
                <NavigationHeader selectedStep={NavigationSteps.Identification}  showNavigationBar={true} />
                <div className="application-header">
                    <PreviousNextButtonHeader
                        selectedStep={NavigationSteps.Identification}
                        isFormComplete={isFormComplete}
                        callbackOnSave={() => handleSave()}
                    />
                    <div className="title">
                        Identification
                    </div>
                </div>
                <div className="application-page-container" data-testid="identification-info-page-container">
                    <div className="form-container">
                        <div className="social-security-container">
                            <label htmlFor="social-security-input">Social Security Number*</label>
                            <input id="social-security-input" className="social-security-input" pattern="[0-9]*" type="password" inputMode="numeric" maxLength={9} value={socialSecurityNumber} onChange={stripNonNumbers} onFocus={ssnFocus} onBlur={ssnBlur}></input>
                            { showValidationMessage &&
                                <ErrorMesage message="Please enter a 9 digit social security number"></ErrorMesage>
                            }
                            <div className="social-security-text">
                                <div className="why-question-text">Why do we need this?</div>
                                <div>We use your social security number when running background checks.</div>
                            </div>
                        </div>
                        <div className="dob-container">
                            <label htmlFor="dob-picker">Date of Birth*</label>
                            <div className="dob-input">
                                <DatePickerWrapper 
                                    inputId="dob-picker" 
                                    value={dateOfBirth} 
                                    onChange={(event) => setDateOfBirth(event.target.value)}
                                    maxDate={today}
                                    />
                            </div>
                        </div>
                        <div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}