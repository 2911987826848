import { useContext, useState } from "react";
import alert_diamond from "../assets/alert_diamond.svg";
import "./JobClosedHeader.scss";
import AllJobFiltersContainerDesktop from "../all-job-filters-container-desktop/AllJobFiltersContainerDesktop";
import { PloppableContext } from "../utilities/context/PloppableContextProvider";
import AllJobFiltersContainerMobile from "../all-job-filters-container-mobile/AllJobFiltersContainerMobile";

type Props = {
    setSearchForMoreJobsVisibility?: (visibility: boolean) => void
}

export default function JobClosedHeader(props: Props) {
    const [showFiltersModal, setFiltersModal] = useState<boolean>(false);
    const isPloppable = useContext(PloppableContext).isPloppable;
    const isMobile = window.matchMedia("(max-width: 800px)").matches;

    const toggleSearchForMoreJobsModal = (visibility: boolean) => {
        setFiltersModal(visibility);
        props.setSearchForMoreJobsVisibility && props.setSearchForMoreJobsVisibility(visibility)
    }

    return (
        <div className="closed-header-container" data-testid="closed-header-container">
            <div className="alert-container">
                <img className="alert-image" src={alert_diamond} data-testid="alert-diamond-img" alt="alert"></img>
                <div className="alert-text" data-testid="closed-header-text">
                    This job has closed
                </div>
            </div>

            { isPloppable &&
                <div className="search-for-jobs-link" onClick={() => toggleSearchForMoreJobsModal(true)}>Search for more jobs</div>
            }

            { isPloppable && showFiltersModal && !isMobile &&
                    <div className="alljobfilters-desktop" >
                        <AllJobFiltersContainerDesktop 
                            showFiltersContainer={showFiltersModal} 
                            onToggle={() => toggleSearchForMoreJobsModal(!showFiltersModal)}
                            searchPageUrl={"/careers/find-a-job"}
                            titleText="Search for more jobs"
                        ></AllJobFiltersContainerDesktop>
                    </div>
            }
            {isPloppable && showFiltersModal && isMobile &&
                <div>
                    <AllJobFiltersContainerMobile 
                        showFiltersContainer={showFiltersModal} 
                        onToggle={() => toggleSearchForMoreJobsModal(!showFiltersModal)}
                        searchPageUrl={"/careers/find-a-job"}
                        titleText={"Search for More Jobs"}
                    ></AllJobFiltersContainerMobile>
                </div>
            }
        </div>
    );

}
