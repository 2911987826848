import React, { useState } from "react";
import { ReactNode } from "react";

export type ErrorContextType = {error: Error | null, setError: (error: Error) => void, clearError: () => void}
export const ErrorContext = React.createContext<ErrorContextType>({error: null, setError: () => {return}, clearError: () => {return}})

export function ErrorContextProvider({children} : {children : ReactNode}) {
    const [error, setError] = useState<Error | null>(null);

    return (
    <>
        <ErrorContext.Provider value={{error: error, setError: (error) => {setError(error)}, clearError: () => {setError(null)}}}>
            {children}
        </ErrorContext.Provider>
    </>)
}