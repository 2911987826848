import alert_diamond from "../../assets/alert_diamond.svg"
import { useError } from "../../utilities/context/useError"
import "./ModalErrorMessage.scss"

export default function ModalErrorMesage(){
    const {error, clearError} = useError();

    return(
        <div id="ModalErrorMessage">
            <img className="hidden-preload-image"src={alert_diamond} data-testid="alert-diamond-img" alt=""></img>
            {error &&
                <div id="ModalErrorMessage">
                    <div className="background">
                        <div className="modal">

                            <div className="header">
                                Something went wrong...
                            </div>

                            <div className="body">
                                <div className="message-container">
                                    <div>
                                        <img className="error-image"src={alert_diamond} data-testid="alert-diamond-img" alt=""></img>
                                    </div>            
                                    <div className="error-message">
                                        {error.message} 
                                    </div>
                                </div>
                                
                                <button className="close-button" onClick={clearError}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }   
        </div>
    )
}