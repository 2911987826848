import { useState, useContext } from "react";
import trsLogo from "../assets/trs_logo.svg";
import "./DesktopTRSHeader.scss";
import LoginModal from "../login-modal/LoginModal";
import UserMenu from "./UserMenu";
import UserMenuModal from "./UserMenuModal";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import FeatureFlagContext from "../tp-core-types/FeatureFlagsModel";


type Props = {
    setShowMenuModal: (isVisible: boolean) => void;
    showUserMenuModal: boolean;
    applicationComplete: boolean;
};

export default function DesktopTRSHeader(props: Props) {
    const userInfoModel = useContext(UserInfoModel);
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const ff = useContext(FeatureFlagContext);
    
    const showDialog = () => {
        setShowLoginModalVisibility(true);
    };

    const closeDialog = () => {
        setShowLoginModalVisibility(false);
    };

    return (
        <div id="DesktopTRSHeader" data-testid="desktop-header">
            <LoginModal
                isVisible={showLoginModalVisibility}
                setVisibility={(value: boolean) => setShowLoginModalVisibility(value)}
                successfulLogin={() => new Promise<void>(closeDialog)}
                isLoginForm={true}
                headerText={"Log into your account or sign up below"}
                canBeDismissed={true}
            />

            <div className="desktop-page-header">
                <a data-testid="trs-logo" href={`${process.env.REACT_APP_HOMEPAGE_URL}`}>
                    <img className="trs-logo-image" src={trsLogo} alt="TRS Logo" />
                </a>
                <div className={props.applicationComplete ? ff.featureFlag.featureFlag_HubIsSunset ? "right-side-header-items-logged-in-alt" : "right-side-header-items-logged-in" : "right-side-header-items-incomplete-application"}>
                    {!ff.featureFlag.featureFlag_HubIsSunset && props.applicationComplete && 
                        <a data-testid="go-to-travelerhub-link" href={process.env.REACT_APP_TRAVELER_HUB_URL}>
                            <button data-testid="go-to-travelerhub-button" className="go-to-travelerhub-button">
                                Go to Traveler Hub
                            </button>
                        </a>
                    }
                    <UserMenu
                        showLoginDialog={showDialog}
                        setShowMenuModal={props.setShowMenuModal}
                        showUserMenuModal={props.showUserMenuModal}
                    />
                </div>
            </div>
            {props.showUserMenuModal && <UserMenuModal applicationComplete={props.applicationComplete} setShowUserMenuModal={props.setShowMenuModal} />}
        </div>
    );
}
