import { MouseEvent, useEffect, useState } from "react";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import TPCoreAPICommon from "../tp-core-api-common/TPCoreAPICommon";
import { useError } from "../utilities/context/useError";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { useLocation, useNavigate } from "react-router-dom";
import "./SignContract.scss"
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import TPCoreAPIDocuments from "../tp-core-api-common/TPCoreAPIDocuments";


export default function SignContract() {
    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    const navigate = useNavigate();
    const {setError} = useError();
    const location = useLocation();
    const [contractId, setContractId] = useState<number | undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [images, setImages] = useState<string[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    async function getFile() {
        if(location && location.state && location.state.contractId && location.state.documentMasterId) {
            setContractId(location.state.contractId);
            TPCoreAPIDocuments.getContractDocumentImages(location.state.contractId, location.state.documentMasterId).then((res: string[]) => {
                setLoading(false);
                setImages(res);
            }).catch(()=>{
                setError(new Error("It looks like something went wrong. Please try again."));
                navigate(location.state.returnLocation)
                });        
        }
        else{
            setError(new Error("It looks like something went wrong. Please try again."));
            navigate("/dashboard");
        }
    }

    useEffect(() => {
        getFile();
    }, [])

    async function handleSubmit(e:MouseEvent<HTMLButtonElement, unknown>) {
        setButtonDisabled(true);
        setLoading(true);
            if(contractId) {
                await TPCoreAPICommon.eSignContractDocument(contractId, location.state.documentMasterId).then(() => {
                    navigate(location.state.returnLocation)
                })
                .catch(() => {
                    setError(new Error("It looks like something went wrong. Please try again."))
                    navigate(location.state.returnLocation)
                });
            } 
            else {
                setError(new Error("It looks like something went wrong. Please try again."))
                navigate("/dashboard")
            }
    }

    return (
        <div id="ReviewAndSignContract">
            <GenericPageHeader title="Review and Sign Document"></GenericPageHeader>
            {loading === true ? <LoadingOverlay/> :
            <>
                <div className="page-container" >
                <div className="button-container">
                        <div className="checkbox-header-container">
                            <div className="checkbox-container">
                            <input type="checkbox" data-testid="agree-checkbox" id="agree-checkbox" className="agree-checkbox checkbox" onChange={() => setButtonDisabled(!buttonDisabled)}></input>
                                <label htmlFor="agree-checkbox">
                                    I have reviewed and agree to sign electronically.
                                </label>
                            </div>
                        </div>
                        <button className="sign-contract-button" disabled={buttonDisabled} onClick={e => handleSubmit(e)}>Sign Contract</button>
                        {isMobile && <hr/>}
                    </div>
                    {
                        !loading && images.length > 0 &&
                            <>
                                <div className="images-container" data-testid="mobile-contract-view">
                                    { images.map((i) => {
                                        return (
                                            <div className="image-wrapper">
                                                <img key={i} alt="contractImage" src={i}></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                    }
                </div>
            </>
        }
        </div>
    );
}


