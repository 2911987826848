import { MouseEvent, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import "./TermsAndConditions.scss"
import TPCoreAPICommon from "../../tp-core-api-common/TPCoreAPICommon";
import { useError } from "../../utilities/context/useError";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserInfoContext from "../../tp-core-types/UserInfoModel";

export default function TermsAndConditions (){
    const userContext = useContext(UserInfoContext);
    const navigate = useNavigate();
    const agree = "agreeToTermsAndConditions";
    const submitApplicationId="submitApplicationId";
    const [isSubmitApplicationDisabled, setIsSubmitApplicationDisabled] = useState<boolean>(true);
    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const {setError} = useError()


    async function openFile(documentName:string){
        let waitingPage;
        try{
            waitingPage = window.open("document-loading.html")!;
            const myData= (await TPCoreAPICommon.getDocument(documentName));
            const file = window.URL.createObjectURL(myData);
            waitingPage.location.href = file;
        }
        catch{
            waitingPage?.close();
        }
        
    }

    async function handleSubmit(e:MouseEvent<HTMLButtonElement, unknown>) {
        e.currentTarget.disabled = true;
        setIsSubmitApplicationDisabled(true);
        try{
            await TPCoreAPI.saveSubmitApplication();
            userContext.refreshUserInfo();
            navigate("/dashboard")
        }
        catch {
            setIsSubmitApplicationDisabled(false);
            setError(new Error("It looks like something went wrong. Please try again."))
        }
        
    }
     
    return(
    <div id="TermsAndConditions" data-testid="TermsAndConditions">
         <NavigationHeader selectedStep={NavigationSteps.TermsAndConditions} showNavigationBar={false} />
         <div className="terms-and-conditions-page-wrapper">
            <div className="header">
                <div className="title">Terms, Conditions and Pre-Employment Documents
                { isMobile &&
                    <button className="submitButton" disabled={isSubmitApplicationDisabled} onClick={e => handleSubmit(e)}>
                        Submit Application to TRS
                    </button>
                }
                </div>
            </div>
            <div className="page-container">

            <div className="checkbox-header-container">
                <div className="checkbox-container">
                    <input className="application-checkbox checkbox" id={agree} data-testid="iAgree" type="checkbox" onChange={() => setIsSubmitApplicationDisabled(!isSubmitApplicationDisabled)} />
                    <label htmlFor={agree}>I agree to all the following:</label>
                 </div>
                {!isMobile &&
                    <button id={submitApplicationId} className="submitButton" disabled={isSubmitApplicationDisabled} onClick={e => handleSubmit(e)}>Submit Application to TRS</button>
                }
            </div>
            <div className="terms-and-conditions-container">
                <div className="terms-and-conditions-scroll-container">
                    <ul className="terms-and-conditions-list">
                        <li>The information provided herein for participation with TRS Healthcare is true, correct and complete. </li>
                        <li>I acknowledge that any misstatement or ommission of facts on the resume will result in the termination of my relationsip with TRS Healthcare.</li>
                        <li>I authorize TRS Healthcare to release this information and reference information to TRS Healthcare Client Facilities.</li>
                        <li>I understand that by giving TRS Healthcare permission to submit my information for assignment opportunities I am also agreeing to any criminal background search and pre-employment drug screening that may be required by certain states or Client Facilities.</li>
                        <li>I authorize TRS Healthcare to investigate all information contained herein and secure any information from all my employers, references, and academic institutions and to release this information to any Client Facility where I may be considered for a travel work assignment.</li>
                        <li>I hereby release TRS Healthcare from any/all liability of any kind and nature which, at any time, could result from obtaining and having a decision based on such information. I also understand that neither the completion of these forms nor any other part of my consideration for assigments establishes any obligation for TRS Healthcare to locate assignments for me.</li>
                        <li>I understand that an employment relationship begins with my first shift. I also understand that I must contact TRS Healthcare at the conclusion of my assignment to maintain my active employment relationship.</li>
                        <li>I have received the <span className="document" onClick={() => openFile("Handbook")}>Healthcare Professional Handbook</span> and agree to abide by the policies.</li>
                        <li>I consent to unemployment and disability insurance coverage under the Arkansas Employment Security Law.</li>
                        <li>TRS Healthcare is an equal opportunity employer.</li>
                        <li >I have also read and agree to:</li>
                        <ul className="terms-and-conditions-sublist">
                            <li><div className="document" onClick={() => openFile("AuthForRelease")}>Authorization for the Release of Information</div></li>
                            <li><div className="document" onClick={() => openFile("CandidatePlacement")}>Candidate Placement Agreement</div></li>
                            <li><div className="document" onClick={() => openFile("HealthInfoPrivacy")}>HIPAA Agreement</div></li>
                            <li><div className="document" onClick={() => openFile("ConsumerReportDisclaimer")}>Federal Disclosure</div></li>
                            <li><div className="document" onClick={() => openFile("FairCredit")}>Federal Summary of Rights</div></li>
                            <li><div className="document" onClick={() => openFile("CaliRights")}>California Disclosure</div></li>  
                            <li><div className="document" onClick={() => openFile("CaliBackground")}>CA Authorization for Backgrounds</div></li>
                            <li><div className="document" onClick={() => openFile("CaliAppStatement")}>CA Applicants Statement and Agreement</div></li>
                            <li><div className="document" onClick={() => openFile("Arbitration")}>Arbitration Agreements</div></li>
                            <li><div className="document" onClick={() => openFile("ElectronicSig")}>Electronic Signature and Communications Agreement</div></li>
                        </ul>
                    </ul>
                </div>
            </div>
            </div>
        </div>

    </div>)
}