import { UserHubData } from "../tp-core-types/UserHubData";

export const Session = {
    getHcpLoginToken : () : string => {
        const cookies: string[] = document.cookie.split(';')

        for (let index = 0; index < cookies.length; index++) {
            const cookie = cookies[index];
            const keyValuePair = cookie.split('=')
            if (keyValuePair[0].trim() === `${process.env.REACT_APP_COOKIE_ENV}_hcpLoginToken`){
                return keyValuePair[1]
            }
        }

        return "";
    },
    getHcpHubData : () : string => {
        const cookies: string[] = document.cookie.split(';')

        for (let index = 0; index < cookies.length; index++) {
            const cookie = cookies[index];
            const keyValuePair = cookie.split('=')
            if (keyValuePair[0].trim() === `${process.env.REACT_APP_COOKIE_ENV}_hubData`){
                return keyValuePair[1]
            }
        }

        return "";
    },
    getUtmData : (utmPart:string) : string => {
        const cookies: string[] = document.cookie.split(';')

        for (let index = 0; index < cookies.length; index++) {
            const cookie = cookies[index];
            const keyValuePair = cookie.split('=')
            if (keyValuePair[0].trim() === `${utmPart}`){
                return keyValuePair[1]
            }
        }

        return "";
    },
    setHcpLoginToken : (token : string) : void => {
        document.cookie = `${process.env.REACT_APP_COOKIE_ENV}_hcpLoginToken=${token};domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/;`;

    },
    setHcpHubLoginData : (data : UserHubData) : void => {
        document.cookie=`${process.env.REACT_APP_COOKIE_ENV}_hubData=${JSON.stringify(data)};domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/;`;
    },

    clearHcpLoginToken : () : void => {                                                                                
        document.cookie=`${process.env.REACT_APP_COOKIE_ENV}_hcpLoginToken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/;`;
        document.cookie=`${process.env.REACT_APP_COOKIE_ENV}_hubData=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/;`;
        document.cookie=`.FormName=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=/;`;

    },

    isHcpLoggedIn : () : boolean => {
        return Session.getHcpLoginToken() !== "";
    },
    getUtmSource: (): string | null => {
        const utmSource = Session.getUtmData("utm_source") || null;
        return utmSource;
    }
}
