import { useEffect, useState } from "react";
import { LeadSourcesModel } from "../tp-core-types/LeadSourcesModel";


type Props = {
    leadSources: LeadSourcesModel[],
    onLeadsourceChange:(leadSource: LeadSourcesModel)=>void

}

export default function SelectLeadSources(props: Props) {
    const [leadSourceGroupName, setLeadSourceGroupName] = useState<string[]>([])
    

    useEffect(()=>{
        function getAndSetGroupNames() {
            const unquineWebGroups: Set<string> = new Set();
    
            props.leadSources.sort((a, b) => (a.webOrder ?? 0) - (b.webOrder ?? 0)).forEach((leadSource)=>{
                unquineWebGroups.add(leadSource.webGroup);
            })
            setLeadSourceGroupName(Array.from(unquineWebGroups))
        }

        getAndSetGroupNames();
    },[props.leadSources])


    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filteredLeadSources = props.leadSources.filter(x => x.id.toString() === event.target.value);
        if(filteredLeadSources[0]) {
         
            props.onLeadsourceChange(filteredLeadSources[0]);
        }
    };

    return <>
        <label htmlFor="leadsources" className="label">How did you hear about us?</label>
        <select id="leadsources" onChange={handleSelectChange} data-testid="source-select" >
            <option disabled selected key="default"  value={-1}></option>
        {leadSourceGroupName.map((groupName, i)=>(
            <optgroup key={i} label={groupName}>{
                props.leadSources.filter((source)=>        
                    source.webGroup === groupName        
                ).sort((a, b) => (a.webOrder ?? 0) - (b.webOrder ?? 0))
                .map((source)=>(
                    <option data-group={source.webGroup} key={source.id} value={source.id}>{source.webFriendlyName}</option>
                ))
            }</optgroup>
        ))}
        </select>
    </>
}