import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import "./CredentialsDropbox.scss"
import plus_sign from "../assets/plus_sign_white.svg";
import { useEffect, useRef, useState } from "react";
import { DropboxItem } from "../tp-core-types/DropboxDocumentsResult";
import TPCoreAPIDocuments from "../tp-core-api-common/TPCoreAPIDocuments";
import { DropboxUpload, DropboxUploadResult } from "../tp-core-types/DropboxUpload";
import { DropboxAttach } from "../tp-core-types/DropboxAttach";
import DropboxDocument from "./DropboxDocument";
import LoadingOverlay from "../../src/loading-overlay/LoadingOverlay"
import { useError } from "../utilities/context/useError";
import DragNDrop from "./DragNDrop";
import DesktopUploadModal from "./DesktopUploadModal";
import MobileUploadModal from "./MobileUploadModal";



export default function CredentialsDropbox() {
    const isMobile = window.matchMedia("(max-width: 1249px)").matches;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setError } = useError();
    const acceptableTypes: string[] = ["pdf", "doc", "docx", "jpg", "jpeg", "png", "gif", "bmp", "jfif"];
    const [dropboxItems, setDropboxItems] = useState<DropboxItem[]>([] as DropboxItem[]);
    const [file, setFile] = useState<DropboxUploadResult | undefined>();
    const [uploadedFileName, setUploadedFileName] = useState<string>("");
    const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false);
    const [attachingFile, setAttachingFile] = useState<boolean>(false);

    useEffect(() => {
        fetchDocuments()
    }, [])

    const fetchDocuments = async () => {
        const res = await TPCoreAPIDocuments.getDropboxDocuments();
        setDropboxItems(res.dropboxItems)
    }

    const handleAddDocument = async (label: string) => {
        setAttachingFile(true)
        const attachment = {
            filepath: file?.filePath,
            documentTitle: label
        } as DropboxAttach;
        await TPCoreAPIDocuments.attachDropboxDocument(attachment);
        setFile(undefined);
        fetchDocuments();
        setAttachingFile(false);
        setShowAddDocumentModal(!showAddDocumentModal);
    }

    const initiateFileUpload  = () => {
        const btn = document.getElementById("file-picker");
        btn?.click();
    }

    const handleFileInputChange = async (file: File | null) => {
        if( file ) {
            try{
                const fileType = file?.name.split('.').pop() ?? "";
                if(acceptableTypes.includes(fileType.toLowerCase())) {
                    setUploadedFileName(file.name)
                    setShowAddDocumentModal(!showAddDocumentModal)
                    const upload = {
                        file: file,
                        fileType: fileType
                    } as DropboxUpload

                    const result = await TPCoreAPIDocuments.uploadDropboxDocument(upload)
                    setFile(result);
                    setUploadedFileName(file.name)
                }
                else {
                    setError(new Error(`Invalid file type: .${fileType}`))
                }
            }
            catch {
                setShowAddDocumentModal(false)
                setFile(undefined)
                setUploadedFileName("");
                setError(new Error("Error uploading file - please try again."))
            }

        }
    }

    const handleChangeFile = () => {
        setShowAddDocumentModal(false);
        setFile(undefined);
        initiateFileUpload();
    }

    const closeModal = () => {
        setShowAddDocumentModal(false);
        setFile(undefined);
        setUploadedFileName("");
    }

    const deleteDocument = async (hcpDropboxId: number) => {
        const newItems = dropboxItems.filter((item) => item.hcpDropBoxId !== hcpDropboxId);
        setDropboxItems(newItems);
        await TPCoreAPIDocuments.deleteDropboxDocument(hcpDropboxId);
    }

    async function openFile(filePath: string){
        let waitingPage;
        try {
            setIsLoading(true);
            //waitingPage = window.open("document-loading.html")!;
            const myData = (await TPCoreAPIDocuments.getDocumentAttachment(filePath));
            console.log("Got data - launching window");
            const file = window.URL.createObjectURL(myData);
            //waitingPage.location.href = file;
            window.open(file);
            setIsLoading(false);
        }
        catch{
            setError(new Error("Something went wrong when opening the file."))
            //waitingPage?.close();
            setIsLoading(false);
        }
    }

    return (

        <div id="CredentialsDropbox">
            {isLoading &&
                <LoadingOverlay displayText="Loading Document"></LoadingOverlay>
            }
         { showAddDocumentModal &&
         <>
            {isMobile
                ? <MobileUploadModal attaching={attachingFile} closeModal={closeModal} handleChangeFile={handleChangeFile} file={file} uploadedFileName={uploadedFileName} handleAddDocument={handleAddDocument}/>
                : <DesktopUploadModal attaching={attachingFile} closeModal={closeModal} handleChangeFile={handleChangeFile} file={file} uploadedFileName={uploadedFileName} handleAddDocument={handleAddDocument}/>
            }
         </>
        }
        <GenericPageHeader title="Credentials Dropbox"></GenericPageHeader>

        <div className="upload-button-container">
            { !isMobile &&
                <div className="sub-title">Drag and drop documents into the area below or upload from device</div>
            }
            <button onClick={() => initiateFileUpload()} className="upload-button">
                <img alt="plus-sign" src={plus_sign}></img>
                <div>Upload Document</div>
            </button>
        </div>
        <div className="page-container">

            <div className="upload-document-container">
                {isMobile
                ? <input
                    type="file"
                    hidden
                    id="file-picker"
                    data-testid="file-picker"
                    onChange={(e) => handleFileInputChange(e.target.files ? e.target.files[0] : null)}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp"
                />
                : <DragNDrop onFilesSelected={handleFileInputChange}/>
                }
            </div>
            <div className="uploaded-documents-container">
                {!isMobile && <div className="sub-title">Uploaded Documents</div>}
                <div data-testid="uploaded-documents" className="uploaded-documents">
                    {
                        dropboxItems.length > 0 && dropboxItems.map(d => {
                            return <DropboxDocument key={d.hcpDropBoxId} document={d} deleteDocument={deleteDocument} openDocument={openFile}/>
                        })
                    }
                </div>
            </div>
        </div>
    </div>
    )
}