import { useEffect, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import { ReferenceFormFacilityOption } from "../../tp-core-types/ReferenceFormFacilityOption";
import NavigationHeader from "../navigation-header/NavigationHeader";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import "./References.scss";
import ReferenceForm from "./reference-form";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { HcpReferences, ReferenceFormCallbackPayload } from "../../tp-core-types/HcpReferences";
import { Validator } from "../../validator/Validator";

export default function References() {
    const [recentHistory, setRecentHistory] = useState<ReferenceFormFacilityOption[]>([]);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [references, setReferences] = useState<ReferenceFormCallbackPayload[]>([{
        firstName: "",
        lastName: "",
        title: "",
        phoneNumber: "",
        historyId: "",
        email: "",
        phoneType: "",
        referenceNumber: 0,
        facility: "",
        facilityCity: "",
        facilityState: ""
    },
    {
        firstName: "",
        lastName: "",
        title: "",
        phoneNumber: "",
        historyId: "",
        email: "",
        phoneType: "",
        referenceNumber: 1,
        facility: "",
        facilityCity: "",
        facilityState: ""
    }])
    async function handleSave(): Promise<boolean> {
        return (await TPCoreAPI.postHcpReferences(references)).ok
    }

    function updateFormData(index: number, formData: ReferenceFormCallbackPayload){
        const referencesCopy : ReferenceFormCallbackPayload[] = [...references]
        referencesCopy[index] = formData
        setReferences(referencesCopy);
    }
    
    useEffect(() => {
        checkFormComplete();
    }, [references, references[0], references[1]])

    const isFormComplete = (formData: ReferenceFormCallbackPayload) => {
        const emailIsValidOrEmpty = Validator.validateEmail(formData.email) || formData.email === "";

        return emailIsValidOrEmpty
            && fieldHasValue(formData.historyId)
            && fieldHasValue(formData.firstName) 
            && fieldHasValue(formData.lastName)
            && fieldHasValue(formData.title)
            && fieldHasValue(formData.phoneNumber)
            && fieldHasValue(formData.phoneType)
      };

    function fieldHasValue(value: string): boolean {
        return value !== "" && value !== null && value !== undefined
    }

    function checkFormComplete(){
        const form1Complete = isFormComplete(references[0]);
        const form2Complete = isFormComplete(references[1]);
        if(form1Complete && form2Complete){
            setFormIsValid(true);
        }else{
            setFormIsValid(false);
        }
    }

    const getHcpReferences = async () => {
        const hcpReferences : HcpReferences = await TPCoreAPI.getHcpReferences();
        if(hcpReferences.references.length == 2) {
            setReferences(hcpReferences.references.sort((a, b) => a.referenceNumber < b.referenceNumber ? -1 : 1));
        }
    }

    useEffect(()=>{
        LoadRecentHistory();
        getHcpReferences();
        
    },[]);
    
    async function LoadRecentHistory(){
        const results = await TPCoreAPI.getRecentEmploymentHistory();
        setRecentHistory(results.recentRecords);
    }
    return (
        <div id="hcp-references">
            <NavigationHeader selectedStep={NavigationSteps.References}  showNavigationBar={true} />
            <div className="application-header">
                <PreviousNextButtonHeader
                    selectedStep={NavigationSteps.References}
                    isFormComplete={formIsValid}
                    callbackOnSave={handleSave}
                />
                    <div className="title">
                        References
                    </div>
                </div>
                <div className="application-page-container">
                    <div className="reference-body">
                        <span className="application-helper-text">
                                We need two references from <span className="emphasized-reference-information">the last two years</span> to verify your work history.&nbsp; 
                            <span className="emphasized-reference-information">Please note that we will reach out to your references immediately after you complete this
                                application.</span> References are required before we submit you to a facility.
                        </span>
                        {recentHistory && references.length === 2 &&
                                <div className="reference-components" data-testid="reference-components">
                                    <div key="0" className="reference-component" data-testid={"reference-component-0"}>
                                        <div className="reference-form-header">
                                            <div className="reference-form-title">Reference 1*</div>
                                            <div className="application-helper-text">References must have held a supervisory role and should be relevant to the specialty you are applying for.</div>
                                        </div>
                                        <ReferenceForm key={"0"} updateFormData={formData => {updateFormData(0, formData)}} referenceFormPayload={references[0]} facilities={recentHistory}/>
                                    </div>
                                    <div key="1" className="reference-component" data-testid={"reference-component-1"}>
                                        <div className="reference-form-header">
                                            <div className="reference-form-title">Reference 2*</div>
                                            <div className="application-helper-text">References must have held a supervisory role and should be relevant to the specialty you are applying for.</div>
                                        </div>
                                        <ReferenceForm key={"1"} updateFormData={formData => {updateFormData(1, formData)}} referenceFormPayload={references[1]} facilities={recentHistory}/>
                                    </div>
                                </div>
                            }
                    </div>
                </div>
            
        </div>
    );
}
