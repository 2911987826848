import { HcpProfileData } from "../tp-core-types/HcpProfileData"
import displayAsDate from "../utilities/displayAsDate"
import displayAsPhoneNumber from "../utilities/displayAsPhoneNumber"
import "./ProfilePage.scss"

type Props = {
    profileData: HcpProfileData
}

export default function ProfileBiodata(props: Props){

    const formatAddress = (profileData: HcpProfileData) => {
        const stateOrProvince = profileData.state === "" ? profileData.province : profileData.state;
        const zipOrPostalCode = profileData.zipCode === "" ? profileData.postalCode : profileData.zipCode;

        return`${profileData.city}, ${stateOrProvince} ${zipOrPostalCode} ${profileData.country}`
    }
    
    return(
        <div className="profile-container">
            <div className="container-header">Biodata and Contact Info</div>
                <div className="profile-container-info">
                    <div className="biodata-field-container">
                        <h2 className="biodata-info-title">Name:</h2>
                        <div className="info-value">{props.profileData.firstName} {props.profileData.lastName}</div>
                    </div>
                    <div className="biodata-field-container">
                        <h2 className="biodata-info-title">Phone Number:</h2>
                        <div className="info-value">{displayAsPhoneNumber(props.profileData.phoneNumber)}</div>
                    </div>
                    <div className="biodata-field-container">
                        <h2 className="biodata-info-title">Email Address:</h2>
                        <div className="info-value">{props.profileData.email}</div>
                    </div>
                    <div className="biodata-field-container">
                        <h2 className="biodata-info-title">Date of Birth:</h2>
                        <div data-testid="dob-field" className="info-value">{props.profileData.dob && props.profileData.dob !== "" && displayAsDate(props.profileData.dob)}</div>
                    </div>
                    <div className="biodata-field-container">
                        <h2 className="biodata-info-title">Permanent Address:</h2>
                        <div className="info-value">
                            <div>{props.profileData.streetAddress}</div>
                            <div>{formatAddress(props.profileData)}</div>
                        </div>

                    </div>
                    <div className="biodata-field-container">
                        <h2 className="biodata-info-title">Travel Address:</h2>
                        <div className="info-value">
                            <div>{props.profileData.streetAddress}</div>
                            <div>{formatAddress(props.profileData)}</div>
                        </div>
                    </div>
                </div>
        </div>
    )
}