import { ChangeEvent, useContext, useEffect, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import UserInfoContext from "../../tp-core-types/UserInfoModel";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { HshChecklistItem, HshChecklistItemsResponse } from "../../tp-core-types/HshChecklistItems";
import { useError } from "../../utilities/context/useError";
import "./SkillsChecklist.scss"
import SkillsChecklistCheckBox from "./SkillsCheckListCheckBox";
import { useNavigate } from "react-router-dom";

export default function SkillsChecklist (){
    const userInfo = useContext(UserInfoContext);
    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const navigate = useNavigate();

    const [checklistOptions, setChecklistOptions] = useState<HshChecklistItem[]>();
    const [selectedChecklistIds, setSelectedChecklistIds] = useState<string []>([]);
    const [takeButtonDisabled, setTakeButtonDisabled] = useState<boolean>(false);

    const {setError} = useError();

    const getSkillsChecklist = async () => {
        try {
            const hcpHshItems: HshChecklistItemsResponse = await TPCoreAPI.getHshChecklistItems();
            setChecklistOptions(hcpHshItems.hshItems);
            const selectedChecklists = hcpHshItems.hshItems.map(cl => cl.hshItemId);
            setSelectedChecklistIds(selectedChecklists);
        } catch {
            setError(new Error("It looks like something went wrong. Please try again."));
        }            
    }

    const updateSelectedChecklists = (id: string) => {
        const existingIndex = selectedChecklistIds.indexOf(id)
        let newIds;
        if (existingIndex !== -1) {
            newIds = [...selectedChecklistIds];
            newIds.splice(existingIndex, 1);
            setSelectedChecklistIds(newIds)
        }
        else {
            newIds = [...selectedChecklistIds];
            newIds.push(id);
            setSelectedChecklistIds(newIds)
        }
    }

    const postSkillsChecklists = async () => {
        await TPCoreAPI.assignHcpSkillsChecklists({checklistIds: selectedChecklistIds});
        navigate("/skills-checklist-summary")
    }

    useEffect(() => {
     getSkillsChecklist();
    }, [])

    useEffect(() => {
        if (selectedChecklistIds.length === 0) {
            setTakeButtonDisabled(true)
        }
        else {
            setTakeButtonDisabled(false)
        }
    }, [selectedChecklistIds, setSelectedChecklistIds])

    return <div id="SkillsChecklist">
        <NavigationHeader selectedStep={NavigationSteps.Skills_Checklist}  showNavigationBar={false}/>
        <div className="application-page-container">
            <div className="application-sticky-header">
                <div className="skills-title">Skills Checklist</div>
            </div>
            <div className="checklist-container">
                <div className="container-description">{userInfo.userInfo.firstName}, based on your work history, you qualify for the following skills checklist(s):</div>
                <div className="checklist-subContainer">
                {checklistOptions &&
                    <div className="checklist-item-container" data-testid="checklist-item-container">
                        {checklistOptions.map((checklistItem) => {
                            return <SkillsChecklistCheckBox key={checklistItem.hshItemId} checklistItem={checklistItem} updateSelectedChecklists={updateSelectedChecklists}/>
                        })}    
                    </div>
                }    
                </div>
            </div>
      
            <div className="take-all-button">
                <button className="action-button" disabled={takeButtonDisabled} onClick={postSkillsChecklists}>Take Selected Checklists</button>
            </div>
        </div>
    </div>;
}

