import calendar_icon from "../../assets/calendar_icon.png";
import clock_icon from "../../assets/clock_icon.png";
import dollar_icon from "../../assets/dollar_icon.png";
import hospital_icon from "../../assets/hospital_icon.png";
import people_icon from "../../assets/people_icon.png";
import displayAsMoney from "../../utilities/displayAsMoney";
import getShiftDisplay from "../../utilities/getShiftDisplay";
import displayAsDate from "../../utilities/displayAsDate";
import "./StartApplicationModal.scss";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Session } from "../../utilities/Session";
import DatePickerWrapper from "../../date-picker-wrapper/DatePickerWrapper";
import TimeOffRow from "../time-off-row/TimeOffRow";
import { RequestedTimeOff } from "../../tp-core-types/RequestedTimeOff";
import { JobDetailsModel } from "../../tp-core-types/JobDetailsModel";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { UserInfo } from "../../tp-core-types/UserInfoModel";
import alert_diamond from "../../assets/alert_diamond.svg"
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";

type Props = {
    jobDetails: JobDetailsModel;
    isVisible: boolean;
    setVisibility: (value: boolean) => void;
    showLoginDialog: (afterLogin: () => Promise<void>) => void;
    postStartApplication: (
        jobId: number,
        isInterested: boolean,
        desiredStartDate: Date,
        otherRequests: string,
        bestContactTime: string,
        requestedTimeOff: RequestedTimeOff[],
        userInfo: UserInfo | undefined
    ) => Promise<void>;
};

function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
    event.currentTarget.src = "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
}

export default function StartApplicationModal(props: Props) {
    const ff = useContext(FeatureFlagContext);
    const twoWeeksFromToday = new Date();
    twoWeeksFromToday.setDate(twoWeeksFromToday.getDate() + 14);

    const tenWeeksFromToday = new Date()
    tenWeeksFromToday.setDate(tenWeeksFromToday.getDate() + 70);
    
    const [anyOtherRequests, setAnyOtherRequests] = useState("");
    const [bestContactTime, setBestContactTime] = useState("");
    const [desiredStartDate, setDesiredStartDate] = useState(twoWeeksFromToday)
    const [requestedTimeOff, setRequestedTimeOff] = useState<RequestedTimeOff[]>([{"fromDate": null, "toDate": null}])
    const [timeOffYesNo, setTimeOffYesNo] = useState<boolean>(true);
    const [formTimeOffValid, setFormTimeOffValid] = useState<boolean>(false);

    function handleContinue(props: Props) {

        if(ff.featureFlag.featureFlag_Rto_IsEnabled && ! (!timeOffYesNo || (requestedTimeOff.length > 0
            &&
            requestedTimeOff[0].fromDate != null)))
        {
                setFormTimeOffValid(false);
                return;
        }

        if (Session.isHcpLoggedIn()) {
            runPostStartApplicationWithState();
        } else {
            props.showLoginDialog(runPostStartApplicationWithState);
        }
        props.setVisibility(false);
    }
    
  async function runPostStartApplicationWithState(): Promise<void> {
        let userInfo: UserInfo | undefined = undefined;
      
        if (Session.isHcpLoggedIn()) {
            // we are making a call again because the context that we are given may not have been updated in the login process
            userInfo = await TPCoreAPI.getUserInfo();
        }

        return props.postStartApplication(
            props.jobDetails.jobId,
            true,
            desiredStartDate,
            anyOtherRequests,
            bestContactTime,
            requestedTimeOff
                .map((x) => x.fromDate ? x : {fromDate:x.toDate, toDate: null})
                .filter(timeOff => timeOff.fromDate != null || timeOff.toDate != null),
            userInfo
        );
    }

    function handleTimeOffChange(timeoff: RequestedTimeOff, index: number) {
        const requestedTimeOffCopy = [...requestedTimeOff];
        requestedTimeOffCopy[index] = timeoff;

        setRequestedTimeOff(requestedTimeOffCopy);
    }

    function handleTimeOffYesNo(value: boolean) {
        setTimeOffYesNo(value);
        if(!value){
            setRequestedTimeOff([{"fromDate": null, "toDate": null}]);
        }
    }

    function handleNewDateOnClick(){
        setRequestedTimeOff([...requestedTimeOff, {fromDate: null, toDate: null}])
    }

    const backgroundRef = useRef<HTMLDivElement>(null);

    useEffect( () => {
        setFormTimeOffValid(true);
    },[requestedTimeOff]);


    return (
        <div id="StartApplicationModal">
            {props.isVisible && (
                <div
                    className="start-application-modal-background"
                    data-testid="start-application-modal-background"
                    onClick={(e) => {
                        if(e.target === backgroundRef.current) {
                            props.setVisibility(false);
                        }
                    }}
                    ref={backgroundRef}
                >
                    <div
                        className="start-application-container"
                        data-testid="start-application-container"
                    >
                        <div className="start-application-header">Specify your desired start date and requested time off</div>
                        <div className="start-application-body">
                            <div className="start-application-job-details">
                                <div className="mobile-details">
                                    <div className="start-application-body-header">
                                        <span className="jobMainHeaderTitle">{`${props.jobDetails.profession} (${props.jobDetails.specialty})`}</span>
                                        <span className="jobMainHeaderSeparator">{` - `}</span>
                                        <span className="jobMainHeaderSubtitle">{`${props.jobDetails.facility.city}, ${props.jobDetails.facility.state}`}</span>
                                    </div>
                                    <div>
                                        <span className="jobMainHeaderJobNumberText">{`Job Number: ${props.jobDetails.jobId}`}</span>
                                    </div>
                                    <div className="detail-title">
                                        <img className="detail-icon" src={hospital_icon} alt=""></img>
                                        <h3 className="detail-title-text">{props.jobDetails.facility.facilityName}</h3>
                                    </div>
                                    <div className="detail-subtitle">
                                        <img className="detail-icon" src={dollar_icon} alt=""></img>
                                        <h3 className="detail-subtitle-text">{displayAsMoney(props.jobDetails.grossWeeklyPay)}</h3>
                                    </div>
                                </div>
                                <div className="desktop-only-details">
                                    <div className="detail-item item-500">
                                        <img
                                            className="detail-icon"
                                            src={getShiftDisplay(props.jobDetails.shiftCategory).icon}
                                            alt=""
                                        ></img>
                                        <p className="detail-item-text">
                                            {getShiftDisplay(props.jobDetails.shiftCategory).detailText}
                                        </p>
                                    </div>
                                    <div className="detail-item item-500">
                                        <img className="detail-icon" src={clock_icon} alt=""></img>
                                        <p className="detail-item-text">{props.jobDetails.shift}</p>
                                    </div>
                                    <div className="detail-item item-500">
                                        <img className="detail-icon" src={people_icon} alt=""></img>
                                        <p className="detail-item-text-positions">{props.jobDetails.availablePositions}</p>
                                        {props.jobDetails.availablePositions === 1 ? (
                                            <p className="detail-item-text">Opening</p>
                                        ) : (
                                            <p className="detail-item-text">Openings</p>
                                        )}
                                    </div>
                                    <div className="detail-item-with-helper-text">
                                        <div className="detail-item item-500">
                                            <img className="detail-icon" src={calendar_icon} alt=""></img>
                                            <p className="detail-item-text">
                                                {props.jobDetails.duration} Weeks. Starts {displayAsDate(props.jobDetails.startDate)}*
                                            </p>
                                        </div>
                                        <p className="helper-text">* In some cases, start dates can be flexible</p>
                                    </div>
                                    <div className="facilityImageContainer">
                                        <img
                                            className="facilityImage"
                                            src={props.jobDetails.facility.imageUrl}
                                            onError={addDefaultSrc}
                                            alt="facility"
                                            data-testid="facility-image"
                                        />
                                    </div>
                                </div>
                               
                            </div>
                            <div className="start-application-timeoff-requests">
                                <div className="timeoff-scroll-box">
                                    <div className="timeoff-flex-box">
                                        <label htmlFor="desiredStartDate">Your Desired Start Date</label>
                                        <DatePickerWrapper 
                                            inputId="desiredStartDate" 
                                            value={desiredStartDate} 
                                            onChange={(event) => setDesiredStartDate(event.value ?? desiredStartDate)} 
                                            minDate={twoWeeksFromToday} 
                                            maxDate={tenWeeksFromToday} />
                                        
                                        <div className="req-time-off-label-container">
                                            <div><label>Requested Time Off*</label> </div>
                                            { 
                                            ff.featureFlag.featureFlag_Rto_IsEnabled &&
                                            <div className="req-time-off-yes-no-container">
                                                <div onClick={()=>handleTimeOffYesNo(true)} className={`req-time-off-yes-button ${timeOffYesNo ? 'req-time-off-button-active' : ''}`}>Yes</div>
                                                <div data-testid="req-time-off-no-button" onClick={()=>handleTimeOffYesNo(false)} className={`req-time-off-no-button ${timeOffYesNo ? '' : 'req-time-off-button-active'}`}>No</div>
                                            </div>
                                            }
                                        </div>

                                        {
                                            timeOffYesNo &&
                                            requestedTimeOff.map((timeOff, index) => (
                                                <TimeOffRow timeOffValid={formTimeOffValid} timeOff={timeOff} key={index} onChange={(changedTimeOff) => handleTimeOffChange(changedTimeOff, index)}/>
                                            ))
                                        }

                                        {timeOffYesNo &&
                                        <button className="newDateButton" onClick={handleNewDateOnClick}><span className="plusSize">+</span> New Date</button>
                                        }

                                        {!formTimeOffValid && 
                                        <div className="time-off-error-msg">
                                            <img className="error-image"src={alert_diamond} alt=""></img>
                                            Please provide Requested Time Off to continue
                                        </div>
                                        }
                                        
                                        <label htmlFor="anyOtherRequests">Any Other Requests</label>
                                        <textarea
                                            placeholder="ex: Block scheduling, unknown required time off, etc."
                                            id="anyOtherRequests"
                                            onChange={(e) => setAnyOtherRequests(e.target.value)}
                                            value={anyOtherRequests}
                                        ></textarea>

                                        <label htmlFor="bestContactTime">When is the best time to contact you?</label>
                                        <textarea
                                            id="bestContactTime"
                                            onChange={(e) => setBestContactTime(e.target.value)}
                                            value={bestContactTime}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="start-application-buttons">
                                    <button
                                        className="start-application-cancel"
                                        data-testid="cancel-button"
                                        onClick={() => props.setVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="start-application-continue"
                                        data-testid="continue-button"
                                        onClick={() => {
                                            handleContinue(props);
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );


}
