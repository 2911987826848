import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { City, JobSearchOptionsModel, Profession, Specialty, State } from "../tp-core-types/JobSearchOptionsModel";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import SelectButtonWrapper from "../select-button-wrapper/SelectButtonWrapper";
import { SelectButtonChangeEvent } from "primereact/selectbutton";
import sun_icon from "../assets/sun.svg";
import moon_icon from "../assets/moon.svg";
import sun_moon_icon from "../assets/sun-moon.svg";
import { ShiftCategory } from "../tp-core-types/ShiftCategory";
import LocationMultiSelectWrapper from "../multi-select-wrapper/LocationMultiSelectWrapper";
import SpecialtyMultiSelectWrapper from "../multi-select-wrapper/SpecialtyMultiSelectWrapper";
import getFilterSettingsFromURLParams, { LocationOption, NumberOfShifts } from "../utilities/JobFilterCollaborator";
import { getCitiesFromLocations, getStatesFromLocations } from "../utilities/locationHandler";
import "./AllJobFiltersForm.css";
import SelectProfessionDropdown from "../select-profession-dropdown/SelectProfessionDropdown";

type Props = {
    professionId: string | null;
    specialtyIds: string | null;
    stateIds: string | null;
    cityIds: string | null;
    shiftCategoryId: string | null;
    numberOfShifts: string | null;
};

export default function AllJobFiltersForm(props: Props) {
    const [searchOptions, setSearchOptions] = useState<{
        professions: Profession[];
        specialties: Specialty[];
        locations: LocationOption[];
    }>({
        professions: [],
        specialties: [],
        locations: [],
    });

    const [selectedProfession, setSelectedProfession] = useState<Profession>();
    const [selectedSpecialties, setSelectedSpecialties] = useState<Specialty[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<LocationOption[]>([]);
    const [selectedPreferredShift, setSelectedPreferredShift] = useState<ShiftCategory | undefined>();
    const [selectedNumberOfShifts, setSelectedNumberOfShifts] = useState<NumberOfShifts | undefined>();
    const isMobile = window.matchMedia("(max-width: 800px)").matches;


    const getFilteredSettings = async () => {
        const jobSearchOptions: JobSearchOptionsModel = await TPCoreAPI.getJobSearchOptions();
        const specialtyOptions = jobSearchOptions.specialties
            .map((option: Specialty) => {
                return {
                    id: option.id.toString(),
                    name: determineSpecialtyOptionName(option),
                    abbreviation: option.abbreviation,
                    longDescription: option.longDescription,
                    altText: option.altText,
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
        const stateOptions: State[] = jobSearchOptions.states.map((state: State) => {
            return { ...state, id: state.id };
        });
        const cityOptions: City[] = jobSearchOptions.cities.map((city: City) => {
            return { ...city, id: city.id };
        });
        const locationOptions: LocationOption[] = constructLocationOption(stateOptions, cityOptions);
        locationOptions.unshift( {name: "All Compact States", showAllCompact: true, city: null, id: "-99",radius: null, state: {id: "", abbreviation: "", isCompact: false, name: "" }});

        const filterSettings = getFilterSettingsFromURLParams(jobSearchOptions, {
            professionIds: props.professionId,
            specialtyIds: props.specialtyIds,
            stateIds: props.stateIds,
            cityIds: props.cityIds,
            shiftCategoryId: props.shiftCategoryId,
            numberOfShifts: props.numberOfShifts,
        });

        setSelectedProfession(filterSettings.filteredProfession);
        setSelectedSpecialties(filterSettings.filteredSpecialties);
        setSelectedSpecialties(
            filterSettings.filteredSpecialties.map((option: Specialty) => {
                return {
                    id: option.id.toString(),
                    name: determineSpecialtyOptionName(option),
                    abbreviation: option.abbreviation,
                    longDescription: option.longDescription,
                    altText: option.altText,
                };
            })
        );

        setSelectedLocations(filterSettings.filteredLocations);

        setSearchOptions({
            professions: jobSearchOptions.disciplines,
            specialties: specialtyOptions,
            locations: locationOptions,
        });
    };

    useLayoutEffect(() => {
        setPositionAndWidthOfDropdownBox();
    });

    useEffect(() => {
        getFilteredSettings();
        setWidthOfSpecialtiesDropdownBox();
        if (props.shiftCategoryId) {
            setSelectedPreferredShift(parseInt(props.shiftCategoryId));
        }

        if (props.numberOfShifts) {
            setSelectedNumberOfShifts(parseInt(props.numberOfShifts));
        }
    }, []);

    const getShouldShowAllCompactStates = (): boolean => {
        const allCompactStates = searchOptions.locations.filter((location) => location.state.isCompact && location.city === null && !location.showAllCompact)
        const allSelectedCompactStates = selectedLocations.filter((location) => location.state.isCompact && location.city === null && !location.showAllCompact)

        return allCompactStates.length === allSelectedCompactStates.length
    }

    const constructLocationOption = (stateOptions: State[], cityOptions: City[]) => {
        const stateLocations: LocationOption[] = stateOptions.map((state: State) => {
            return {
                id: state.id,
                name: state.name,
                state: { ...state },
                city: null,
                radius: null,
                showAllCompact: false
            };
        });
        const cityLocations: LocationOption[] = cityOptions.map((city: City) => {
            return {
                id: city.id,
                name: `${city.name}, ${city.stateAbbreviation}`,
                state: stateOptions.filter((s) => s.abbreviation === city.stateAbbreviation)[0],
                city: { ...city },
                radius: "100",
                showAllCompact: false
            };
        });
        return stateLocations.concat(cityLocations);
    };

    function setWidthOfSpecialtiesDropdownBox() {
        const panels = document.getElementsByClassName("p-multiselect-panel");
        const inputs = document.getElementsByClassName("p-multiselect-label-container");
        if (panels.length > 0 && inputs.length > 0) {
            const panel = panels[0] as HTMLElement;
            const input = inputs[0] as HTMLElement;

            const inputBoundingRect = input.getBoundingClientRect();

            const newWidth = inputBoundingRect.width;

            panel.style.width = newWidth.toString() + "px";
        }
    }

    const evaluateSpecialtiesHelperText = () => {
        const selectedSpecialtiesElement = document.getElementsByClassName("p-multiselect-label")[0];
        const selectedSpecialtiesContainerElement = document.getElementsByClassName("p-multiselect-label-container")[0];

        if (selectedSpecialtiesElement && selectedSpecialtiesContainerElement) {
            if (selectedSpecialties.length > 0) {
                selectedSpecialtiesContainerElement.setAttribute("add-more-text", "Add more specialties");
            } else {
                selectedSpecialtiesContainerElement.setAttribute("add-more-text", "");
            }

            if (selectedSpecialties.length > 3) {
                selectedSpecialtiesElement.setAttribute(
                    "more-than-3-selections-text",
                    `+ ${selectedSpecialties.length - 3} more`
                );
            } else {
                selectedSpecialtiesElement.setAttribute("more-than-3-selections-text", "");
            }
        }
    };
    useEffect(() => {
        evaluateSpecialtiesHelperText();
    }, [selectedSpecialties]);

    const evaluateLocationsHelperText = () => {
        const selectedLocationsElement = document.getElementsByClassName("p-multiselect-label")[1];
        const selectedLocationsContainerElement = document.getElementsByClassName("p-multiselect-label-container")[1];
        
        const filteredLocationOptions = selectedLocations.filter((location) => !location.showAllCompact);

        if (selectedLocationsElement && selectedLocationsContainerElement) {
            if (filteredLocationOptions.length > 0) {
                selectedLocationsContainerElement.setAttribute("add-more-text", "Add more locations");
            } else {
                selectedLocationsContainerElement.setAttribute("add-more-text", "");
            }
            if (filteredLocationOptions.length > 3) {
                selectedLocationsElement.setAttribute(
                    "more-than-3-selections-text",
                    `+ ${filteredLocationOptions.length - 3} more`
                );
            } else {
                selectedLocationsElement.setAttribute("more-than-3-selections-text", "");
            }
        }
    };
    useEffect(() => {
        evaluateLocationsHelperText();

        if (selectedLocations.length === 0 && searchOptions.locations.length === 0) {
            return
        }
        const allCompactStateOptionIndex: number = selectedLocations.findIndex((location) => location.showAllCompact)
        const shouldShowAllCompactStatesOptions = getShouldShowAllCompactStates()

        if(shouldShowAllCompactStatesOptions && allCompactStateOptionIndex === -1) {
            const localSelectedLocations = [...selectedLocations];
            localSelectedLocations.push(searchOptions.locations[0])
            setSelectedLocations(localSelectedLocations)
        } else if(!shouldShowAllCompactStatesOptions && allCompactStateOptionIndex !== -1) {
            const localSelectedLocations = [...selectedLocations];
            localSelectedLocations.splice(allCompactStateOptionIndex, 1)
            setSelectedLocations(localSelectedLocations)
        }

    }, [selectedLocations, searchOptions]);

    const handleSpecialtiesChange = (event: MultiSelectChangeEvent) => {
        setSelectedSpecialties(event.value);
    };

    const handleLocationChange = (event: MultiSelectChangeEvent) => {
        let items = event.value;

        if (event.value.length > selectedLocations.length) { //an option was selected
            if (items[items.length-1].showAllCompact) {
                const stateLocations = searchOptions.locations.filter((searchOption) => !searchOption.showAllCompact && searchOption.city == null)
                
                stateLocations.forEach((location) => {
                    if (location.state.isCompact && !selectedLocations.some(selectedLocation => selectedLocation.id === location.id)) {
                        items.push(location);
                    }
                });
            } else {
                if (items.length > 1) items.unshift(items.pop()); //move newest selected item to front
            }
        } else { //an option was deselected
            if(selectedLocations.some((location) => location.showAllCompact) && !items.some((location: any) => location.showAllCompact)) {
                items = items.filter((location: any) => location.city !== null || !location.state.isCompact);
            }
        }
        
        setSelectedLocations(items);
    };

    const handlePreferredShiftChange = (event: SelectButtonChangeEvent) => {
        setSelectedPreferredShift(event.value);
    };

    const handleSelectedNumberOfShiftsChange = (event: SelectButtonChangeEvent) => {
        setSelectedNumberOfShifts(event.value);
    };

    function handleSpecialtiesRemove(idToRemove: string): void {
        setSelectedSpecialties(
            selectedSpecialties.filter((selectedSpecialty: Specialty) => selectedSpecialty.id !== idToRemove)
        );
    }

    function handleLocationRemove(idToRemove: string): void {
        setSelectedLocations(
            selectedLocations.filter((selectedLocation: LocationOption) => selectedLocation.id !== idToRemove)
        );
    }

    function onCityRadiusDropdownChange(event: ChangeEvent<HTMLSelectElement>) {
        const cityId: string = event.target.id;
        const radius: string = event.target.value;

        const index = selectedLocations.findIndex((x) => x.id === cityId);

        const location = selectedLocations[index];
        location.radius = radius;

        const newLocations = selectedLocations.slice(0);
        newLocations.splice(index, 1, location);

        setSelectedLocations(newLocations);
    }

    function getSelectedStatesInputValue(): string[] {
        const states = getStatesFromLocations(selectedLocations);

        return states.map((location) => {
            return location.state.abbreviation;
        });
    }

    function getSelectedCitiesInputValue(): string[] {
        const cities = getCitiesFromLocations(selectedLocations);

        return cities.map((city) => {
            return `${city.id}_${city.radius}`;
        });
    }

    function setPositionAndWidthOfDropdownBox() {
        requestAnimationFrame(() => {
            const panels = document.getElementsByClassName("p-multiselect-panel");
            if (panels.length > 0) {
                const panel = panels[0] as HTMLElement;

                let input: HTMLElement | null = null;
                if (document.getElementById("specialty-multi-select") !== null) {
                    input = document.getElementById("specialtyIdMultiSelect");
                } else if (document.getElementById("location-multi-select") !== null) {
                    input = document.getElementById("locationIdMultiSelect");
                }

                if (input) {
                    const inputBoundingRect = input.getBoundingClientRect();

                    const newTop = inputBoundingRect.top + input.offsetHeight;
                    const newLeft = inputBoundingRect.left;
                    const newWidth = inputBoundingRect.width;

                    panel.style.top = newTop.toString() + "px";
                    panel.style.left = newLeft.toString() + "px";
                    panel.style.width = newWidth.toString() + "px";
                }
            }
        });
    }


    const determineSpecialtyOptionName = (option: Specialty): string => {
        return option.name === option.abbreviation || option.abbreviation === ""
            ? option.name
            : option.name + " - " + option.abbreviation;
    };

    return (
        <form id="job-search-form" method="get" name="search-for-jobs-form" className="jobSearchFilters">
            <div className="topContainer">
                <div className="scrolable">
                    <div className="rowDiv">
                        <div className="columnStyle">
                            <SelectProfessionDropdown
                                onProfessionChange={setSelectedProfession}
                                professions={searchOptions.professions}
                                selectedProfessionId={selectedProfession?.id ?? ""}
                                isRequired={false}
                            />
                        </div>
                        <div className="columnStyle">
                            <label className="label">Specialty</label>
                            <SpecialtyMultiSelectWrapper
                                id="specialtyIdMultiSelect"
                                name=""
                                onShow={() => {return}}
                                onHide={() => {return}}
                                onChange={handleSpecialtiesChange}
                                onRemove={handleSpecialtiesRemove}
                                options={searchOptions.specialties}
                                value={selectedSpecialties}
                                optionLabel="name"
                                filterPlaceholder="Search"
                            />
                            <input
                                id="specialtyId"
                                type="hidden"
                                name="specialtyIds"
                                value={selectedSpecialties.map((specialty: Specialty) => specialty.id)}
                            ></input>
                        </div>
                        <div className="columnStyle">
                            <label className="label">Location</label>
                            <LocationMultiSelectWrapper
                                id="locationIdMultiSelect"
                                name=""
                                value={selectedLocations}
                                onShow={() => {return}}
                                onHide={() => {return}}
                                onChange={handleLocationChange}
                                onRemove={handleLocationRemove}
                                options={searchOptions.locations}
                                optionLabel="name"
                                filterPlaceholder="Search"
                                onSelectedOptionDropdownChange={onCityRadiusDropdownChange}
                                radii={[{ cityId: "", radius: "" }]}
                            />
                            <input
                                id="stateIds"
                                type="hidden"
                                name="stateIds"
                                value={getSelectedStatesInputValue().map((stateId: string) => stateId)}
                            ></input>
                            <input
                                id="cityIds"
                                type="hidden"
                                name="cityIds"
                                value={getSelectedCitiesInputValue().map((cityId: string) => cityId)}
                            ></input>
                        </div>
                    </div>
                    <div className="rowDiv">
                        <div className="columnStyle">
                            <label className="label">Preferred Shift</label>
                            <SelectButtonWrapper
                                id="preferred-shift-select-button"
                                className="preferred-shift-select-button"
                                name=""
                                value={selectedPreferredShift ?? 0}
                                onChange={handlePreferredShiftChange}
                                options={[
                                    {
                                        icon: sun_icon,
                                        name1: "Day",
                                        name2: "",
                                        value: ShiftCategory.Days,
                                    },
                                    {
                                        icon: moon_icon,
                                        name1: "Night",
                                        name2: "",
                                        value: ShiftCategory.Nights,
                                    },
                                    {
                                        icon: sun_moon_icon,
                                        name1: "Either",
                                        name2: "",
                                        value: ShiftCategory.DaysOrNights,
                                    },
                                ]}
                                optionLabel={"name"}
                            />
                            <input
                                data-testid="shift-id"
                                id="shift-id"
                                type="hidden"
                                name="shiftCategoryId"
                                value={selectedPreferredShift}
                            ></input>
                        </div>
                        <div className="columnStyle">
                            <label className="label">Number of Shifts per Week</label>
                            <SelectButtonWrapper
                                id="shifts-per-week-select-button"
                                className="shifts-per-week-select-button"
                                name={""}
                                value={selectedNumberOfShifts ?? 0}
                                onChange={handleSelectedNumberOfShiftsChange}
                                options={[
                                    { icon: "", name1: "3", name2: "Shifts", value: NumberOfShifts.ThreeShifts },
                                    { icon: "", name1: "4", name2: "Shifts", value: NumberOfShifts.FourShifts },
                                    { icon: "", name1: "5", name2: "Shifts", value: NumberOfShifts.FiveShifts },
                                    {
                                        icon: "",
                                        name1: "Any #",
                                        name2: "of Shifts",
                                        value: NumberOfShifts.AnyNumberOfShifts,
                                    },
                                ]}
                                optionLabel={"name"}
                            />
                            <input
                                data-testid="shifs-per-week-id"
                                id="shifs-per-week-id"
                                type="hidden"
                                name="numberOfShifts"
                                value={selectedNumberOfShifts}
                            ></input>
                        </div>
                        { !isMobile &&
                            <div className="columnStyle">
                            <div className="searchButton">
                                <button className="buttonContainer">Update Results</button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                { isMobile &&
                        <div className="columnStyle searchButtonDiv">
                            <div className="searchButton">
                                <button className="buttonContainer">Update Results</button>
                            </div>
                        </div>
                }
            </div>
        </form>
    );
}
