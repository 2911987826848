import { useEffect, useState } from "react";
import ReferenceForm from "../../HCPApplication/references/reference-form";
import { ReferenceFormCallbackPayload } from "../../tp-core-types/HcpReferences";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { ReferenceFormFacilitiesResponse, ReferenceFormFacilityOption } from "../../tp-core-types/ReferenceFormFacilityOption";
import { useError } from "../../utilities/context/useError";
import ProfileModal from "../ProfileModal";
import './ReferenceModal.scss'

export type Props = {
    cancelCallback: () => void
    saveCallback: (formData: ReferenceFormCallbackPayload) => Promise<boolean>
}

export default function ReferenceModal(props: Props){
    const [facilityOptions, setFacilityOptions] = useState<ReferenceFormFacilityOption[]>([]);
    const [formData, setFormData] = useState<ReferenceFormCallbackPayload>({
        historyId: "",
        facility: "",
        facilityCity: "",
        facilityState: "",
        firstName: "",
        lastName: "",
        title: "",
        phoneNumber: "",
        phoneType: "",
        email: "",
        referenceNumber: 1
    })
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
    
    const {setError} = useError();

    useEffect(() => {
        getFacilityOptions();
    }, [])


    const getFacilityOptions = async () => {
        const response: ReferenceFormFacilitiesResponse = await TPCoreAPI.getFacilityOptions();
        setFacilityOptions(response.facilityOptions);
    }

    const isSaveDisabled = () => {
        return formData.historyId === ""
            || formData.firstName === "" 
            || formData.lastName === ""
            || formData.title === ""
            || formData.phoneNumber === ""
            || formData.phoneType === ""
            || requestInProgress
    };

    const saveButtonHandler = () => {
        setRequestInProgress(true);
        const saveResult = props.saveCallback(formData);
            saveResult.then(result => {
                if(!result) {
                    setRequestInProgress(false);
                    setError(new Error("Something went wrong. Please try again."));
                }
        })
    };

    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 800px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    return(
      
        <ProfileModal 
            cancelCallback={props.cancelCallback}
            saveCallback={saveButtonHandler}
            saveButtonText={"Save Reference"}
            headerText={"Add New Reference"}
            subHeaderText={"Reference"}
            disableSaveButton={isSaveDisabled()}                    
        >            
            <div data-testid="reference-modal-wrapper" className="profile-reference-modal-wrapper">
                <ReferenceForm facilities={facilityOptions} referenceFormPayload={formData} updateFormData={setFormData} />                            
            </div>
        </ProfileModal>     
    )
}