import { useContext, useEffect, useState } from "react";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import "./LoginForm.scss"
import { Session } from "../../utilities/Session";
import UserInfoModel from "../../tp-core-types/UserInfoModel";
import alert_diamond from "../../assets/alert_diamond.svg"

type Props = {
    successfulLogin: () => void;
    showLogin: (showLogin: boolean) => void;
    showForgotPasword: (showForgot: boolean) => void;
}

export default function LoginForm(props:Props){
    const userInfoModel = useContext(UserInfoModel) 
    const [userEmail, setUserEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [invalidCredentialVisible, setInvalidCredentialVisible] = useState<boolean>(false)
    const [isLoginButtonEnabled, setIsLoginButtonEnabled] = useState(false);

    const handleLogin = async  () => {
        setIsLoginButtonEnabled(true);
        if (userEmail === '' || password === '') {
            setInvalidCredentialVisible(true);
            return;
        }
        
        try {
            const userToken = await TPCoreAPI.getUserToken(userEmail, password)
            Session.setHcpLoginToken(userToken.token);
            userInfoModel.getAndSetToken();
        }
        catch(error) {
            setIsLoginButtonEnabled(false);
            setInvalidCredentialVisible(true)
            return error
        }
        try {
            const userHubToken = await TPCoreAPI.getUserHubData(userEmail, password)
            Session.setHcpHubLoginData(userHubToken)
        }
        catch(error) {
        }
        props.successfulLogin();
    }
   

    const handleUserEmailChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value);
    }

    const handlePasswordChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handleKeyDown = (e : React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            handleLogin()
        }
    }
    
    return(
    <div id="LoginForm">
        <div className="login-body-container">
            <form className="login-form" data-testid="login-form" onSubmit={(e)=>e.preventDefault()}>
                <div className='login-modal-field'>
                    <label htmlFor="login-form-email">Email Address*</label>
                    <input inputMode="email" id='login-form-email' data-testid="email-input" autoComplete="email" className={invalidCredentialVisible ? "invalid-credential-border" : "valid-credential-text"} onChange={handleUserEmailChange} onKeyDown={handleKeyDown} onFocus={()=>{setInvalidCredentialVisible(false)}}></input>
                </div>
                <div className='login-modal-field'>
                    <label htmlFor="login-form-password">Password*</label>
                    <input 
                        type='password' 
                        id='login-form-password' 
                        data-testid="password-input" 
                        autoComplete="current-password" 
                        className={invalidCredentialVisible ? "invalid-credential-border" : "valid-credential-text"} 
                        onChange={handlePasswordChange} onKeyDown={handleKeyDown}  
                        onFocus={()=>{setInvalidCredentialVisible(false)}}
                        maxLength={30}>
                    </input>
                    {invalidCredentialVisible && <div className="invalid-credential-text">
                    <img className="alert" src={alert_diamond} alt="Exclamation Point"/> Invalid login credentials
                        </div>}

                    {!invalidCredentialVisible && <div className="padded"></div>}
                </div>
           
                <button className="login-modal-button" data-testid="login-modal-button" onClick={handleLogin} disabled={isLoginButtonEnabled}>
                    Login
                </button>
                <div className="login-footer">
                    <div className="forgot-password link" onClick={() => props.showForgotPasword(true)}>Forgot Password</div>
                    <div className="login-footer-account" onClick={() => props.showLogin(false)}>Don't have an account?
                        <div className="link" data-testid="footer-signup">Sign up today</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    )
}