export class Validator {
    static validateEmail(email:string): boolean {
        const indexOfRightmostAtSign = email.lastIndexOf('@');
        const indexOfRightmostPeriod = email.lastIndexOf('.');
        
        if(indexOfRightmostAtSign === -1 || indexOfRightmostPeriod === -1)
            return false;

        const textBeforeAt = email.substring(0,indexOfRightmostAtSign);
        const textAfterPeriod = email.substring(indexOfRightmostPeriod + 1);

        if(textBeforeAt && textAfterPeriod.length >= 2)
            return true;

        return false;
    }

    static validatePhoneNumber(phoneNumber:string) : boolean {
        const validMatch = phoneNumber.match(/^[0-9]{10}$/)

        if (validMatch && validMatch.length > 0) {
            return true;
        } else {
            return false;
        }
    }
}