import "./reset-password.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { ValidationResetPasswordModel } from "../tp-core-types/ValidationResetPasswordModel";
import { useEffect, useState } from "react";
import alert_diamond from "../assets/alert_diamond.svg"
import ForgotPasswordModal from "../forgot-password-modal/ForgotPasswordModal";

export default function ResetPasswordPage() {
    const [searchParams] = useSearchParams();
    const [webUserName, setWebUserName] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordResetSucessful, setPasswordResetSuccessful] = useState(false);
    const [guidIsValid, setGuidIsValid] = useState(true)
    const [screenEnabled, setScreenEnabled] = useState(false)
    const [input, setInput] = useState("");
    const [sendPasswordResetCompleted, setSendPasswordResetCompleted] = useState(false);

    const guid = searchParams.get("rpg") ?? "";
    const navigate = useNavigate();
    
    useEffect(()=> {
        validateGuid()
    }, 
    [guid]);
    
    function GoToLogin(){
        navigate('/login-page?returnUrl=/dashboard')
    }

    function confirmPasswords(){
        const validPassword = (newPassword === confirmPassword)
        setPasswordsMatch(validPassword);
        return validPassword;
    }
    async function resetPassword(){
        if (confirmPasswords()) {
            setPasswordResetSuccessful(true);
            await TPCoreAPI.resetPassword(guid, confirmPassword)
        }
    }

    function sendResetRequest() {
        TPCoreAPI.sendPasswordReset(input);
        setSendPasswordResetCompleted(true);
    }



    async function validateGuid() {
        const validationResult:ValidationResetPasswordModel = await TPCoreAPI.validateResetGuid(guid)
        setGuidIsValid(validationResult.guidIsValid)
        setWebUserName(validationResult.webUserName)
        setScreenEnabled(true);
    }

    return(
    <>
            <div id="ResetPasswordPage" data-testid="ResetPasswordPage">
                <div className="reset-password-header">Reset Password</div>
                {!guidIsValid && screenEnabled && !sendPasswordResetCompleted &&
                    <div className="body-space">
                        <div className="invalid-field-alert-text">
                        <img className="alert" src={alert_diamond} alt="Exclamation Point"/>
                        Password reset link is no longer valid!
                        Reset your password or login below.
                        </div>

  
                        <div className="forgot-form">
                            <div className="forgot-input">
                                <label htmlFor="emailaddress">Email Address</label>
                                <input
                                    type="text"
                                    id="emailaddress"
                                    data-testid="emailInput"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    ></input>
                                <div>
                                    <button onClick={sendResetRequest}>Reset Password</button>
                                </div>
                                <div className="centered-text">
                                    -or-
                                </div>
                                <div className="login-button-container">
                                    <button className="login-button" onClick={GoToLogin}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!passwordResetSucessful && guidIsValid && screenEnabled && !sendPasswordResetCompleted && 
                    <div className="body-space">
                        <div className="reset-password-body">
                            <div className="body-header">
                                <span>Enter your new password for {webUserName}</span>
                            </div>
                            
                            <div className="body-body">
                                <div className="inputContainer">
                                    <label htmlFor="newPassword">New Password*</label>
                                    <input id="newPassword" type="password" value={newPassword}
                                    onChange={(e) => { setNewPassword(e.target.value); }}></input>
                                </div>
                                <div className="inputContainer">
                                    <label htmlFor="confirmPassword">Confirm New Password*</label>
                                    <input id="confirmPassword" type="password" value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value); }}
                                    onBlur={(e)=> confirmPasswords() }
                                    >
                                        
                                    </input>
                                    {!passwordsMatch && screenEnabled && 
                                    <div>
                                        <img className="alert" src={alert_diamond} alt="Exclamation Point"/> <span className="invalid-field-alert-text">Passwords must match</span>
                                    </div>}
                                </div>
                                <div className="reset-button-container">
                                    <button onClick={resetPassword}>Reset Password</button>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                }
                
                {passwordResetSucessful && guidIsValid && screenEnabled && !sendPasswordResetCompleted &&
                    <div className="password-complete">
                        <div className="password-complete-message">You password has been successfully reset. Click below to login.</div>
                        <div className="password-complete-button">
                            <button onClick={GoToLogin}>Login</button>
                        </div>
                    </div>

                }
                {sendPasswordResetCompleted && screenEnabled && 
                    <div className="submission-received-container">
                        <div className="submission-received-helper-text">
                            Your submissions has been received. If we have an account matching your email address, you will
                            receive an email with a link to reset your password.
                        </div>
                        <div className="forgot-footer">
                            <button className="forgot-close" onClick={GoToLogin}>
                                Close
                            </button>
                        </div>
                    </div>   
                }
            </div>
        
        
    </>
    )
}