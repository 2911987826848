import alert_diamond from "../../assets/alert_diamond.svg"
import "./ErrorMessage.scss"

type Props = {
    message:string
}
export default function ErrorMesage(props:Props){
    return(
        <div id="ErrorMessage">
            <img className="error-image"src={alert_diamond} data-testid="alert-diamond-img" alt=""></img>
            <div className="error-message">{props.message}</div>
        </div>
    )
}