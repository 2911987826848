import { Calendar } from "primereact/calendar";
import { FormEvent } from "primereact/ts-helpers";
import "./DatePickerWrapper.scss"

type Props = {
    inputId: string,
    value: Date | null | undefined,
    minDate?: Date,
    maxDate?: Date,
    onChange: (event: FormEvent<Date>) => void,
    showButtonBar?: boolean,
    onClear?: () => void,
    disabled?: boolean,
    formValid?: boolean
}

export default function DatePickerWrapper(props: Props) {
    const isMobile = window.matchMedia("(max-width: 800px)").matches;

    return (
        <div id="DatePickerWrapper">
            <Calendar inputId={props.inputId} value={props.value} className={(props.formValid != null && !props.formValid) ? 'form-invalid' : ''} onChange={props.onChange} onClearButtonClick={props.onClear} showIcon placeholder="mm/dd/yyyy" minDate={props.minDate} maxDate={props.maxDate} touchUI={isMobile} showButtonBar={props.showButtonBar} disabled={props.disabled ?? false}></Calendar>
        </div>
    )
}