import DatePickerWrapper from '../../date-picker-wrapper/DatePickerWrapper';
import './StartEndDates.scss';

type Props = {
    setMilitaryStart: (date: Date | null) => void;
    setMilitaryEnd:  (date: Date | null) => void;
    militaryStart: Date | null;
    militaryEnd: Date | null;
}

export default function StartEndDates(props: Props) {
    return(        
        <div id="StartEndDates" data-testid="start-end-date">
            <div className="spacer-container"></div>
            <div className="content-container">
                <div className="date-input-container start-date">
                    <label htmlFor="start-date">Start Date*</label>
                    <DatePickerWrapper
                        inputId="start-date" 
                        showButtonBar={true}
                        onChange={(event)=>{event.value && props.setMilitaryStart(event.value)}}
                        onClear={()=>{props.setMilitaryStart(null)}}
                        value={props.militaryStart} 
                    />       
                </div >
                <div className="date-input-container end-date-container">
                    <label htmlFor="end-date">End Date</label>
                    <DatePickerWrapper 
                        inputId="end-date" 
                        showButtonBar={true}
                        onChange={(event)=>{event.value && props.setMilitaryEnd(event.value)}}                
                        onClear={()=>{props.setMilitaryEnd(null)}}
                        value={props.militaryEnd} 
                    />                         
                </div >
            </div >
        </div>
    );
}

