import { useEffect, useState } from "react";
import { JobDetailsModel } from "../../tp-core-types/JobDetailsModel";
import "./StartApplicationButton.css";

type Props = {
    showStartApplicationModal: () => void;
    jobDetails: JobDetailsModel | null;
    processing: boolean;
};

export default function StartApplicationButton(props: Props) {    
    const [buttonText, setButtonText] = useState("")
    const [disable, setDisable] = useState(false);


    useEffect(() => {
        if (props.processing) {
            setButtonText("Applying..." ) 
            setDisable(true)
        } else {
            setButtonText(props.jobDetails?.interested ? "You applied for this job" : "Apply Now")
            setDisable(false)
        }
    }, [props.processing, props.jobDetails])

    return (
        <div className="start-application-button-container">
            <button
                disabled={disable}
                data-testid="start-application-button"
                className={`${props.jobDetails?.interested ? "is-interested" : "start-application-button"}`}
                onClick={props.jobDetails?.interested ? () => {return} : props.showStartApplicationModal}
            >
                {buttonText}
            </button>
        </div>
    );
}
