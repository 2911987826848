import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import isArchivedJob from "../utilities/isArchivedJob";
import "./JobSearchMinimumRequirementsDetails.css";

type Props = {
    jobDetails: JobDetailsModel
};

export default function JobSearchMinimumRequirementsDetails(props: Props) {    
    return(
        <div data-testid="minimum-requirements-container">
            <div data-testid="header" className={isArchivedJob(props.jobDetails)? "header bold archived" : "header bold"}>Minimum Requirements</div>
            <ul className="requirementsList">
                {props.jobDetails.minimumRequirements.map((requirement, index) => <li key={index} className="requirementListItem">{requirement}</li>)}
            </ul>
        </div>
    );
}


