import { useEffect, useState } from "react";
import { HcpProfileData, HcpProfileLicense } from "../tp-core-types/HcpProfileData"
import displayAsDate from "../utilities/displayAsDate";
import "./ProfilePage.scss"
import ProfileGrid from "./ProfileGrid/ProfileGrid";
import ProfileGridMobile from "./ProfileGrid/ProfileGridMobile";

type Props = {
    profileData: HcpProfileData
}

export default function ProfileLicenseInfo(props: Props)
{
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component unmounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    const getFormattedLicenseData = () => {
        if(props.profileData.licenses.length === 0 ) return []

        const sortedLicenses = props.profileData.licenses.sort(sortStringDate);
        return sortedLicenses.map((license) => {
            return{rows: [
                license.nameOnLicense, 
                license.stateName, 
                license.licenseNumber, 
                license.isActive ? "Active" : "Inactive",  
                license.issueDate && <div data-testid="issue-date">{displayAsDate(license.issueDate)}</div>,
                displayAsDate(license.expirationDate),
                license.isCompact ? "Yes" : "No"] as string[]
            }
        })
    }
    function sortStringDate(a: HcpProfileLicense, b: HcpProfileLicense){
        const dateA = new Date(a.issueDate);
        const dateB = new Date(b.issueDate);
        return dateA < dateB ? 1 : -1;
    }
    return (
        <>
            {isMobile && <ProfileGridMobile 
                parentContainerHeader={"License Information"} 
                noDataMessage="No license information to display. Contact your recruiter for more information."
                containers={
                    props.profileData.licenses.sort(sortStringDate).map(l => { 
                            return {
                                header: l.stateName || "-",
                                data: {
                                    headers: ["Name on License", "License Number", "License Status", "License Original Issue Date", "License Expiration Date", "Compact Status"],
                                    id: l.licenseId?.toString(),
                                    items: [
                                        l.nameOnLicense || "-", 
                                        l.licenseNumber || "-", 
                                        l.isActive ? "Active" : "Inactive", 
                                        l.issueDate && <div data-testid="issue-date">{displayAsDate(l.issueDate)}</div>, 
                                        displayAsDate(l.expirationDate) ?? "-", 
                                        l.isCompact || false ? "Yes" : "No"]
                                }
                            }
                        }
                    )
                } />
            }
            {!isMobile &&  <div className="profile-container">
                <ProfileGrid 
                    containerHeader={"License Information"} 
                    noDataMessage="No license information to display. Contact your recruiter for more information."
                    itemHeaders={["Name on License", "License State", "License Number", "License Status", "License Original Issue Date", "License Expiration Date", "Compact License"]} 
                    items={getFormattedLicenseData()}
                />
            </div>}
        </>
    )
}