import { City, JobSearchOptionsModel, Profession, Specialty, State } from "../tp-core-types/JobSearchOptionsModel";
import { ShiftCategory } from "../tp-core-types/ShiftCategory";

export type FilterURLParams = {
    professionIds: string | null,
    specialtyIds: string | null,
    stateIds: string | null,
    cityIds: string | null,
    shiftCategoryId: string | null,
    numberOfShifts: string | null
}

export type SelectedFilters = {
    filteredProfession: Profession
    filteredSpecialties: Specialty[]
    filteredLocations: LocationOption[],
    shiftCategory?: ShiftCategory,
    numberOfShifts?: NumberOfShifts
}

export type CityWithRadius = {
    city: City,
    radius: string
}

export type LocationOption = {
    id: string,
    name: string,
    state: State,
    city: City | null,
    radius: string | null,
    showAllCompact: boolean,
}

export enum NumberOfShifts {
    AnyNumberOfShifts = -1,
    ThreeShifts = 3,
    FourShifts = 4,
    FiveShifts = 5,
}

export default function getFilterSettingsFromURLParams(options: JobSearchOptionsModel, urlParams: FilterURLParams): SelectedFilters{
    const states = getStatesFromStateIdsString(urlParams.stateIds, options.states);
    const cities = getCitiesWithRadiusFromCityIdsString(urlParams.cityIds, options.cities, options.states);
    const locations = [...states, ...cities];

    const filterSettings : SelectedFilters = {
        filteredProfession: getProfessionsFromProfessionIdsString(urlParams.professionIds, options.disciplines),
        filteredSpecialties: getSpecialtiesFromSpecialtyIdsString(urlParams.specialtyIds, options.specialties),
        filteredLocations: locations,
        shiftCategory: urlParams.shiftCategoryId ? getShiftCategoryFromId(urlParams.shiftCategoryId) : undefined,
        numberOfShifts: urlParams.numberOfShifts ? convertNumberOfShiftsToEnum(urlParams.numberOfShifts) : undefined,
    }

    return filterSettings;
}

export function getURLParamsFromFilterSettings(jobSearchOptions: JobSearchOptionsModel, filterSettings: SelectedFilters): FilterURLParams {
    const urlParams: FilterURLParams = {
        professionIds: filterSettings.filteredProfession.id,
        specialtyIds: filterSettings.filteredSpecialties
                    .map( fs => fs.id )
                    .join(","),
        stateIds:  filterSettings.filteredLocations
                    .filter(location => location.city == null)
                    .map(state => state.id)
                    .join(","),
        cityIds: filterSettings.filteredLocations
                    .filter(location => location.city != null)
                    .map(city => city.id + "_" + city.radius)
                    .join(","),

        shiftCategoryId: filterSettings.shiftCategory?.toString() ?? "",
        numberOfShifts: filterSettings.numberOfShifts?.toString() ?? ""
    };

    return urlParams;
}

function getProfessionsFromProfessionIdsString(ids: string | null, options: Profession[]) : Profession{
    if(ids === "" || ids === null){return {id: "13", name: "Registered Nurse", webOrder: 0}}
    
    const professionIds = ids.split(",");
    return options.filter(p => professionIds.includes(p.id.toString()))[0];
}

function getSpecialtiesFromSpecialtyIdsString(ids: string | null, options: Specialty[]) : Specialty[]{
    if(ids === "" || ids === null){return []}

    const specialtyIds = ids.split(",");
    const filteredSpecialties = options.filter(p => specialtyIds.includes(p.id.toString()));

    filteredSpecialties.forEach(x => x.id = x.id.toString())
    return filteredSpecialties;
}

function getStatesFromStateIdsString(ids: string | null, options: State[]) : LocationOption[]{
    if(ids === "" || ids === null){return []}

    const stateIds = ids.split(",");
    const states = options.filter(option => stateIds.includes(option.abbreviation.toString()));
    const locations : LocationOption[] = [];
    states.forEach(state => locations.push({id: state.id, name:state.name, state: state, city: null, radius: null, showAllCompact: false}));
    return locations;
}

function getCitiesWithRadiusFromCityIdsString(ids: string | null, cityOptions: City[], stateOptions: State[]) : LocationOption[]{
    if(ids === "" || ids === null){return []}
    
    const citiesWithRadiiStrings = ids.split(',') ?? [];
    const locations : LocationOption[] = []

    citiesWithRadiiStrings.forEach(x => {
        const cityId = x.split("_")[0];
        const radius = x.split("_")[1];
        const city = getCityObjectFromCityId(cityOptions, cityId);
        if(city && radius){
            const cityWithRadius : CityWithRadius = {city: city, radius: radius}
            locations.push({id: cityId, name:`${cityWithRadius.city.name}, ${cityWithRadius.city.stateAbbreviation}`, state: getStateFromAbbreviation(stateOptions, cityWithRadius.city.stateAbbreviation), city: cityWithRadius.city, radius: radius, showAllCompact: false})
        }
    })
    
    return locations;
}

function getStateFromAbbreviation(stateOptions: State[], stateAbbreviation: string) : State {
    return stateOptions.filter(state => state.abbreviation === stateAbbreviation)[0];
}

function getCityObjectFromCityId(cityOptions : City[], cityId : string) : City | undefined{
    return cityOptions.find(s => s.id === cityId);
}

function getShiftCategoryFromId(id: string) : ShiftCategory{
    if(id === "1")
        return ShiftCategory.Days
    
    if(id === "2")
        return ShiftCategory.Nights

    return ShiftCategory.DaysOrNights;
}

function convertNumberOfShiftsToEnum(numberOfShifts: string) : NumberOfShifts{
    if(numberOfShifts === "3")
        return NumberOfShifts.ThreeShifts
    
    if(numberOfShifts === "4")
        return  NumberOfShifts.FourShifts

    if(numberOfShifts === "5")
        return  NumberOfShifts.FiveShifts

    return  NumberOfShifts.AnyNumberOfShifts;
}