export default function displayZip(zip: string) {
    //Canadian Postal Code
    if ( zip.length === 6 && zip.match(/[a-zA-Z]/i) )
    {
        return zip.slice(0,3) + " " + zip.slice(3)
    }

    //US Zipcode
    else if ( zip.length > 5 && !zip.match(/[a-zA-Z]/i))
    {
        return zip.slice(0,5) + "-" + zip.slice(5)
    }

    //Unknown
    else
    {
        return zip;
    }
}