import { useEffect, useState } from "react"
import { HcpProfileData } from "../../tp-core-types/HcpProfileData"
import DateService from "../../utilities/dateService"
import ProfileGrid from "../ProfileGrid/ProfileGrid"
import ProfileGridMobile from "../ProfileGrid/ProfileGridMobile"

type Props = {
    profileData: HcpProfileData
}


export default function ProfileCertifications(props: Props) {
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])
    return (
        <div id="profile-certifications">
            {!isMobile && <ProfileGrid 
                containerHeader={"Certifications"} noDataMessage="No certifications to display. Contact your recruiter for more information."
                itemHeaders={["Certification", "Issue Date", "Expiry Date", "Issued By","Certificate"]} 
                items = {props.profileData.certifications.sort((a, b) => b.createdOrder - a.createdOrder).map((record) => {
                        return { 
                            rows: [
                                record.certificationType && <div data-testid="profile-cert-type">{record.certificationType}</div>,
                                record.completionDate && <div>{DateService.getMonthNameByDate(record.completionDate)} {DateService.getMonthDayYearByDate(record.completionDate)?.year}</div>,                            
                                record.expirationDate && <div>{DateService.getMonthNameByDate(record.expirationDate)} {DateService.getMonthDayYearByDate(record.expirationDate)?.year}</div>,
                                record.issuedBy && <div>{record.issuedBy}</div>,
                                record.certificationType && <div>{record.certificationType}.pdf</div>
                            ]
                        }
                    })
                } />
            }
            {isMobile && <ProfileGridMobile 
                parentContainerHeader={"Certifications"} noDataMessage="No certifications to display. Contact your recruiter for more information."
                containers={
                    props.profileData.certifications.sort((a, b) => b.createdOrder - a.createdOrder).map(record => {
                        return {
                            header: record.certificationType,
                            data: {
                                headers: ["Issue Date", "Expiry Date", "Issued By", "Certificate"],
                                id: record.certId?.toString(),
                                items: [
                                    record.completionDate && <div>{DateService.getMonthNameByDate(record.completionDate)} {DateService.getMonthDayYearByDate(record.completionDate)?.year}</div>,                            
                                    record.expirationDate && <div>{DateService.getMonthNameByDate(record.expirationDate)} {DateService.getMonthDayYearByDate(record.expirationDate)?.year}</div>,
                                    record.issuedBy && <div>{record.issuedBy}</div>,
                                    record.certificationType && <div data-testid="profile-cert-type">{record.certificationType}.pdf</div>
                                ]
                            }
                        }
                    })                    
                }  />}
        </div>
    )
}