import { useContext, useEffect, useState } from "react"
import "./SubmittedJobResults.scss"
import { JobModel } from "../tp-core-types/JobModel"
import { JobSubmissionResult } from "../tp-core-types/JobSubmissionResult";
import JobSearchCard from "../job-search-card/JobSearchCard";
import JobSearchDetails from "../job-search-details/JobSearchDetails";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { SubmittedJobDetail } from "../tp-core-types/SubmittedJobDetail";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import { JobOfferResultItem, JobOffersResult } from "../tp-core-types/JobOffersResult";
import JobOfferCard from "../job-offer-card/JobOfferCard";
import JobOfferDetails from "../job-offer-details/JobOfferDetails";
import { useLocation } from "react-router-dom";


export default function SubmittedJobResultsAndOffers() {
    const userInfoModel = useContext(UserInfoModel)
    const [jobs, setJobs] = useState<JobSubmissionResult>({submittedJobs: []});
    const [selectedJob, setSelectedJob] = useState<JobModel | JobOfferResultItem | null>(null)
    const [submittedJobDetails, setSubmittedJobDetails] =  useState<SubmittedJobDetail | null>(null);
    const [showPanelOnMobile, setShowPanelOnMobile] = useState<boolean>(false);
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);
    const [jobOffers, setJobOffers] = useState<JobOffersResult>();
    const [showOffers, setShowOffers] = useState<boolean>(false);

    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    const location = useLocation();
    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        
        if (Session.getHcpLoginToken() !== ""){
            getAllJobsAndOffers();
            setShowLoginModalVisibility(false);
        } else {
            setShowLoginModalVisibility(true);
        }
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, []);
    
    useEffect(() => {
        if(selectedJob != null){    
            const checkIsTypeJobModel = (value: JobModel | JobOfferResultItem): value is JobModel => { return true };
            if(checkIsTypeJobModel(selectedJob)) {
                    const job = selectedJob as JobModel;
                    getSubmittedJobDetails(job.jobId);
            }
        }
        if(location && location.hash){
            const element = document.querySelector(location.hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: "center" })
                location.hash = "";
            }
        }
    }, [selectedJob,location])

    const getAllJobsAndOffers = async() => {

        const submissionResults = await TPCoreAPI.getSubmittedJobs();
        setJobs(submissionResults);
        
        const offerResults = await TPCoreAPI.getJobOffers();
        setJobOffers(offerResults);
        clearJobSelection();

        setContentLoaded(true);

        if(location && location.state) {
            if(location.state.contractId) {
                setShowOffers(true);
                let selectedIndex = 0;
                if(location && location.state && location.state.contractId){
                    const foundIndex = offerResults.offers.findIndex(f => f.contractId === location.state.contractId);
                    if(foundIndex >= 0) selectedIndex = foundIndex;
                }
                setSelectedJob(offerResults.offers[selectedIndex]);
            }
            else if(location.state.submissionId) {
                setShowOffers(true);
                let selectedIndex = 0;
                if(location && location.state && location.state.submissionId){
                    const foundIndex = offerResults.offers.findIndex(f => f.submissionId === location.state.submissionId);
                    if(foundIndex >= 0) selectedIndex = foundIndex;
                }
                setSelectedJob(offerResults.offers[selectedIndex]);
            }
            else if(location.state.jobId) {
                setShowOffers(false);
                    let selectedIndex = 0;
                    if(location && location.state && location.state.jobId){
                        const foundIndex = submissionResults.submittedJobs.findIndex(f => f.jobId === location.state.jobId);
                        if(foundIndex >= 0) selectedIndex = foundIndex;
                    }
                    setSelectedJob(submissionResults.submittedJobs[selectedIndex]);
            }
        }
        else if(location && location.hash && location.hash === "#submissions"){
            setShowOffers(false);
            if(!isMobile && submissionResults.submittedJobs.length > 0){
                setSelectedJob(submissionResults.submittedJobs[0]);
            }
        }
        else if(location && location.hash && location.hash === "#offers"){
            setShowOffers(true);
            if(!isMobile && offerResults && offerResults.offers.length > 0){
                setSelectedJob(offerResults.offers[0]);
            }
        }
        else if(offerResults && offerResults.offers.length > 0){
            setShowOffers(true);
            if(!isMobile){
                setSelectedJob(offerResults.offers[0]);
            }
        }else if (submissionResults.submittedJobs.length > 0){
            setShowOffers(false);
            if(!isMobile){
                setSelectedJob(submissionResults.submittedJobs[0]);
            }
        }
    }

    useEffect(() => {
        if(isMobile && contentLoaded && selectedJob === null) {
            setShowPanelOnMobile(true)
        }
    }, [isMobile, contentLoaded, selectedJob, submittedJobDetails, location])

    const getSubmittedJobDetails = async (jobId: number) => {
        const results = await TPCoreAPI.getSubmittedJobDetails(jobId);
        setSubmittedJobDetails(results);
    }

    const handleSubmittedJobClick = (job: JobModel) => {
        setSelectedJob(job as JobModel);
    };

    const handleJobOfferClick = (job: JobOfferResultItem) => {
        setSelectedJob(job as JobOfferResultItem);
    };

    const handleLogout = () => {
        setShowLoginModalVisibility(true);
    }

    const handleLogin = async() => {
        setShowLoginModalVisibility(false);
        getAllJobsAndOffers();
    }

    const clearJobSelection = () => {
        setSelectedJob(null);
    }

    const leftPanelContent = () => {
        return (
            <div className="submissions-jobs-container" data-testid="submissions-job-container">
                            <div className="left-pane-header">
                                <div 
                                    data-testid="offers-container" 
                                    onClick={()=> { 
                                        setShowOffers(true);
                                        if(!isMobile) {setSelectedJob(jobOffers?.offers[0] ?? null)} 
                                    }}
                                    className={"left-tab-btn " + (showOffers ? 'btn-selected' : '') } >
                                    <div>{jobOffers?.offers? `Offers (${jobOffers?.offers.length})` : "Offers"}</div>
                                </div>
                                <div 
                                    data-testid="submissions-container" 
                                    className={"left-tab-btn " + (!showOffers ? 'btn-selected' : '') } 
                                    onClick={() => {
                                        setShowOffers(false); 
                                        if(!isMobile) {setSelectedJob(jobs?.submittedJobs[0] ?? null);} 
                                    }}>
                                    <div>{jobs?.submittedJobs? `Submissions (${jobs?.submittedJobs.length})` : "Submissions"}</div>
                                </div>
                            </div>

                        { jobs?.submittedJobs && !showOffers &&
                            <div data-testid="submitted-job-list" className="left-panel-content"> 
                                {jobs.submittedJobs.length !== 0 && jobs.submittedJobs.map((job) => {
                                    return(
                                        <div key={`job${job.jobId}`} id={`job-${job.jobId}`}>
                                            <div data-testid={`job${job.jobId}`} >
                                            <JobSearchCard
                                                job={job} 
                                                handleJobClick={handleSubmittedJobClick} 
                                                selected={job.jobId === selectedJob?.jobId}
                                                isArchived={false}/>
                                            </div>
                                        </div>
                                    )})
                                }          
                                {(jobs.submittedJobs.length ?? 0) === 0 && <div className="none-to-show">No submissions to show</div> }           
                            </div>
                        }
                        {showOffers &&
                            <div data-testid="offers-job-list" className="left-panel-content "> 
                                {contentLoaded && jobOffers?.offers && jobOffers.offers.map((jobOffer) => {
                                    return(
                                        <div key={`job${jobOffer.jobId}`} id={jobOffer.contractId !== null ? `contract-${jobOffer.contractId}` : `offer-${jobOffer.submissionId}`}>
                                            <div className="offer-border" data-testid={`job${jobOffer.jobId}`}>
                                            <JobOfferCard
                                                jobOfferResultItem={jobOffer}
                                                handleJobOfferClick={handleJobOfferClick} 
                                                selected={jobOffer.jobId === selectedJob?.jobId}
                                            />
                                            </div>
                                        </div>
                                    )})
                                }
                                {(jobOffers?.offers.length ?? 0) === 0 && <div className="none-to-show">No offers to show</div>}                   
                            </div>
                        }
                    </div>
        )
    }

    return (
        <div id="SubmittedJobResults">
            <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
            {isMobile && 
                <GenericPageHeader title={contentLoaded ? `Submissions & Offers` : `Loading...`}></GenericPageHeader>
            }
            { !isMobile && 
                <GenericPageHeader title="Submissions & Offers"></GenericPageHeader>
            }
            <div className="container-style" data-testid="container-style">
                <div className="left-pane-container">
                    {showPanelOnMobile &&
                        leftPanelContent()
                    }
                    {!isMobile &&
                        leftPanelContent()
                    }
                </div>
                {!showOffers && selectedJob?.jobId && submittedJobDetails && (
                    <JobSearchDetails 
                        neverShowActionButtons={true}  
                        jobId={selectedJob.jobId}
                        returnToResultsCallback={clearJobSelection}
                        submittedJobDetails={submittedJobDetails} 
                    />
                )}
                {showOffers && selectedJob && (  
                    <JobOfferDetails
                        key={((selectedJob as JobOfferResultItem).contractId)}
                        jobContractId={(selectedJob as JobOfferResultItem).contractId ? (selectedJob as JobOfferResultItem).contractId : null } 
                        jobSubmissionId={(selectedJob as JobOfferResultItem).submissionId}
                        returnToResultsCallback={clearJobSelection}
                        submittedJobDetails={submittedJobDetails} 

                    />
                )}
            </div>
        </div>
    )
}