import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserInfoContextProvider from './utilities/context/UserInfoContextProvider'
import { ErrorContextProvider } from './utilities/context/ErrorContextProvider';
import { BrowserRouter } from 'react-router-dom';
import { FeatureFlagContextProvider } from './utilities/context/FeatureFlagContextProvider';
import { PloppableContextProvider } from './utilities/context/PloppableContextProvider';
import { TRSHeaderContextProvider } from './utilities/context/TRSHeaderContextProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorContextProvider>
      <FeatureFlagContextProvider>
        <UserInfoContextProvider>
          <PloppableContextProvider>
            <BrowserRouter data-testid="router">
              <TRSHeaderContextProvider>
                <App />
              </TRSHeaderContextProvider>
            </BrowserRouter>
          </PloppableContextProvider>
        </UserInfoContextProvider>
      </FeatureFlagContextProvider>
    </ErrorContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
