import "./Application.scss"
import { Routes, Route } from "react-router-dom";
type Props = {
    children: React.ReactElement
}

export default function Application(props:Props){
    return(
        <div id="Application">
            {props.children}
        </div>)
}