import { JobDetailsModel } from "../tp-core-types/JobDetailsModel"
import displayAsMoney from "../utilities/displayAsMoney";
import isArchivedJob from "../utilities/isArchivedJob";
import './JobSearchPayDetails.css';


type Props = {
    jobDetails: JobDetailsModel
};

export default function JobSearchPayDetails(props: Props){

    function getWeeklyTaxableTravelBonus(){
        const weeklyTravelBonus = props.jobDetails.bonuses.find(x => x.name === "Weekly Taxable Travel Bonus")

        return weeklyTravelBonus !== undefined ? weeklyTravelBonus : {name: "No Weekly Travel Bonus", amount: 0};
    }

    return(
        <div className="wrapper" data-testid="pay-details-container">
            <div data-testid="header" className={isArchivedJob(props.jobDetails)? "header archived": "header"}>
                <div className="bold">Detailed Pay Breakdown:</div> 
                <div>Average Weekly Gross: <span className="bold">{displayAsMoney(props.jobDetails.grossWeeklyPay)}</span></div>
            </div>
            <div className="ratesAndStipends">
                <div data-testid="rates-header" className="mainPaySection">
                    <div className="bold ratesSubHeader rateDetailsHeader">HOURLY RATES:</div>
                    <div data-testid= "hourly-rates" className="payDetailItems">
                        <div>Hourly Rate: <span className="bold">{displayAsMoney(props.jobDetails.hourlyRate)}</span></div>
                        {hasDollarAmount(props.jobDetails.onCallRate) && <div>On-Call Rate: <span className="bold">{displayAsMoney(props.jobDetails.onCallRate)}</span></div>}
                        {(hasDollarAmount(props.jobDetails.premiumRate) && props.jobDetails.facility.state !== "CA")&&<div>
                            Premium Rate: <span className="bold">{displayAsMoney(props.jobDetails.premiumRate)}</span>
                        </div>}
                        {props.jobDetails.facility.state === "CA" && 
                        <>
                            <div>Shift Overtime Rate: <span className="bold">{displayAsMoney(props.jobDetails.caliOverTimeRate1)}</span></div>
                            <div>Weekly Overtime Rate: <span className="bold">{displayAsMoney(props.jobDetails.overTimeRate)}</span></div>
                        </>
                        }
                        {props.jobDetails.facility.state !== "CA" && 
                        <>
                            <div>Overtime Rate: <span className="bold">{displayAsMoney(props.jobDetails.overTimeRate)}</span></div>
                        </>
                        }
                        {hasDollarAmount(props.jobDetails.callBackRate) &&
                            <div>Call back Rate: <span className="bold">{displayAsMoney(props.jobDetails.callBackRate)}</span></div>
                        }
                    </div>
                </div>                
                <div data-testid="stipends-header" className="mainPaySection">
                    <div className="bold ratesSubHeader rateDetailsHeader"><span>TAX FREE STIPENDS:</span><span className="bold highlight">{displayAsMoney(getTotalStipends())}/week</span></div>
                    <div data-testid="tax-free-stipends" className="payDetailItems">
                        {hasDollarAmount(props.jobDetails.weeklyMeals) &&<div>Meals/Incidentals: <span className="bold">{displayAsMoney(props.jobDetails.weeklyMeals)}/week</span></div>}
                        {hasDollarAmount(props.jobDetails.weeklyLodging) &&<div>Lodging: <span className="bold">{displayAsMoney(props.jobDetails.weeklyLodging)}/week</span></div>}
                    </div>
                </div>
            </div>
            <div>
            
            {props.jobDetails.shift.includes(',') === false && 
                <div data-testid="weekly-gross-breakdown" className="bonuses">                    
                    <div className="bold ratesSubHeader rateDetailsHeader">WEEKLY GROSS BREAKDOWN:</div>

                    <div className="table">
                        <>
                            <div className="first-cell">
                                Base Pay       
                            </div>
                            <div className="equals-cells">
                                <div className="calculation-cell">
                                    = {props.jobDetails.regularPayHours} hours x {displayAsMoney(props.jobDetails.hourlyRate)}/hour
                                </div>
                                <div className="total-cell">
                                    =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.regularPayPerWeek)}/week</span>
                                </div>
                            </div>
                            <div className="calculation-cell desktop-cell">
                                = {props.jobDetails.regularPayHours} hours x {displayAsMoney(props.jobDetails.hourlyRate)}/hour
                            </div>
                            <div className="total-cell desktop-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.regularPayPerWeek)}/week</span>
                            </div>
                        </>
                        {props.jobDetails.caliOvertimePayHours1 === 0 && props.jobDetails.premiumPayHours > 0 &&
                        <>
                            <div className="first-cell">
                                Premium Pay       
                            </div>
                            <div className="equals-cells">
                                <div className="calculation-cell">
                                    = {props.jobDetails.premiumPayHours} hours x {displayAsMoney(props.jobDetails.premiumRate)}/hour
                                </div>
                                <div className="total-cell">
                                    =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.premiumPayPerWeek)}/week</span>
                                </div>
                            </div>
                            <div className="calculation-cell desktop-cell">
                                = {props.jobDetails.premiumPayHours} hours x {displayAsMoney(props.jobDetails.premiumRate)}/hour
                            </div>
                            <div className="total-cell desktop-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.premiumPayPerWeek)}/week</span>
                            </div>
                        </>}
                        {props.jobDetails.caliOvertimePayHours1 !== 0 &&
                        <>
                            <div className="first-cell">
                                Shift Overtime Pay      
                            </div>
                            <div className="equals-cells">
                                <div className="calculation-cell">
                                    = {props.jobDetails.caliOvertimePayHours1} hours x {displayAsMoney(props.jobDetails.caliOverTimeRate1)}/hour
                                </div>
                                <div className="total-cell">
                                    =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.caliOvertimePayPerWeek1)}/week</span>
                                </div>
                            </div>
                            <div className="calculation-cell desktop-cell">
                                = {props.jobDetails.caliOvertimePayHours1} hours x {displayAsMoney(props.jobDetails.caliOverTimeRate1)}/hour
                            </div>
                            <div className="total-cell desktop-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.caliOvertimePayPerWeek1)}/week</span>
                            </div>
                        </>}
                        {props.jobDetails.overtimePayHours > 0 &&
                        <>
                            <div className="first-cell">
                            {props.jobDetails.caliOvertimePayHours1 === 0 &&
                            <>
                                Overtime Pay
                            </>
                            }
                            {props.jobDetails.caliOvertimePayHours1 !== 0 &&
                            <>
                                Weekly Overtime Pay
                            </>
                            }
                            </div>
                            <div className="equals-cells">
                                <div className="calculation-cell">
                                    = {props.jobDetails.overtimePayHours} hours x {displayAsMoney(props.jobDetails.overTimeRate)}/hour
                                </div>
                                <div className="total-cell">
                                    =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.overtimePayPerWeek)}/week</span>
                                </div>
                            </div>
                            <div className="calculation-cell desktop-cell">
                                = {props.jobDetails.overtimePayHours} hours x {displayAsMoney(props.jobDetails.overTimeRate)}/hour
                            </div>
                            <div className="total-cell desktop-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.overtimePayPerWeek)}/week</span>
                            </div>
                        </>
                        }
                        
                        <div className="first-cell">
                            Tax Free Stipends (Meals/Incidentals & Lodging)
                        </div>
                        <div className="equals-cells">
                            <div className="calculation-cell">
                            </div>
                            <div className="total-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(getTotalStipends())}/week</span>
                            </div>
                        </div>
                        <div className="calculation-cell desktop-cell">
                        </div>
                        <div className="total-cell desktop-cell">
                            =&nbsp;<span className="bold">{displayAsMoney(getTotalStipends())}/week</span>
                        </div>
                        {getWeeklyTaxableTravelBonus().amount > 0 && 
                        <>
                            <div className="first-cell">
                                Weekly Taxable Travel Bonus
                            </div>
                            <div className="equals-cells">
                                <div className="calculation-cell">
                                </div>
                                <div className="total-cell">
                                    =&nbsp;<span className="bold">{displayAsMoney(getWeeklyTaxableTravelBonus()?.amount)}/week</span>
                                </div>
                            </div>
                            <div className="calculation-cell desktop-cell">
                            </div>
                            <div className="total-cell desktop-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(getWeeklyTaxableTravelBonus()?.amount)}/week</span>
                            </div>
                        </>
                        }
                        <div className="first-cell bold highlight">
                            Total Weekly Gross Pay
                        </div>
                        <div className="equals-cells highlight">
                            <div className="calculation-cell">
                            </div>
                            <div className="total-cell">
                                =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.grossWeeklyPay)}</span>
                            </div>
                        </div>
                        <div className="calculation-cell desktop-cell highlight">
                        </div>
                        <div className="total-cell desktop-cell highlight">
                            =&nbsp;<span className="bold">{displayAsMoney(props.jobDetails.grossWeeklyPay)}</span>
                        </div>
                        
                    </div>


                </div>
            }
            </div>

            {props.jobDetails.bonuses.length > 0  && 
                <div data-testid="bonuses" className="bonuses">
                <div className="bold bonusSubHeader">BONUSES:</div>
                    {props.jobDetails.bonuses.map((bonus, index) => {
                        if(bonus.name !== "Weekly Taxable Travel Bonus"){
                            return <div key={index}><span>{bonus.name}</span>: <span className="bold">{displayAsMoney(bonus.amount)}</span></div>
                        }
                        return null
                    })} 
            </div>}
        </div>
    )

    function getTotalStipends(): number | undefined {
        const meals = props.jobDetails.weeklyMeals ?? 0;
        const lodging = props.jobDetails.weeklyLodging ?? 0;
        return meals + lodging;
    }

    function hasDollarAmount(dollarAmount: number | undefined) {
        if (dollarAmount){
            return  dollarAmount > 0;
        }
    }
}