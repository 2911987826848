import { useEffect, useState } from "react";
import empty_star from "../assets/empty_star.png";
import full_star from "../assets/full_star.png";
import "./AddToFavoriteButton.css"
import { Session } from "../utilities/Session";
import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";

type Props = {
    jobDetails: JobDetailsModel
    postFavoriteChange : (isFavorited: boolean) => Promise<void>
    showLogin: (afterLogin: () => Promise<void>) => void

}

export default function AddToFavoriteButton(props: Props) {
    const [isFlipped, setIsFlipped] = useState(false);

    const [transitionText, setTransitionText] = useState(props.jobDetails.favorited ? "Removing from Favorites" : "Adding to Favorites")

    useEffect(() => {
        setIsFlipped(false);
        setTransitionText(props.jobDetails.favorited ? "Removing from Favorites" : "Adding to Favorites");
    }, [props.jobDetails]);

    const handleAddToFavorites = () => {
        if(Session.getHcpLoginToken() === "") {
            props.showLogin(() => props.postFavoriteChange(true));
        }        
        else
        {
            props.postFavoriteChange(true)
            setIsFlipped(true);  
        }
        
    }
    
    const handleRemoveFromFavorites = () => {

        if(Session.getHcpLoginToken() === "") {
            props.showLogin(() => props.postFavoriteChange(false));
        } else {
            props.postFavoriteChange(false)
            setIsFlipped(true); 
        }  
    }

    const addToFavoritesButton = (
        <button data-testid='add-to-favorites-button' onClick={handleAddToFavorites} className='favorite-button'>
            <img src={empty_star} alt="empty star" data-testid='add-to-favorites-empty-star' className='favorite-button-img'></img>
            Add to Favorites
        </button>
    );

    const removeFromFavoritesButton = (
        <button data-testid='remove-from-favorites-button' onClick={handleRemoveFromFavorites} className="favorite-button">
            <img src={full_star} alt="full star" data-testid='remove-from-favorites-full-star' className='favorite-button-img'></img>
            Remove from Favorites
        </button>
    );

    return (
        <div className={`flip-container ${isFlipped ? " added": ""}`} >
            <div className="flipper">
                <div className="flipper-front">
                    {props.jobDetails.favorited ? removeFromFavoritesButton : addToFavoritesButton}
                </div>
                <div className={`flipper-back ${isFlipped ? " addedFavorite" : ""}`} data-testid="successfully-added-message">
                    {transitionText}
                </div>
            </div>
        </div>
    )
}