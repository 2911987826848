import { TimesheetPayPeriod } from "../tp-core-types/Timesheet"
import { useEffect, useRef, useState } from "react";
import displayAsDate from "../utilities/displayAsDate";
import "./TimesheetPayPeriods.scss";
import lock from "../assets/lock.png";

type Props = {
    payPeriods: Array<TimesheetPayPeriod> | null,
    handlePayPeriodSelected: (newValue: number) => void
}

export default function TimesheetPayPeriods(props: Props){
    const [timesheet, setTimesheet] = useState<TimesheetPayPeriod[]>([]);
    
    return (
    <div id="TimesheetPayPeriods"> 
        {

        props.payPeriods?.map( (payPeriod) =>{
            return(
                <div key={payPeriod.timesheetId} className="pay-period-item" onClick={() => props.handlePayPeriodSelected(payPeriod.timesheetId)}>
                    {payPeriod.isLocked && 
                    <div className="image-container">
                        <img src={lock}></img>
                    </div>
                    }
                    <p className="centered-text">
                        {displayAsDate(payPeriod.startDate)} - {displayAsDate(payPeriod.endDate)}
                    </p>
                </div>)
        } )
        }
        {props.payPeriods?.length==0 && 
        <div>No pay periods available for the selected contract.</div>
        }
    </div>
    )

}
 