import './UpdateBackgroundQuestions.scss';
import { useEffect, useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";

import { BackgroundAnswers } from "../tp-core-types/BackgroundAnswers";
import { BackgroundAnswer } from "../HCPApplication/background-questions/BackgroundAnswer";

import { BackgroundQuestion } from "../HCPApplication/background-questions/BackgroundQuestion";
import SingleBackgroundQuestion from "../HCPApplication/background-questions/SingleBackgroundQuestion";
import StartEndDates from "../HCPApplication/background-questions/StartEndDates";
import SubmitStatement from "../HCPApplication/background-questions/SubmitStatement";
import { useError } from "../utilities/context/useError";
import GenericPageHeader from '../generic-page-header/GenericPageHeader';
import UpdateBackgroundQuestionsSubmitModal from './UpdateBackgroundQuestionsSubmitModal';



export default function UpdateBackgroundQuestions() {
    const marketing_url = process.env.REACT_APP_MARKETING_BASE_URL;
    const { setError } = useError();

    const defaultAnswer: BackgroundAnswer = {
        isAnswered: false,
        answer: false,
    };

    const [militaryStart, setMilitaryStart] = useState<Date | null>(null);
    const [militaryEnd, setMilitaryEnd] = useState<Date | null>(null);
    const [isCaliforniaResident, setCaliforniaResident] = useState<boolean>(false);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    

    const [q1Answer, setq1Answer] = useState(defaultAnswer);
    const [q2Answer, setq2Answer] = useState(defaultAnswer);
    const [q3Answer, setq3Answer] = useState(defaultAnswer);
    const [q4Answer, setq4Answer] = useState(defaultAnswer);
    const [q5Answer, setq5Answer] = useState(defaultAnswer);
    const [q6Answer, setq6Answer] = useState(defaultAnswer);
    const [q7Answer, setq7Answer] = useState(defaultAnswer);
    const [q8Answer, setq8Answer] = useState(defaultAnswer);
    const [q9Answer, setq9Answer] = useState(defaultAnswer);
    const [q10Answer, setq10Answer] = useState(defaultAnswer);



    const q1: BackgroundQuestion = {
        questionNumber: 1,
        questionText:
            'Are you a citizen or permanent resident of the <span style="font-weight: bold">United States?</span>',
    };
    const q2: BackgroundQuestion = {
        questionNumber: 2,
        questionText: 'Are you legally authorized to work in the <span style="font-weight: bold">United States?</span>',
    };
    const q3: BackgroundQuestion = { questionNumber: 3, questionText: "Have you served in the Military?" };
    const q4: BackgroundQuestion = {
        questionNumber: 4,
        questionText: 'Are you a permanent resident of the <span style="font-weight: bold">State of California?</span>',
    };
    const q5: BackgroundQuestion = {
        questionNumber: 5,
        questionText:
            "Have you ever been named as a defendant in a malpractice claim, suit settlement, or has a judgment been made against you?",
    };
    const q6: BackgroundQuestion = {
        questionNumber: 6,
        questionText:
            "Has any professional license or certification ever been or currently in the process of being investigated, denied, revoked, suspended, reduced, limited, placed on probation, terminated, or placed under other disciplinary actions?",
    };
    const q7: BackgroundQuestion = {
        questionNumber: 7,
        questionText:
            "Are there any reasons that would prevent you from competently performing the job related functions of a traveler?",
    };
    const q8: BackgroundQuestion = {
        questionNumber: 8,
        questionText:
            "Have you ever been convicted of, or pleaded guilty or no contest to a criminal felony or misdemeanor, or are currently under indictment for any alleged criminal activities?",
    };
    const q9: BackgroundQuestion = {
        questionNumber: 9,
        questionText:
            "Have you ever been the object of an administrative, civil or criminal complaint or investigation regarding sexual misconduct?",
    };
    const q10: BackgroundQuestion = {
        questionNumber: 10,
        questionText: "Are you fluent in any other language besides English?",
    };

    const isMobile = window.matchMedia("(max-width: 1249px)").matches;

    

    useEffect(() => {
        if (
        q1Answer.isAnswered &&
        q2Answer.isAnswered &&
        q3Answer.isAnswered &&
        q4Answer.isAnswered &&
        q5Answer.isAnswered &&
        q6Answer.isAnswered &&
        q7Answer.isAnswered && 
        q8Answer.isAnswered &&
        q9Answer.isAnswered &&
        q10Answer.isAnswered) {
            setIsFormComplete(true)
        }

    }, [q1Answer.isAnswered, q2Answer.isAnswered, q3Answer.isAnswered, q4Answer.isAnswered, q5Answer.isAnswered, q6Answer.isAnswered, q7Answer.isAnswered, q8Answer.isAnswered, q9Answer.isAnswered, q10Answer.isAnswered])

    async function handleSave() {
        
        const answers: BackgroundAnswers = {
            isUsCitizen: q1Answer.answer,
            isAuthorizedToWorkInUs: q1Answer.answer ? null : q2Answer.answer,
            hasMilitaryService: q3Answer.answer,
            militaryStartDate: militaryStart,
            militaryEndDate: militaryEnd,
            isCaliforniaResident: q4Answer.answer,
            liabilityAction: q5Answer.answer,
            licenseSuspended: q6Answer.answer,
            canPerformTravelerTasks: q7Answer.answer,
            convicted: q8Answer.answer,
            hasHadMisconductActivity: q9Answer.answer,
            isBilingual: q10Answer.answer,
        };

        try{
            TPCoreAPI.postUpdateBackgroundAnswers(answers);
            setIsModalVisible(true);
        } catch {
            setError( new Error("Oops... looks like something went wrong. Please try again."))
        }
    }



    useEffect(() => {
        TPCoreAPI.getUpdateBackgroundAnswers()
            .then((answers: BackgroundAnswers) => {
                setq1Answer(setExistingAnswerState(answers.isUsCitizen));
                setq2Answer(setExistingAnswerState(answers.isAuthorizedToWorkInUs));
                setq3Answer(setExistingAnswerState(answers.hasMilitaryService));
                setq4Answer(setExistingAnswerState(answers.isCaliforniaResident));
                setq5Answer(setExistingAnswerState(answers.liabilityAction));
                setq6Answer(setExistingAnswerState(answers.licenseSuspended));
                setq7Answer(setExistingAnswerState(answers.canPerformTravelerTasks));
                setq8Answer(setExistingAnswerState(answers.convicted));
                setq9Answer(setExistingAnswerState(answers.hasHadMisconductActivity));
                setq10Answer(setExistingAnswerState(answers.isBilingual));

                setMilitaryStart(setExistingDateAnswer(answers.militaryStartDate));
                setMilitaryEnd(setExistingDateAnswer(answers.militaryEndDate));
            })
            .catch(() => {
                return;
            });
    }, []);

    useEffect(() => {
        setCaliforniaResident(q4Answer.answer)
    }, [q4Answer, setq4Answer]);

    useEffect(() => {
        if(isModalVisible){
            setTimeout(()=> { 
                setIsModalVisible(false); }, 2000)
        }
    }, [isModalVisible]);

    useEffect(() => {
        setIsFormComplete(false);
        const isCitizen = q1Answer.isAnswered && q1Answer.answer;
        const canWork = q1Answer.isAnswered && !q1Answer.answer && q2Answer.isAnswered && q2Answer.answer;
        const hasValidMilitaryAnswer = q3Answer.isAnswered && ((q3Answer.answer === true  && militaryStart) || q3Answer.answer === false );
        if (
            (isCitizen || canWork) &&
            hasValidMilitaryAnswer &&
            q4Answer.isAnswered &&
            q5Answer.isAnswered &&
            q6Answer.isAnswered &&
            q7Answer.isAnswered &&
            (q8Answer.isAnswered || isCaliforniaResident) &&
            (q9Answer.isAnswered || isCaliforniaResident) &&
            q10Answer.isAnswered
            
        ) {
            setIsFormComplete(true);
        }
    }, [
        q1Answer.isAnswered,
        q1Answer.answer,
        q2Answer.isAnswered,
        q2Answer.answer,
        q3Answer.isAnswered,
        q3Answer.answer,
        q4Answer.isAnswered,
        q4Answer.answer,
        q5Answer.isAnswered,
        q5Answer.answer,
        q6Answer.isAnswered,
        q6Answer.answer,
        q7Answer.isAnswered,
        q7Answer.answer,
        q8Answer.isAnswered,
        q8Answer.answer,
        q9Answer.isAnswered,
        q9Answer.answer,
        q10Answer.isAnswered,
        q10Answer.answer,
        militaryStart,
        isCaliforniaResident
    ]);

    function navigateToTRSInternational() {
        window.location.href = `${marketing_url}/international/application/`;
    }

    function setExistingAnswerState(backgroundAnswerField: boolean | null) {
        return {
            isAnswered: backgroundAnswerField !== null,
            answer: backgroundAnswerField !== null ? backgroundAnswerField : false,
        };
    }

    function setExistingDateAnswer(answer: any) {
        if (!answer) return null;
       
        const convertedDate = new Date(Date.parse(answer));
        return convertedDate;
        
    }

    return (
        <div id="UpdateBackgroundQuestions">
            <div data-testid="update-background-questions-title-div">
            <GenericPageHeader title='Update Background Questions'/>
            </div>
            
          
            <div className="submit-button-container">
                <button data-testid="submit-button" id="submit-button" onClick={handleSave} disabled={!isFormComplete}>{isMobile? "Save Responses" :"Save Background Questions"}</button>
            </div>         
     
            <div className="application-page-container">
                {
                    isModalVisible &&
                    <UpdateBackgroundQuestionsSubmitModal/>
                }
                <div className="all-questions-container">
                    <div className="all-questions-container-scroll">
                        <SingleBackgroundQuestion
                            backgroundQuestion={q1}
                            setQuestionAnswer={setq1Answer}
                            currentState={q1Answer}
                            isRequired={true}
                            FollowUpQuestion={null}
                            isCaliforniaResident={null}
                        />
                        {q1Answer.isAnswered && !q1Answer.answer && (
                            <SingleBackgroundQuestion
                                currentState={q2Answer}
                                backgroundQuestion={q2}
                                setQuestionAnswer={setq2Answer}
                                isRequired={true}
                                FollowUpQuestion={null}
                                isCaliforniaResident={null}
                            />
                        )}
                        {q1Answer.isAnswered && !q1Answer.answer && q2Answer.isAnswered && !q2Answer.answer && (
                            <div className="nonus-international-container">
                                <div className="international-text">
                                    Since you are not eligible to work in the United States, you may be a good fit for our
                                    TRS International services.
                                </div>     
                                <div className="trs-international-button" onClick={() => navigateToTRSInternational()}>
                                    Apply with TRS International
                                </div>                       
                            </div>
                        
                        )}
                        {(!q1Answer.isAnswered || !q2Answer.isAnswered || q1Answer.answer || q2Answer.answer) && (
                            <>
                          
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q3}
                                    setQuestionAnswer={setq3Answer}
                                    currentState={q3Answer}
                                    isRequired={true}
                                    isCaliforniaResident={null}
                                    FollowUpQuestion={
                                        {
                                            component: <StartEndDates setMilitaryStart={setMilitaryStart} setMilitaryEnd={setMilitaryEnd} militaryStart={militaryStart} militaryEnd={militaryEnd}/>, 
                                            isVisible: q3Answer.answer
                                        }
                                    }
                                />                       
                                
                              <SingleBackgroundQuestion
                                    backgroundQuestion={q4}
                                    setQuestionAnswer={setq4Answer}
                                    currentState={q4Answer}
                                    isRequired={true}
                                    FollowUpQuestion={null}
                                    isCaliforniaResident={null}
                                />
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q5}
                                    setQuestionAnswer={setq5Answer}
                                    currentState={q5Answer}
                                    isRequired={true}
                                    isCaliforniaResident={null}
                                    FollowUpQuestion={
                                        {
                                            component: <SubmitStatement/>, 
                                            isVisible: q5Answer.answer
                                        }
                                    }
                                />
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q6}
                                    setQuestionAnswer={setq6Answer}
                                    currentState={q6Answer}
                                    isRequired={true}
                                    isCaliforniaResident={null}
                                    FollowUpQuestion={
                                        {
                                            component: <SubmitStatement/>, 
                                            isVisible: q6Answer.answer
                                        }
                                    }
                                />
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q7}
                                    setQuestionAnswer={setq7Answer}
                                    currentState={q7Answer}
                                    isRequired={true}
                                    isCaliforniaResident={null}
                                    FollowUpQuestion={
                                        {
                                            component: <SubmitStatement/>, 
                                            isVisible: q7Answer.answer
                                        }
                                    }
                                />
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q8}
                                    setQuestionAnswer={setq8Answer}
                                    currentState={q8Answer}
                                    isRequired={true}
                                    isCaliforniaResident={isCaliforniaResident}
                                    FollowUpQuestion={
                                        {
                                            component: <SubmitStatement/>, 
                                            isVisible: q8Answer.answer
                                        }
                                    }
                                />
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q9}
                                    setQuestionAnswer={setq9Answer}
                                    currentState={q9Answer}
                                    isRequired={true}
                                    isCaliforniaResident={isCaliforniaResident}
                                    FollowUpQuestion={
                                        {
                                            component: <SubmitStatement/>, 
                                            isVisible: q9Answer.answer
                                        }
                                    }
                                />
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q10}
                                    setQuestionAnswer={setq10Answer}
                                    currentState={q10Answer}
                                    isRequired={true}
                                    FollowUpQuestion={null}
                                    isCaliforniaResident={null}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
