import { useContext } from "react";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import down_arrowhead from "../assets/down-arrowhead.svg";
import menu_person from "../assets/user-menu-person.svg";
import "./UserMenu.css"

type Props = {
    showLoginDialog: () => void
    setShowMenuModal: (isVisible: boolean) => void
    showUserMenuModal: boolean
    }

export default function UserMenu(props: Props) {
    const userInfoModel = useContext(UserInfoModel); 
    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    return(
    <>
        { userInfoModel.userInfo.firstName === "" && userInfoModel.token === "" &&
        <div className="right-header">
            <div className="login-button traveler-login" data-testid="header-login-button" onClick={props.showLoginDialog} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1123_2305)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
                            fill="#000B57"
                        ></path>
                    </g>{" "}
                    <defs>
                        <clipPath id="clip0_1123_2305">
                            <rect width="24" height="24" fill="white"></rect>
                        </clipPath>
                    </defs>
                </svg>
                <span>Traveler Login</span>
            </div>
                <a
                    href="https://app.centrafi.net/Login.aspx"
                    className="login-button hospital-login"
                    rel="noreferrer"
                    target="_blank"
                >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1123_2308)">
                        <path
                            d="M12 7V3H2V21H22V7H12ZM10 19H4V17H10V19ZM10 15H4V13H10V15ZM10 11H4V9H10V11ZM10 7H4V5H10V7ZM20 19H12V9H20V19ZM18 11H14V13H18V11ZM18 15H14V17H18V15Z"
                            fill="#000B57"
                        ></path>
                    </g>{" "}
                    <defs>
                        <clipPath id="clip0_1123_2308">
                            <rect width="24" height="24" fill="white"></rect>
                        </clipPath>
                    </defs>
                </svg>
                Hospital Login
            </a>
            </div>}
            {userInfoModel.token !== "" &&
                <div data-testid='user-menu-container' onClick={()=>{props.setShowMenuModal(!props.showUserMenuModal)}} className= 'user-menu-container'>
                    <img src={menu_person} data-testid='user-menu-person' className='menu-person' alt="login"></img>
                    {!isMobile && <div className='user-name-container'>
                        <div data-testid = "first-name">{userInfoModel.userInfo.firstName}</div>
                        <div data-testid = "last-name">{userInfoModel.userInfo.lastName}</div>
                    </div>}
                    <img src={down_arrowhead} data-testid='down-arrowhead' className='down-arrowhead' alt="login"></img>
                </div>
            }
    </>
    )
}