import { FacilityDetailsModel } from "../tp-core-types/FacilityDetailsModel"
import { JobDetailsModel } from "../tp-core-types/JobDetailsModel"
import getShiftDisplay from "../utilities/getShiftDisplay"

export class JobPostingScriptCreator{
    static getScript = (jobDetailsModel: JobDetailsModel) => {
        const scriptElement = document.createElement("script")
        scriptElement.type = "application/ld+json"

        const data = {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title" : `${jobDetailsModel.profession} (${jobDetailsModel.specialty})`, 
            "description": `${jobDetailsModel.profession} (${jobDetailsModel.specialty}), ${getShiftDisplay(jobDetailsModel.shiftCategory as number).detailText}, ${jobDetailsModel.shift}`,
            "datePosted" : jobDetailsModel.createDateTime.split("T")[0],
            "employmentType" : "TEMPORARY",
            "hiringOrganization" : {
                "@type" : "Organization",
                "name" : "TRS Healthcare",
                "sameAs" : "https://www.trshealthcare.com",
                "logo": "https://my.trshealthcare.com/static/media/trs_logo.21db5fa15525d1e0a2724b510e8205fe.svg"
            },
            "qualifications" : JobPostingScriptCreator.getQualifications(jobDetailsModel.minimumRequirements),
            "jobBenefits": JobPostingScriptCreator.getJobBenefits(jobDetailsModel.benefitsOfTrs),
            "validThrough": JobPostingScriptCreator.getValidThroughDate(jobDetailsModel.startDate),
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                  "@type": "QuantitativeValue",
                  "value": jobDetailsModel.grossWeeklyPay,
                  "unitText": "WEEK"
                }
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": JobPostingScriptCreator.getFacilityStreetAddress(jobDetailsModel.facility),
                    "addressLocality": jobDetailsModel.facility.city,
                    "addressRegion": jobDetailsModel.facility.state,
                    "postalCode": jobDetailsModel.facility.zip,
                    "addressCountry": "US"
                }
            },
            "identifier": {
                "@type": "PropertyValue",
                "name": "TRS Healthcare",
                "value": jobDetailsModel.jobId
            }
        }

        scriptElement.innerHTML = JSON.stringify(data);
        return scriptElement;
    }

    static getQualifications = (qualifications: string[]) => {
        let ulElement = "<ul>"
        qualifications.forEach((qualification) => {
            ulElement = ulElement.concat(`<li>${qualification}</li>`)
        })
        return ulElement.concat("</ul>")
    } 

    static getJobBenefits = (benefits: string[]) => {
        let ulElement = "<ul>"
        benefits.forEach((benefit) => {
            ulElement = ulElement.concat(`<li>${benefit}</li>`)
        })
        return ulElement.concat("</ul>")
    }

    static getFacilityStreetAddress = (facilityDetails: FacilityDetailsModel) => {
        let streetAddress = facilityDetails.address1;
        if (facilityDetails.address2) {
            streetAddress = streetAddress?.concat(" " + facilityDetails.address2)
        }
        return streetAddress
    }

    static getValidThroughDate = (createDateTime: string) => {
        const dateTime = new Date(createDateTime);
        dateTime.setDate(dateTime.getDate() + 28)
        return dateTime.toISOString().split("T")[0]
    }
}