import { useContext, useEffect, useState } from "react"
import TimesheetMobile from "./TimesheetMobile";
import TimesheetDesktop from "./TimesheetDesktop";
import { GetHcpActiveContractsWithTimesheetsResult, TimesheetPayPeriod } from "../tp-core-types/Timesheet";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";

export default function Timesheet(){
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [selectedContract, setSelectedContract] = useState<number|null>(null);
    const [selectedTimesheet, setSelectedTimesheet] = useState<number|null>(null);
    const [timesheetData, setTimesheetData] = useState<GetHcpActiveContractsWithTimesheetsResult[]>([]);
    const [payPeriods, setPayPeriods] = useState<Array<TimesheetPayPeriod>| null>(null);
  
    useEffect(()=>{
        getTimesheetData();
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    useEffect(()=>{
        setPayPeriodFromContract();
    }, [selectedContract]);

    useEffect(() => {
        setPayPeriodFromContract();
    }, [timesheetData])

    const setPayPeriodFromContract = () => {
        
        if(!selectedContract && timesheetData.length > 0) setSelectedContract(timesheetData[0].contractNum);

        const defaultSelected = selectedContract ? selectedContract : timesheetData.length > 0 ? timesheetData[0].contractNum : [];
        for(let i=0;i<timesheetData.length;i++){
            const contract = timesheetData[i];

            if(contract.contractNum == defaultSelected)
            {            
                let pp = contract.timesheets;
                pp = pp.sort( (a,b)=>{return b.startDate.localeCompare(a.startDate)});
                setPayPeriods(pp);
            }
        }
    }
    const getTimesheetData = async() => {
        const timesheetDataResult = await TPCoreAPI.getHcpActiveContractsWithTimesheets();
        if(selectedContract === null && timesheetDataResult.length > 0) {
            setSelectedContract(timesheetDataResult[0].contractNum);

            setTimesheetData(timesheetDataResult);
            setPayPeriodFromContract();
        }
    }
    function handleChangeEvent(newContractNum: number | null, newTimesheetId: number | null){
        setSelectedContract(newContractNum)
        setSelectedTimesheet( newTimesheetId)

    }
     
    return (
            <>{isMobile && selectedContract && payPeriods && timesheetData &&
                <TimesheetMobile handleContractChange={(newcontract, newTimesheet)=> handleChangeEvent(newcontract, newTimesheet) } selectedContract={selectedContract} selectedTimesheet={selectedTimesheet} contractData={timesheetData} payPeriods={payPeriods} ></TimesheetMobile>
            }
            {!isMobile && selectedContract && payPeriods && timesheetData &&
                <TimesheetDesktop handleContractChange={(newcontract, newTimesheet)=> handleChangeEvent(newcontract, newTimesheet) } selectedContract={selectedContract}  selectedTimesheet={selectedTimesheet} contractData={timesheetData} payPeriods={payPeriods}></TimesheetDesktop>
             }
             </>
    )
 
}