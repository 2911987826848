import { ChangeEvent, useState } from "react";
import { HshChecklistItem } from "../../tp-core-types/HshChecklistItems";

interface Props {
    checklistItem: HshChecklistItem
    updateSelectedChecklists: (id: string) => void;
}

export default function SkillsChecklistCheckBox (props: Props){
    const [isChecked, setIsChecked] = useState<boolean>(true);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

    return(
        <div className="checklist-item">
            <input 
                data-testid="checklist-checkbox" 
                className="application-checkbox" 
                type="checkbox" 
                checked = {isChecked}
                id={props.checklistItem.hshItemId} 
                value={props.checklistItem.hshItemId ?? Math.random().toString()}
                onChange={ e => props.updateSelectedChecklists(props.checklistItem.hshItemId)}
                onClick={handleCheckboxChange}>
            </input>
            <label htmlFor={props.checklistItem.hshItemId} data-testid={props.checklistItem.hshItemId}  className="checklist-item-name">
                {props.checklistItem.hshItemLabel}
                {/* {isMobile && <>{checklistItem.specialty}</>} */}
                {/* {!isMobile && <>{checklistItem.discipline} - {checklistItem.specialty}</>} */}
            </label>                                  
        </div>
    );
}