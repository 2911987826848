import { useEffect, useState } from "react";
import { HcpDegreeType } from "../../tp-core-types/HcpDegreeTypes";
import { HcpDegreeField } from "../../tp-core-types/HcpDegreeFields";
import { HcpDegree } from "../../tp-core-types/HcpDegree";
import TrashCan from "../../assets/trash-can.svg";
import DisabledTrashCan from "../../assets/disabled-trash-can.svg";
import Verified from "../../assets/verified.png"


type EducationRecordProps = {
    degreeTypes: HcpDegreeType[],
    degreeFields: HcpDegreeField[],
    degreeData: HcpDegree,
    setNewRecordDisabled: (v:boolean) => void,
    updateRecord: (degreeData: HcpDegree) => void,
    deleteRecord: (degreeData: HcpDegree) => void,
    recordNumber: number,
    numberOfRecords: number
}

export default function EducationInfoRecord(props: EducationRecordProps) {
    const degreeType = !isNullOrEmpty(props.degreeData.degreeTypeId) ? (props.degreeTypes?.find(t => t.degreeTypeId === props.degreeData.degreeTypeId)?.degreeType) || "" : "";
    const degreeField = !isNullOrEmpty(props.degreeData.degreeFieldId) ? (props.degreeFields?.find(t => t.degreeFieldId === props.degreeData.degreeFieldId)?.degreeField) || "" : "";
    const degreeMonth = !isNullOrEmpty(props.degreeData.degreeDate) ? props.degreeData.degreeDate.split("/")[0] : "";
    const degreeYear = !isNullOrEmpty(props.degreeData.degreeDate) ? props.degreeData.degreeDate.split("/")[1] : "";
    
    const [degree, setDegree] = useState<HcpDegree>(props.degreeData);
    const [headerText, setHeaderText] = useState<string>("Education Record 1");
    const [headerType, setHeaderType] = useState<string>(degreeType);
    const [headerField, setHeaderField] = useState<string>(degreeField);
    const [graduationMonth, setGraduationMonth] = useState<string>(degreeMonth);
    const [graduationYear, setGraduationYear] = useState<string>(degreeYear);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
    const [degreeSaved, setDegreeSaved] = useState<boolean>(degree.degreeId !== null ? true : false);
    
    function isNullOrEmpty(value: any) {
        return value === "" || value === 0 || value === undefined || value === null
    }

    function getHeaderDisplayField(selectedFieldId:string) : string {
        const field = (props.degreeFields?.find(t => t.degreeFieldId.toString() === selectedFieldId)?.degreeField) || "";
        setHeaderField(field);
        return field;
    }

    function getHeaderDisplayType(selectedTypeId:string) : string {
        const type = props.degreeTypes?.find(t => t.degreeTypeId.toString() === selectedTypeId)?.degreeType || "";
        setHeaderType(type);
        return type;
    }

    function saveRecord() {
        props.setNewRecordDisabled(false);
        props.updateRecord(degree);
        setDegreeSaved(true);
    }

    function deleteRecord()  {
        props.deleteRecord(degree);
        if(!degreeSaved) {
            props.setNewRecordDisabled(false);
        }
    }

    function isValidGraduationDate() {
        const currentYear = new Date().getFullYear();
        const numberMonth = parseInt(graduationMonth);
        const numberYear = parseInt(graduationYear);
        return graduationMonth !== ""
            && graduationYear !== ""
            && graduationMonth.length <= 2
            && graduationMonth.length >= 1
            && graduationYear.length === 4
            && (numberMonth !== null || numberMonth !== undefined)
            && numberMonth <= 12 && numberMonth >= 1
            && (numberYear !== null || numberYear !== undefined)
            && numberYear <= (currentYear + 20) && numberYear >= 1850;
    }

    function getDegreeHeader(headerType: string, headerField: string, recordNumber: number) {
        let defaultText = `${headerType} - ${headerField}`;
        if (headerType === "" && headerField === "") defaultText = `Education Record ${recordNumber}`;
        return defaultText;
    }

    useEffect(() => {
        const headerText = getDegreeHeader(headerType, headerField, props.recordNumber);
        setHeaderText(headerText);
    }, [degree.degreeId, headerType, headerField, props.recordNumber])


    useEffect(() => {
        setDegree({...degree, degreeDate: graduationMonth + "/" + graduationYear})
    }, [graduationMonth, graduationYear, degreeSaved])

    useEffect(() => {
        setSaveButtonDisabled(
            degree.degreeTypeId === 0 
            || degree.degreeFieldId === 0
            || degree.school === "" 
            || degree.location === ""
            || isValidGraduationDate() === false)
    }, [degree])
    
    return (
            
            <div className="education-content">
                <div className="education-info-record-header-text">{headerText} {degree.verified && <img src={Verified} className="verified-header-checkmark" alt="green checkmark"/>}</div>
                    {!degreeSaved &&
                    <div className="record-and-button-container"> 
                        <div className="record-container">
                            <div className="education-row">
                                <div>
                                    <div className="form-element">
                                        <label htmlFor="select-degree-type">Type*</label>  
                                        { props.degreeTypes && 
                                            <select value={degree.degreeTypeId ?? 0} id="select-degree-type" onChange={event => {getHeaderDisplayType(event.target.value); setDegree({...degree, degreeTypeId:parseInt(event.target.value)}); }} className="select-box">
                                            <option value={0} key={"emptyOne"}></option>
                                            {props.degreeTypes.map(t => {
                                                return (
                                                    <option key={t.degreeTypeId} value={t.degreeTypeId}>
                                                        {t.degreeType}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        }                                  
                                    </div>
                                </div>
                                <div>
                                    <div className="form-element">
                                    <label htmlFor="select-degree-field">Field of study*</label>  
                                        { props.degreeFields && 
                                            <select value={degree.degreeFieldId ?? 0} id="select-degree-field" onChange={event => {getHeaderDisplayField(event.target.value); setDegree({...degree, degreeFieldId:parseInt(event.target.value)});} } className="select-box">
                                            <option value={0} key={"emptyOne"}></option>
                                            {props.degreeFields.map(t => {
                                                return (
                                                    t.degreeField !== "Other" &&
                                                    <option key={t.degreeFieldId} value={t.degreeFieldId}>
                                                        {t.degreeField}
                                                    </option>
                                                );
                                            })}
                                            <option value={props.degreeFields.find(df => df.degreeField === "Other")?.degreeFieldId}>Other</option>
                                        </select>
                                        }      
                                    </div>
                                </div>
                                <div>
                                    <div className="form-element">
                                        <label htmlFor="input-degree-school">School*</label>
                                        <input className="input-box" id="input-degree-school" value={degree.school} onChange={(event) => setDegree({...degree, school:event.target.value})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="education-row">
                                <div className="form-element">
                                    <label htmlFor="input-degree-location">Location*</label>
                                    <input className="input-box" id="input-degree-location" value={degree.location} onChange={(event) => setDegree({...degree, location:event.target.value})}/>
                                </div>
                                <div className="form-element" >
                                    <div className="graduation-label">
                                        Graduation Date*
                                    </div>
                                    <div className="graduation-input-container">
                                        <input className="month-input-box" pattern="[0-9]*" type="text" inputMode="numeric" maxLength={2} placeholder="MM" onChange={(event) => setGraduationMonth(event.target.value)} data-testid="input-degree-graduation-month"></input>
                                        <input className="year-input-box" pattern="[0-9]*" type="text" inputMode="numeric" maxLength={4} placeholder="YYYY" onChange={(event) => setGraduationYear(event.target.value)} data-testid="input-degree-graduation-year"></input>
                                    </div>
                                </div>
                            </div>
                        
                    </div>

                    <div className="education-button-container">
                        <div className="delete-record-button-container">
                            <button data-testid="delete-button" className="delete-record-button" onClick={() => deleteRecord()} disabled={props.numberOfRecords===1 || degree.verified}>
                                <div className="record-button-sub-container">
                                    { (props.numberOfRecords === 1 || degree.verified) && <img src={DisabledTrashCan} alt="trash can"/> }
                                    { (props.numberOfRecords !== 1 && !degree.verified) && <img src={TrashCan} alt="trash can"/> }
                                    <div className="delete-record-text">Delete Record</div>
                                </div>
                            </button>
                        </div>
                        <div className="save-record-button-container">
                            <button className="save-record-button" disabled={saveButtonDisabled} onClick={() => saveRecord()}>Save Record</button>
                        </div>
                    </div>
                </div>
            }
            
            
                {degreeSaved && 
                <>
                        <div className="saved-record-container">
                            <div className="saved-degree-type-and-field">{ (degree.degreeTypeId === null ? "Unknown" : headerType)  + " Degree - " + (degree.degreeFieldId === null ? "Unknown Field" : headerField)}</div>
                            <div>{(degree.school.length > 0 ? degree.school : "Unknown School")  + ", " + (degree.location.length > 0 ? degree.location : "Unknown Location")}</div>
                            <div>{(degree.degreeDate !== "/" ? degree.degreeDate : "Unknown Graduation Date")}</div>
                            {degree.verified && 
                                <div className="verified-text">
                                    <img className="verified-text-checkmark" src={Verified} alt="green checkmark"/>
                                    <div data-testid="green-verified-text">TRS has verified this record. It cannot be deleted.</div> 
                                </div>
                            }
                            
                        </div>
                    <div className="education-button-container">
                        <div className="delete-record-button-container">
                           {!degree.verified && 
                            <button data-testid="delete-button" className="delete-record-button" onClick={() => deleteRecord()} disabled={props.numberOfRecords===1 || degree.verified}>
                                    <div className="record-button-sub-container">
                                        { (props.numberOfRecords === 1)  && <img src={DisabledTrashCan} alt="trash can"/> }
                                        { (props.numberOfRecords !== 1) && <img src={TrashCan} alt="trash can"/> }
                                        <div className="delete-record-text">Delete Record</div>
                                    </div>
                                </button>
                            }
                            <div/>
                        </div>
                    </div>
                    
                </>
                }

                

        </div>
    )
}
