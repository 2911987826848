import { DeleteDropboxPayload } from "../tp-core-types/DeleteDropboxPayload";
import { DropboxAttach } from "../tp-core-types/DropboxAttach";
import { DropboxDocumentsResult } from "../tp-core-types/DropboxDocumentsResult";
import { DropboxUpload, DropboxUploadPayload, DropboxUploadResult } from "../tp-core-types/DropboxUpload";
import blobToBase64 from "../utilities/blobToBase64";
import { Session } from "../utilities/Session";

const TPCoreAPIDocuments = {
    getSignedContractDocument: async (contractNum: number, documentMasterId: number) => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Contract/getSignedContractDocument?contractNum=${contractNum}&documentMasterId=${documentMasterId}`,
            options
        );
        return response.ok ? await response.blob() : Promise.reject({});
    },

    getContractDocumentImages: async (contractNum: number, documentMasterId: number): Promise<string[]> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Contract/getContractDocumentImages?contractNum=${contractNum}&documentMasterId=${documentMasterId}`,
            options
        );
        return response.ok ? await response.json() : Promise.reject({});
    },

    getPoliciesAndProceduresDocument: async (fileName: string) => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Document/getPolicyAndProcedureDocuments?documentName=${fileName}`,
            options
        );
        return response.ok ? await response.blob() : Promise.reject({});
    },

    uploadDropboxDocument: async (upload: DropboxUpload): Promise<DropboxUploadResult> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        let base64Blob;
        if(upload.file !== undefined){
            base64Blob = await blobToBase64(upload.file) as string;
        }

        const payload = {
            file: base64Blob ? base64Blob.split('base64,')[1] : undefined,
            fileType: upload.fileType
        } as DropboxUploadPayload

        const options = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Document/uploadDocument`,
            options
        );

        return response.ok ? response.json() : Promise.reject({});
    },

    attachDropboxDocument: async (attachment: DropboxAttach) => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(attachment),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpCredentialsDropbox/attachDocument`,
            options
        );

        return response.ok ? response : Promise.reject({});
    },

    getDropboxDocuments: async (): Promise<DropboxDocumentsResult> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}`, "Content-Type": "application/json" } };
        }

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpCredentialsDropbox/getUploadedDropboxDocuments`,
            options
        );

        return response.ok ? response.json() : Promise.reject({});
    },

    deleteDropboxDocument: async (hcpDropboxId: number) => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify({ hcpDropboxId: hcpDropboxId} as DeleteDropboxPayload),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpCredentialsDropbox/deleteDocument`,
            options
        );

        return response.ok ? response : Promise.reject({});
    },

    getDocumentAttachment: async (filePath: string) => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: 
                { Authorization: `Bearer ${tokenCheck}`,"Content-Type": "application/json"  } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Document/getDocumentAttachment?filePath=${filePath}`,
            options
        );
        return response.ok ? await response.blob() : Promise.reject({});
    },
    getTrsDocument: async (signatureTypeId: number) => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/DocumentsAndSignatures/getTrsDocument?signatureTypeId=${signatureTypeId}`,
            options
        );
        return response.ok ? await response.blob() : Promise.reject({});
    }


    
}

export default TPCoreAPIDocuments;