import { ReactNode, useContext, useState, useEffect } from "react";
import LoginModal from "./LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
type Props = {
 title: string
 children: ReactNode
}
export default function RequiresLogin( props: Props) {
    const  userInfoModel = useContext(UserInfoModel);
    const [loginVisible, setLoginVisible] = useState(!Session.isHcpLoggedIn());

    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        if (Session.isHcpLoggedIn()){
            setLoginVisible(false);
        } else {
            setLoginVisible(true);
        }
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, [])

    const handleLogout = async() => {
        setLoginVisible(true);
    }

    return <>
        <LoginModal 
            isLoginForm={true} 
            isVisible={ loginVisible } 
            setVisibility={function (value: boolean): void {
                throw new Error("Function not implemented.");
            } } 
            successfulLogin={function (): Promise<void> {
                setLoginVisible(false);
                return Promise.resolve();
            } } 
            headerText={`To continue to ${props.title}, please login or create an account below.`} 
            canBeDismissed={false}  />
        {!loginVisible && props.children}
    </>
}