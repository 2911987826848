import React, { useState } from "react"
import { ReactNode } from "react"

export type TRSHeaderContextType = {isTRSHeaderVisible:boolean, setIsTRSHeaderVisible: (isVisible: boolean) => void}
export const TRSHeaderContext = React.createContext<TRSHeaderContextType>({isTRSHeaderVisible: true, setIsTRSHeaderVisible: (isVisible:boolean) => {return}})

export function TRSHeaderContextProvider({children} : {children : ReactNode}) {
    const [visible, setVisible] = useState(true);

    return (
        <TRSHeaderContext.Provider value={{isTRSHeaderVisible: visible, setIsTRSHeaderVisible: (isVisible: boolean) => {setVisible(isVisible)}}}>
            {children}            
        </TRSHeaderContext.Provider>
    )
}