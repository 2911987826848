import { DropboxItem } from "../tp-core-types/DropboxDocumentsResult"
import displayAsDate from "../utilities/displayAsDate"
import pdf_icon from "../assets/pdf_icon.png";
import trash_can from "../assets/trash-can.svg"
import "./DropboxDocument.scss"

type Props = {
    document: DropboxItem;
    deleteDocument: (id: number) => void;
    openDocument: (filePath: string) => void;
}
export default function DropboxDocument(props: Props) {
    return (
        <div className="db-item" >
            <div className="db-item-title">{}</div>
            <div className="db-item-container">
                <div className="db-item-details">
                    <div>{props.document.docTitle}</div>
                    <div>Added: <span className="db-item-date-added">{displayAsDate(props.document.addedDate)}</span></div>
                </div>
                <div className="db-item-pdf-icon"><img alt="PDF Icon" src={pdf_icon}/></div>
            </div>
            <div className="db-item-button-container">
                <div className="db-item-button-delete" data-testid="db-item-button-delete" onClick={() => props.deleteDocument(props.document.hcpDropBoxId)}>
                    <div className="db-item-delete-button-content">
                        <div className="trash-can-container"><img alt="delete" className="trash-can" src={trash_can}/></div>
                        <div>Delete Document</div>
                    </div>
                </div>
                <div className="db-item-button-view" onClick={() => props.openDocument(props.document.uploadedDocumentPath)}>View Document</div>
            </div>
        </div>
    )
}