import JobSearchResults from "../job-search-results/JobSearchResults";
import { useState, useEffect } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { JobSearchOptionsModel } from "../tp-core-types/JobSearchOptionsModel";
import FilterBar from "../filter-bar/FilterBar";
import "./JobSearchPage.scss";
import getFilterSettingsFromURLParams, {
  SelectedFilters,
} from "../utilities/JobFilterCollaborator";
import { SearchPageUrlParameters } from "./SearchPageUrlParameters";
import {memo} from 'react'

type Props = {
  professionId?: string | null,
  specialtyIds?: string | null,
  stateIds?: string | null,
  cityIds?: string | null,
  shiftCategoryId?: string | null,
  numberOfShifts?: string | null,
}

export default memo(function JobSearchPage(props: Props) {
  const [searchParams] = useState(new URLSearchParams(window.location.search))
  const [isFindJobHeaderVisible, setIsFindJobHeaderVisible] = useState(true);

  const isMobile = window.matchMedia("(max-width: 1250px)").matches;

  const [searchParameters] = useState<SearchPageUrlParameters>({
    professionId: searchParams.get("professionId") ?? props.professionId ?? null,
    specialtyIds: searchParams.get("specialtyIds") ?? props.specialtyIds ?? null,
    stateIds: searchParams.get("stateIds") ?? props.stateIds ?? null,
    cityIds: searchParams.get("cityIds") ?? props.cityIds ?? null,
    shiftCategoryId: searchParams.get("shiftCategoryId") ?? props.shiftCategoryId ?? null,
    numberOfShifts: searchParams.get("numberOfShifts") ?? props.numberOfShifts ?? null
  })

  const [filterOptions, setFilterOptions] = useState<JobSearchOptionsModel>({
    disciplines: [],
    specialties: [],
    cities: [],
    states: [],
  });

  const [selectedFilters, setSelectedFilters] =  useState<SelectedFilters | null>(null);

  // Forces the page to reload when user pressed back button while using Safari
  (function () {
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  })();

  useEffect(() => {
    const getOptions = async () => {
      const options = await TPCoreAPI.getJobSearchOptions();
      setFilterOptions(options);
  
      const selectedFilters = getFilterSettingsFromURLParams(options, {
        professionIds: searchParameters.professionId,
        specialtyIds: searchParameters.specialtyIds,
        stateIds: searchParameters.stateIds,
        cityIds: searchParameters.cityIds,
        shiftCategoryId: searchParameters.shiftCategoryId,
        numberOfShifts: searchParameters.numberOfShifts,
      });

      setSelectedFilters(selectedFilters);
    };
    getOptions();

  }, [searchParameters]);

  return (
    <div id="JobSearchPage">
      <div className="pageContainer">
        {!isMobile && 
        <>
          <div className="desktopHeader" data-testid="desktopHeader">
            <FilterBar
              buttontext="All Filters"
              professionId={searchParameters.professionId}
              specialtyIds={searchParameters.specialtyIds}
              stateIds={searchParameters.stateIds}
              cityIds={searchParameters.cityIds}
              shiftCategoryId={searchParameters.shiftCategoryId}
              numberOfShifts={searchParameters.numberOfShifts}
              filterOptions={filterOptions}
              />
          </div>
          </>
        } 
        {isMobile && isFindJobHeaderVisible &&
          <div className="mobile-filter">
            <FilterBar
              buttontext={
              "Search Filters (" + calculateNumberOfFilters(selectedFilters) + ")"
              }
              professionId={searchParameters.professionId}
              specialtyIds={searchParameters.specialtyIds}
              stateIds={searchParameters.stateIds}
              cityIds={searchParameters.cityIds}
              shiftCategoryId={searchParameters.shiftCategoryId}
              numberOfShifts={searchParameters.numberOfShifts}
              filterOptions={filterOptions}
            />
          </div>
        }
        <JobSearchResults
          urlParameters={searchParameters}
        />
      </div>
    </div>
  );
})
function calculateNumberOfFilters(selectedFilters: SelectedFilters | null) {
  return (
    (selectedFilters?.filteredProfession ? 1 : 0) +
    (selectedFilters?.filteredLocations.length ?? 0) +
    (selectedFilters?.filteredSpecialties.length ?? 0) +
    (selectedFilters?.numberOfShifts ? 1 : 0) +
    (selectedFilters?.shiftCategory ? 1 : 0)
  );
}
