import { useNavigate, useSearchParams } from "react-router-dom";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import { useEffect } from "react";
import "./LoginPage.scss"

export default function LoginPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(Session.getHcpLoginToken()){
            navigate('/dashboard');
        }
    }, [])

    return(
        <div id="LoginPage">
            <LoginModal isLoginForm={true} isVisible={true} setVisibility={() => {return}} successfulLogin={() => new Promise<void>(() => {
                const myUrl = searchParams.get("returnUrl")
                window.open(myUrl ?? "", "_self");
            })} headerText={"Log into your account or sign up below"} canBeDismissed={false}/>
        </div>

    )
}