import { useRef, useState } from "react";
import AllJobFiltersForm from "../all-job-filters-form/AllJobFiltersForm";
import "./AllJobFiltersContainerDesktop.css";
import ExternalJobSearchForm from "../external-job-search-form/ExternalJobSearchForm";

type Props = {
  professionId?: string | null;
  specialtyIds?: string | null;
  stateIds?: string | null;
  cityIds?: string | null;
  shiftCategoryId?: string | null;
  numberOfShifts?: string | null;
  showFiltersContainer: boolean;
  onToggle: () => void;
  searchPageUrl?: string;
  titleText?: string;
};

export default function AllJobFiltersContainerDesktop(props: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [titleText] = useState(props.titleText ?? "All Filters");

  return (
    <>
      {props.showFiltersContainer && (
        <div className="filterContainerDesktopRoot"
          ref={wrapperRef}
          onClick={(event) => {
              props.onToggle();
          }}>
          <div
            data-testid="filter-container-desktop-background"
            className="filterContainerDesktopBackground"
          >
            <div className="filterContainerDesktopLightbox"
              onClick={(e) => e.stopPropagation()}>
              <div className="filtersDesktopLabel">{ titleText }</div >
              {props.searchPageUrl && 
                <ExternalJobSearchForm form_submit_url={props.searchPageUrl} onMultiSelectHide={() => {return}} onMultiSelectShow={() => {return}}></ExternalJobSearchForm>
              }
              {!props.searchPageUrl && 
                <AllJobFiltersForm
                  professionId={props.professionId ?? null}
                  specialtyIds={props.specialtyIds ?? null}
                  stateIds={props.stateIds ?? null}
                  cityIds={props.cityIds ?? null}
                  shiftCategoryId={props.shiftCategoryId ?? null}
                  numberOfShifts={props.numberOfShifts ?? null}
                ></AllJobFiltersForm>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}
