import { useContext } from "react"
import FeatureFlagContext from "../tp-core-types/FeatureFlagsModel";
import SubmittedJobResultsAndOffers from "./SubmittedJobResultsAndOffers";
import SubmittedJobResultsSubmissionsOnly from "./SubmittedJobResultsSubmissionsOnly";

export default function SubmittedJobResults() {
    const ff = useContext(FeatureFlagContext);

    return (
       <SubmittedJobResultsAndOffers/>
    )
}