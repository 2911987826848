import { TimesheetShiftData } from "../tp-core-types/Timesheet"
import "../Timesheet/TimesheetShift.scss";
import displayAsDate from "../utilities/displayAsDate";
import displayAsTime from "../utilities/displayAsTime";
import { useEffect, useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { TimesheetShiftType } from "../tp-core-types/TimesheetShiftType";
type Props = {
    shiftData: TimesheetShiftData
}


export default function TimesheetShift(props: Props){
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [shiftDate, setShiftDate] = useState<string>("");
    const [shiftTypeId, setShiftTypeId] = useState<string>("");
    const [shiftStart, setShiftStart] = useState<string>("");
    const [shiftEnd, setShiftEnd] = useState<string>("");
    const [shiftLunch, setShiftLunch] = useState<string>("");
    const [shiftTypes, setShiftTypes] = useState<TimesheetShiftType[]>([]);
    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const getShiftTypes = async () => {
        const shiftTypesReturn = await TPCoreAPI.getTimesheetShiftTypes(props.shiftData.timesheetId);
        setShiftTypes(shiftTypesReturn);
    }
    useEffect(()=>{
        if(props.shiftData.timesheetId)
            getShiftTypes();
    },[props.shiftData.timesheetId]);

    useEffect(() => {
        if(props.shiftData.shiftId == 0)
            setIsEditing(true);
    }, [])
    return (
        <>
        { !isEditing && 
        <div id="TimesheetShift"> 
            <div className="TimesheetShift" data-testid="timesheetShift-display">
                <div className="shift-item">Date: <span className="item-value">{displayAsDate(props.shiftData.shiftDate)}</span></div>
                <div className="shift-item">Shift Type: <span className="item-value">{props.shiftData.shiftType}</span></div>
                <div className="shift-item">Start Time: <span className="item-value">{displayAsTime(props.shiftData.startTime)}</span></div>
                <div className="shift-item">End Time: <span className="item-value">{displayAsTime(props.shiftData.endTime)}</span></div>
                <div className="shift-item">Unit: <span className="item-value">{props.shiftData.unit}</span></div>
                <div className="shift-item">Lunch: <span className="item-value">{props.shiftData.lunch} minutes</span></div>
            </div>
        </div>
        }
        {!isMobile && isEditing && 
        <div id="TimesheetShift"> 
            <div className="TimesheetShiftEdit" data-testid="timesheetShift-edit">
                <div className="shift-item"><label htmlFor="shift-date">Date</label><br/><input id="shift-date" type='text' className="item-value" value={shiftDate}></input></div>
                <div className="shift-item"><label htmlFor="shift-type">Shift Type</label><br/>
                    <select id="shift-type" className="item-value" value={shiftTypeId}>
                        {shiftTypes.map((t: TimesheetShiftType) => { return <option value={t.shiftTypeId}>{t.name}</option> })}
                    </select></div>
                <div className="shift-item"><label htmlFor="shift-start">Shift Start Time</label><br/><input id="shift-start" type='text' className="item-value" value={shiftStart}></input></div>
                <div className="shift-item"><label htmlFor="shift-end">Shift End Time</label><br/><input id="shift-end" type='text' className="item-value" value={shiftEnd}></input></div>
                <div className="shift-item"><label htmlFor="shift-lunch">Lunch</label><br/><input id="shift-lunch" type='text' className="item-value" value={shiftLunch}></input></div>             
            </div>
        </div>
        } 
        {isMobile && isEditing && 
        <div id="TimesheetShift"> 
            <div className="TimesheetShiftEdit" data-testid="timesheetShift-edit"> 
                <div className="edit-shift-item"><label htmlFor="shift-date">Date* </label><input id="shift-date" type='text' className="item-value" value={shiftDate}></input></div>
                <div className="edit-shift-item"><label htmlFor="shift-type">Shift Type* </label>
                    <select id="edit-shift-item" className="item-value" value={shiftTypeId}>
                        {shiftTypes.map((t: TimesheetShiftType) => { return <option value={t.shiftTypeId}>{t.name}</option> })}
                    </select></div>
                <div className="edit-shift-item"><label htmlFor="shift-start">Start Time* </label><input id="shift-start" type='text' className="item-value" value={shiftStart}></input></div>
                <div className="edit-shift-item"><label htmlFor="shift-end">End Time* </label><input id="shift-end" type='text' className="item-value" value={shiftEnd}></input></div>
                <div className="edit-shift-item"><label htmlFor="shift-lunch">Lunch* </label><input id="shift-lunch" type='text' className="item-value" value={shiftLunch}></input></div>             
            </div>
        </div>
        }
        </>
    )
}