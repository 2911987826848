import greenCheckmark from  "../assets/referral_green_checkmark.svg";
import './UpdateBackgroundQuestionsSubmitModal.scss';

export default function UpdateBackgroundQuestionsSubmitModal() {
    const isMobile = window.matchMedia("(max-width: 1249px)").matches;

    return(
        <div data-testid="update-background-questions-confirmation-modal" id="update-background-questions-submit-modal">
            <div >
                <div className="confirmation-modal" data-testid="update-submit-confirmation">
                    {isMobile &&
                        <div className="success-banner">
                            Success!
                        </div>}
                    <img src={greenCheckmark} alt="Success"></img>
                    <span>Background Questions Updated</span>
                </div>
            </div>
        </div>
    )
}