import { useEffect, useState } from "react";
import { ElectronicMedicalRecordsSystem, EmploymentHistoryFormData, EmploymentHistoryOptionsResult, Specialty } from "../tp-core-types/EmploymentHistory"
import DateService from "../utilities/dateService";
import { MonthOption } from "../utilities/monthOption";
import SpecialtyMultiSelectWrapper from "../multi-select-wrapper/SpecialtyMultiSelectWrapper";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import "./EmploymentHistoryForm.scss";

export type Props = {
    options: EmploymentHistoryOptionsResult
    historyFormData: EmploymentHistoryFormData | null
    recordTitle: string
    onChange: (updatedValues: EmploymentHistoryFormData) => void
}

export default function EmploymentHistoryForm(props: Props){
    const [staffingAgencyIsVisible, setStaffingAgencyIsVisible] = useState<boolean>(false);
    const [recordTitle, setRecordTitle] = useState<string>(props.recordTitle);
    const [facilityCity, setFacilityCity] = useState<string | null>(null);
    const [facilityName, setFacilityName] = useState<string | null>(null);
    const [facilityStateId, setFacilityStateId] = useState<number | null>(null);
    const [monthOptions, setMonthOptions] = useState<MonthOption[]>();
    const [startMonth, setStartMonth] = useState<number | null>(null);
    const [endMonth, setEndMonth] = useState<number | null>(null);
    const [startYear, setStartYear] = useState<number | null>(null);
    const [endYear, setEndYear] = useState<number | null>(null);
    const [yearOptions, setYearOptions] = useState<number[]>([]);
    const [iStillWorkHere, setIStillWorkHere] = useState<boolean>(false);
    const [profession, setProfession] = useState<number>(13);
    const [selectedEmrs, setSelectedEmrs] = useState<ElectronicMedicalRecordsSystem[]>([]);
    const [specialtiesForProfession, setSpecialtiesForProfession] = useState<Specialty[]>([]);
    const [answerIsFloat, setAnswerIsFloat] = useState<boolean | null>(null);
    const [employmentTypeId, setEmploymentTypeId] = useState<number | null>(null);
    const [staffingAgency, setStaffingAgency] = useState<string | null>(null);
    const [unitsFloated, setUnitsFloated] = useState<string | null>(null);
    const [isChargeNurse, setIsChargeNurse] = useState<boolean>(false);
    const [shiftsPerMonth, setShiftsPerMonth] = useState<string | null>(null);
    const [timeOffReason, setTimeOffReason] = useState<string>("");
    const [employeer, setEmployeer] = useState<string | null>(null);
    
    const [isSpecialtySectionVisible, setIsSpecialtySectionVisible] = useState<boolean>(true);
    const [selectedSpecialties, setSelectedSpecialties] = useState<Specialty[]>([]);

    useEffect(() => {
        const updatedEmploymentHistoryFormData:EmploymentHistoryFormData = {
            employmentHistoryId: props.historyFormData?.employmentHistoryId ?? null,
            employmentTypeId: employmentTypeId,
            facilityName: employmentTypeId === 4 ? employeer : employmentTypeId === 5 ? timeOffReason : facilityName,
            city: facilityCity,
            stateId: facilityStateId,
            startMonth: startMonth,
            startYear: startYear,
            endMonth: endMonth,
            endYear: endYear,
            iStillWorkHere: iStillWorkHere,
            professionId: profession,
            agencyName: staffingAgency,
            isCharge: isChargeNurse,
            answerIsFloat: answerIsFloat,
            unitsFloated: unitsFloated,
            shiftPerMonth: updateShiftsPerMonth(),
            specialties: selectedSpecialties.map(s => parseInt(s.id)),
            emrs: selectedEmrs.map(e => parseInt(e.id)),
        }
        props.onChange(updatedEmploymentHistoryFormData);
    }, [
        startMonth, 
        startYear,
        endMonth, 
        endYear, 
        iStillWorkHere, 
        facilityStateId, 
        profession, 
        employmentTypeId, 
        staffingAgency, 
        facilityName, 
        facilityCity,
        answerIsFloat,
        unitsFloated, 
        isChargeNurse, 
        selectedSpecialties, 
        shiftsPerMonth,
        selectedEmrs,
        employmentTypeId,
        timeOffReason,
        employeer
    ]);

    useEffect(() => {   
        setMonthOptions(DateService.getMonthList());
        setYearOptions(DateService.dropdownYearList(40));    
    }, []);

    useEffect(() => {
        const newSpecialtiesForProfession = props.options?.professions.find((p) => p.professionId === profession)?.specialties ?? []
        setSpecialtiesForProfession(newSpecialtiesForProfession);
        setIsSpecialtySectionVisible(newSpecialtiesForProfession.length !== 0)
        if(newSpecialtiesForProfession.length === 0)
        {
                setAnswerIsFloat(null);
                setUnitsFloated(null);
                setIsChargeNurse(false);
        }
        const newSelectedSpecialties = selectedSpecialties.filter((ss) => newSpecialtiesForProfession.findIndex((so) => so.id.toString() === ss.id.toString()) !== -1)
        setSelectedSpecialties(newSelectedSpecialties)
    } ,[profession, props.options]);

    const updateShiftsPerMonth = () => {
        if(employmentTypeId === 4 || employmentTypeId === 5 || employmentTypeId == null) { 
            return null;
        }
        
        return shiftsPerMonth;
    }

    const convertSelectedEmrs = (): ElectronicMedicalRecordsSystem[] | undefined => {
        const propsSelectedEmrs = props.options.electronicMedicalRecordsSystems.filter((e : ElectronicMedicalRecordsSystem) => {
            return props.historyFormData?.emrs.includes(parseInt(e.id))
        })
        return propsSelectedEmrs.length > 0 ? propsSelectedEmrs : selectedEmrs;
        
    }

    const convertSelectedSpecilties = (): Specialty[] => {
        const specialtiesForProfessionInProps = props.options?.professions.find((p) => p.professionId === profession)?.specialties ?? []
        const propsSelectedSpecialties = specialtiesForProfessionInProps.filter((e : Specialty) => {
            return props.historyFormData?.specialties.includes(Number(e.id))
        });

        return propsSelectedSpecialties.length > 0 ? propsSelectedSpecialties : selectedSpecialties
    }

    const validateShiftsPerMonthInput = (shiftsPerMonthText: string) => {
        const regex = new RegExp(/^\d*$/);
        if(regex.test(shiftsPerMonthText) && shiftsPerMonthText.length <= 100){
            setShiftsPerMonth(shiftsPerMonthText);
        }
        
    }

    useEffect(() => {
        setEmploymentTypeId(props.historyFormData?.employmentTypeId ?? null);
        setStaffingAgency(props.historyFormData?.agencyName ?? null);
        setFacilityCity(props.historyFormData?.city ?? null);
        setSelectedEmrs(convertSelectedEmrs() ?? []);
        setSelectedSpecialties(convertSelectedSpecilties() ?? null);
        setAnswerIsFloat(props.historyFormData?.employmentHistoryId === null ? null : props.historyFormData?.answerIsFloat ?? null);
        setEndMonth(props.historyFormData?.endMonth ?? null);
        setEndYear(props.historyFormData?.endYear ?? null);
        setFacilityName(props.historyFormData?.facilityName ?? null);
        setIsChargeNurse(props.historyFormData?.isCharge ?? false);
        setUnitsFloated(props.historyFormData?.unitsFloated ?? null);
        setProfession(props.historyFormData?.employmentHistoryId === null ? 13 : props.historyFormData?.professionId ?? 13);
        setIStillWorkHere(props.historyFormData?.employmentHistoryId === null ? false : props.historyFormData?.iStillWorkHere ?? false);
        setStartMonth(props.historyFormData?.startMonth ?? null);
        setStartYear(props.historyFormData?.startYear ?? null);
        setFacilityStateId(props.historyFormData?.stateId ?? null);
        setShiftsPerMonth(props.historyFormData?.shiftPerMonth ?? null);
        setEmployeer(props.historyFormData?.employmentTypeId === 4 ? props.historyFormData?.facilityName : null);
        setTimeOffReason(props.historyFormData?.employmentTypeId === 5 ? props.historyFormData?.facilityName ?? "" : "");
    }, []);

    useEffect(() => {
        const showAgency = props.options.employmentTypes.find((e) => e.employmentHistoryTypeId === employmentTypeId)?.showAgency;
        if(showAgency != null)
        {
            setStaffingAgencyIsVisible(showAgency);
        }
    }, [employmentTypeId]);

   useEffect(() => {
        if(facilityName || facilityCity || facilityStateId || timeOffReason || employeer)
        {
            const stateName = props.options?.states.find(t => t.stateId === facilityStateId)?.name;
            const fName = facilityName == null ? "" : facilityName;
            const fCity = facilityCity == null ? "" : facilityCity;
            if(employmentTypeId === 5){
                setRecordTitle("Personal Time Off - " + timeOffReason);
            } else if(employmentTypeId === 4){
                setRecordTitle("Non-Medical - " + employeer)
            }else{
                setRecordTitle(fName + " - " + fCity + ", " + (stateName == null ? "" : stateName));
            }
        }
        else {
            setRecordTitle(props.recordTitle);
        }
    }, [facilityCity, facilityName, facilityStateId, props.options?.states, props.recordTitle, timeOffReason, employeer, employmentTypeId]);
    
    const setStillWorkHereState = (checkedState: boolean) => {
        setIStillWorkHere(checkedState);
        setEndMonth(null);
        setEndYear(null);
    }

    useEffect(()=>{
        if(answerIsFloat !== null){
            if(document.getElementsByClassName("float-section")[0])
                document.getElementsByClassName("float-section")[0].scrollIntoView({block: 'center'});
        }
    }, [answerIsFloat])

    useEffect(() => {
        evaluateEmrHelperText();
    }, [selectedEmrs]);

    useEffect(() => {                                                                                                                                          
        evaluateSpecialtiesHelperText();
    }, [selectedSpecialties]);
    
    const checkElement = async (selector: any) => {
        while (document.querySelector(selector) === null) {
            await new Promise((resolve) => requestAnimationFrame(resolve));
        }
        return document.querySelector(selector);
    };

    const evaluateEmrHelperText = () => {
        const selectedEmrElement = document.getElementsByClassName("p-multiselect-label")[0];
        const selectedEmrContainerElement = document.getElementsByClassName("p-multiselect-label-container")[0];
        
        if (selectedEmrElement && selectedEmrContainerElement) {
            if (selectedEmrs.length > 0) {
                selectedEmrContainerElement.setAttribute("add-more-text", "Add more EMRs");
            } else {

                selectedEmrContainerElement.setAttribute("add-more-text", "");
            }

            if (selectedEmrs.length > 3) {
                selectedEmrElement.setAttribute(
                    "more-than-3-selections-text",
                    `+ ${selectedEmrs.length - 3} more`
                );
            } else {
                selectedEmrElement.setAttribute("more-than-3-selections-text", "");

            }
        }
    };

    const evaluateSpecialtiesHelperText = () => {
        const selectedSpecialtyElement = document.getElementsByClassName("p-multiselect-label")[1];
        const selectedSpecialtyContainerElement = document.getElementsByClassName("p-multiselect-label-container")[1];
        
        if (selectedSpecialtyElement && selectedSpecialtyContainerElement) {
            if (selectedSpecialties.length > 0) {
                selectedSpecialtyContainerElement.setAttribute("add-more-text", "Add more Specialties");
            } else {
                selectedSpecialtyContainerElement.setAttribute("add-more-text", "");
            }

            if (selectedSpecialties.length > 3) {
                selectedSpecialtyElement.setAttribute(
                    "more-than-3-selections-text",
                    `+ ${selectedSpecialties.length - 3} more`
                );
            } else {
                selectedSpecialtyElement.setAttribute("more-than-3-selections-text", "");

            }
        }
    }

    const onShowMultiSelect = () => {
        if (window.matchMedia("(max-width: 1250px)").matches) {
            checkElement(".p-multiselect-panel").then((selector) => {
                const panel = selector as HTMLElement;
                panel.style.top = "4rem";
                document.body.style.overflow = "hidden"; //prevent scroll on entire page including this modal
            });
        }
    };

    const onHideMultiSelect = () => {
        if (window.matchMedia("(max-width: 1250px)").matches) {
            document.body.style.overflow = "auto"; //restoring scroll behavior for entire page
        }
    };

    const handleSpecialtiesChange = (event: MultiSelectChangeEvent) => {
        setSelectedSpecialties(event.value);
    }

    const handleSpecialtiesRemove = (idToRemove: string): void => {
        const updatedSelectedSpecialties =  selectedSpecialties.filter((selectedSpecialty: Specialty) => selectedSpecialty.id.toString() !== idToRemove)
        setSelectedSpecialties(updatedSelectedSpecialties);
    }
    const handleEmrsRemove = (idToRemove: string): void => {
        const updatedSelectedEmrs =  selectedEmrs.filter((selectedEmrs: ElectronicMedicalRecordsSystem) => selectedEmrs.id.toString() !== idToRemove)
        setSelectedEmrs(updatedSelectedEmrs);
    }

return (<>
        <div id="employment-history-form" className="form-container">
            <div className="record-title" data-testid="record-title" id="record-title">{recordTitle}</div>
            <div className="record-container">
                <div className="form-row">
                    <div className="form-element employment-type-container">
                        <label htmlFor="employment-type-select">Employment Type*</label>
                        <select className="select-box employment-history-select" id="employment-type-select" 
                            onChange={(e) => {                            
                                setEmploymentTypeId(Number(e.target.value));
                                const showAgency: boolean = props.options?.employmentTypes.find(t => t.employmentHistoryTypeId.toString() === e.target.value)?.showAgency || false;
                                setStaffingAgencyIsVisible(showAgency)
                            }}
                            value={employmentTypeId ?? ""}
                        >
                            <option value={""}></option>
                            {props.options?.employmentTypes?.map((x) => (
                                <option key={x.employmentHistoryTypeId} value={x.employmentHistoryTypeId}>{x.name}</option>
                            ))}
                        </select>
                    </div>
                    {staffingAgencyIsVisible && 
                    <div className="form-element staffing-agency-container">
                        <label htmlFor="employment-staffing-name-input">Staffing Agency*</label>
                        <input className="input-box-staffing" id="employment-staffing-name-input" type="text"
                            onChange={(e) => setStaffingAgency(e.target.value)}
                            value={staffingAgency ?? ""}
                            />
                    </div>
                    }
                    {employmentTypeId !== 4 && employmentTypeId !== 5 &&
                    <div className="form-element facility-name-container">
                        <label htmlFor="employment-facility-name-input">Facility Name*</label>
                        <input className="input-box" id="employment-facility-name-input" type="text" 
                        onChange={(e) => setFacilityName(e.target.value)}
                        value={facilityName ?? ""}
                        />
                    </div>
                    }
                    {employmentTypeId === 5 && 
                    <div className="form-element facility-name-container">
                        <label htmlFor="employment-facility-name-input">Reason for time off</label>
                        <input className="input-box" id="employment-facility-name-input" type="text" 
                        onChange={(e) => setTimeOffReason(e.target.value)}
                        value={timeOffReason ?? ""}
                        />
                    </div>
                    }
                    {employmentTypeId === 4 && 
                    <div className="form-element facility-name-container">
                        <label htmlFor="employment-facility-name-input">Employeer*</label>
                        <input className="input-box" id="employment-facility-name-input" type="text" 
                        onChange={(e) => setEmployeer(e.target.value)}
                        value={employeer ?? ""}
                        />
                    </div>
                    }
                </div>
                <div className="form-row">
                    {employmentTypeId !== 4 && employmentTypeId !== 5 &&
                    <div className="form-element facility-location-container">
                        <label htmlFor="employment-facility-location-input">Facility Location*</label>
                        <div className="facility-location-inputs">
                            <div className="city-input">
                                <input className="city-input-box" placeholder="City" id="employment-facility-location-input" type="text" 
                                    onChange={(e) => setFacilityCity(e.target.value)} 
                                    value={facilityCity ?? ""}/>
                            </div>
                            <div className="state-dropdown">
                                <select className="state-select-dropdown" id="employment-facility-state-input" 
                                    data-testid="facilityState" placeholder="State" 
                                    onChange={(e) => {setFacilityStateId(Number(e.target.value))}} 
                                    value={facilityStateId ?? 0}
                                >
                                    <option value={""}>State</option>
                                    {props.options?.states?.map((x) => (
                                        <option key={x.stateId} value={x.stateId}>{x.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>                   
                    </div>
                    }
                    <div className="form-element date-container">
                        <div className="form-label">Start*</div>
                        <div className="date-select-container">
                            <div className="month-select-container">
                                <select className="select-box month-select" 
                                    id="employment-start-month" 
                                    data-testid="startDateMonth" 
                                    onChange={(e) => { setStartMonth(Number(e.target.value)) }} 
                                    value={startMonth ?? ""}
                                >                   
                                    <option value={""}>Month</option>
                                    { monthOptions?.map((month) => ( 
                                        <option key={month.number} value={month.number}>{month.name}</option> 
                                    ))}
                                </select>
                            </div>
                            <div className="year-select-container">
                                <select className="select-box year-select" id="employment-start-year" data-testid="startDateYear" onChange={(e) => setStartYear(Number(e.target.value))} value={startYear ?? ""}>
                                    <option value={""}>Year</option>
                                    { yearOptions?.map((year) => (
                                        <option key={year} value={year}>{year}</option> 
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-element date-container">
                        <div className="end-date-label-container">
                            <div className="form-label">End*</div>
                            <div className="still-work-here-checkbox-container">
                                <input className="application-checkbox" id="employment-still-work-here" type="checkbox" 
                                    onChange={(e) => setStillWorkHereState(e.target.checked)}
                                    checked={iStillWorkHere ?? false}
                                    />
                                { employmentTypeId === 5 ?    
                                <label className='checkbox-label' htmlFor="employment-still-work-here">I'm still taking time off</label>
                                : 
                                <label className='checkbox-label' data-testid="still-work" htmlFor="employment-still-work-here">I still work here</label>
                                }
                            </div>                        
                            
                        </div>
                    
                        {!iStillWorkHere && 
                        <div className="date-select-container">
                            <div className="month-select-container">
                                <select className="select-box month-select" id="employment-end-month" data-testid="endDateMonth" onChange={(e) => {setEndMonth(Number(e.target.value));}} value={endMonth ?? ""}>
                                    <option value={""}>Month</option>   
                                    { monthOptions?.map((month) => ( <option key={month.number} value={month.number}>{month.name}</option> )) }
                                </select>
                            </div>
                            <div className="year-select-container">
                                <select className="select-box year-select" id="employment-end-year" data-testid="endDateYear" onChange={(e) => setEndYear(Number(e.target.value))} value={endYear ?? ""}>
                                    <option value={""}>Year</option>
                                    { yearOptions?.map((year) => (<option key={year} value={year}>{year}</option> )) }                                    
                                </select>
                            </div>                            
                        </div>
                        }
                    </div>
                </div>
                {employmentTypeId !== 4 && employmentTypeId !== 5 &&
                <div className="form-row profession-emr-row">
                    <div className="form-element profession-container">
                        <label htmlFor="employment-profession">Profession*</label>
                        <select className="select-box profession-select" data-testid="profession" id="employment-profession" 
                            value={profession} 
                            onChange={(e) => setProfession(Number(e.target.value))}
                        >
                                {props.options?.professions?.map((x) => (
                                    <option key={x.professionId} value={x.professionId}>{x.name}</option>
                                ))}
                        </select>
                    </div>
                    {props.options?.electronicMedicalRecordsSystems && 
                    <div className="specialty-select form-element">
                        <label htmlFor="emrIdMultiSelect" className="emr-label">EMR*</label>
                        <SpecialtyMultiSelectWrapper 
                            id="emrIdMultiSelect"
                            name={""}
                            onShow={onShowMultiSelect} 
                            onHide={onHideMultiSelect} 
                            onChange={(event) => { setSelectedEmrs(event.value)}}
                            onRemove={handleEmrsRemove} 
                            options={props.options?.electronicMedicalRecordsSystems}
                            optionLabel={"name"}
                            filterPlaceholder={"Search for EMR"} 
                            value={convertSelectedEmrs() ?? selectedEmrs}                                 
                                                            
                        />

                        <input
                            id="emrId"
                            type="hidden"
                            name="emrIds"
                            value={selectedEmrs.map((emr: ElectronicMedicalRecordsSystem) => emr.id)}
                        ></input>
                    </div> }
                    {employmentTypeId === 3 &&
                        <div className="form-element shifts-per-month-container">
                            <label htmlFor="shifts-per-month-input">Shifts per Month</label>
                            <input className="input-box" id="shifts-per-month-input" type="text" 
                            onChange={(e) => validateShiftsPerMonthInput(e.target.value)}
                            value={shiftsPerMonth ?? ""}
                            />
                        </div>     
                    }
                </div>
                }
                { isSpecialtySectionVisible && employmentTypeId !== 4 && employmentTypeId !== 5 &&
                <div data-testid="specialties-section">
                    <div className="form-element">
                        <label className="label-border">Specialties you worked in this job*</label>
                    </div>
                    <div className="specialty-section">
                        <div className="specilty-charge-section">
                            {props.options?.professions && 
                            <div data-testid="specialtyIdMultiSelect">
                                <label className="specialty-label">Specialty*</label>
                                <SpecialtyMultiSelectWrapper 
                                    id={"specialtyIdMultiSelect"} 
                                    name={""} 
                                    value={convertSelectedSpecilties() ?? selectedSpecialties}                                                     
                                    onShow={onShowMultiSelect}
                                    onHide={onHideMultiSelect} 
                                    onChange={handleSpecialtiesChange}
                                    onRemove={handleSpecialtiesRemove} 
                                    options={specialtiesForProfession} 
                                    optionLabel={"name"} 
                                    filterPlaceholder={"Search for Specialties"} />
                            </div> 
                            } 
                            <div className="charge-nurse-checkbox-container">
                                <input className="charge-nurse-checkbox application-checkbox" 
                                    checked={isChargeNurse} 
                                    onChange={(e) => setIsChargeNurse(e.target.checked)} 
                                    id="isChargeNurse" 
                                    type="checkbox" />
                                <label className="checkbox-label" htmlFor="isChargeNurse">I was a Charge Nurse</label>
                            </div>

                        </div>

                        <div className="float-section">
                            <div className="form-element specialty-float-container">
                                <div className="specialty-float-label-container">
                                    <div className="form-element float-label">
                                        <label>Did you float to any other specialties in this role?*</label>
                                    </div>

                                    <div className="selections">
                                        <div className="yes-no-button-container">
                                            <div id="yes-button" 
                                                className={answerIsFloat ? "selected selection-item yes-border" : "unselected selection-item yes-border"} 
                                                onClick={(e) => setAnswerIsFloat(true) } 
                                                data-testid="specialty-float-yes">Yes</div>
                                            <div id="no-button" 
                                                className={answerIsFloat != null && !answerIsFloat ? "selected selection-item no-border" : "unselected selection-item no-border"} 
                                                onClick={(e) => setAnswerIsFloat(false) } 
                                                data-testid="specialty-float-no">No</div>
                                        </div>
                                    </div>

                                    <div className="form-element" 
                                        hidden={!answerIsFloat}>
                                        <label className="specialties-input-label" >List your float specialties along with the frequency*</label>                            
                                        <textarea maxLength={50} id="textarea-floats" 
                                            onChange={(e) => setUnitsFloated(e.target.value)} 
                                            data-testid="float-specialties-textbox" className="specialties-input" value={unitsFloated ?? ""}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                }
            </div>
        </div>
    </>)
}