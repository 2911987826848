import { Profession } from "../tp-core-types/JobSearchOptionsModel"

type Props = {
    professions: Profession[],
    onProfessionChange: (profession: Profession) => void
    selectedProfessionId: string
    isRequired: boolean
}

export default function SelectProfessionDropdown(props: Props) {
    

    const handleProfessionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const matchingProfessions = props.professions.filter(p => p.id.toString() === event.target.value)
        if (matchingProfessions.length > 0) {
            props.onProfessionChange(matchingProfessions[0])
        }
    };

    const getProfessionLabel = () => {
        return props.isRequired ? "Profession*" : "Profession"
    }

    return <>
    <label htmlFor="professionId" className="label">{getProfessionLabel()}</label>
    {props.professions && <select
        id="professionId"
        data-testid="profession-select"
        name="professionId"
        className="professionSelect"
        onChange={handleProfessionChange}
        value={props.selectedProfessionId}
    >
        <optgroup label="Most Common">
            {props.professions
                .filter((item) => item.webOrder != null)
                .sort((a, b) => (a.webOrder ?? 0) - (b.webOrder ?? 0))
                .map((profession, index) => (
                    <option data-group="1" key={`1. ${profession.id}`} value={profession.id}>
                        {profession.name}
                    </option>
                ))}
        </optgroup>
        <optgroup label="All">
            {props.professions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((profession) => (
                    <option data-group="2" key={`2. ${profession.id}`} value={profession.id}>
                        {profession.name}
                    </option>
                ))}
        </optgroup>
    </select> }
    </>
}