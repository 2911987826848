export enum NavigationSteps {
    Started = "Started",
    Background = "Background",
    Address = "Address",
    License_Info = "License Info",
    Education = "Education",
    Employment = "Employment",
    References = "References",
    Certifications = "Certifications",
    Identification = "Identification",
    Skills_Checklist = "Skills Checklist",
    Skills_Checklist_Summary = "Skills Checklist Summary",
    EEOSelfIdentification = "Equal Employment Opportunity Self-Identification",
    TermsAndConditions = "Terms and Conditions" 
}