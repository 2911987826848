import { useEffect, useState } from "react";
import { HcpProfileData } from "../../tp-core-types/HcpProfileData";
import DateService from "../../utilities/dateService";
import ProfileGrid from "../ProfileGrid/ProfileGrid";
import ProfileGridMobile from "../ProfileGrid/ProfileGridMobile";
import ReferenceModal from "./ReferenceModal";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { ProfileHcpReferenceSaveCommand } from "../../tp-core-types/ProfileHcpReferenceSaveCommand";
import { ReferenceFormCallbackPayload } from "../../tp-core-types/HcpReferences";
import { useError } from "../../utilities/context/useError";


type Props = {
    onChange: () => void;
    profileData: HcpProfileData
}

export default function ProfileReferences(props : Props){
    const [showNewReferenceModal, setShowNewReferenceModal] = useState<boolean>(false);
    const {setError} = useError()

    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    const openNewReferenceModal = () => {
        setShowNewReferenceModal(true);
    }

    const closeNewReferenceModal = () => {
        setShowNewReferenceModal(false);
    };

    const saveNewReference = async (formPayload: ReferenceFormCallbackPayload) => {
        const apiPayload: ProfileHcpReferenceSaveCommand = {
            reference: {
                firstName: formPayload.firstName,
                lastName: formPayload.lastName,
                title: formPayload.title,
                phoneNumber: formPayload.phoneNumber,
                historyId: parseInt(formPayload.historyId),
                email: formPayload.email,
                phoneType: formPayload.phoneType
            }
        };
       try {
            await TPCoreAPI.postProfileHcpReference(apiPayload);
            closeNewReferenceModal();
            props.onChange();
            return Promise.resolve(true);

       } catch {
            return Promise.resolve(false);
       }

    }
  
    
    return (
        <>
            { !isMobile && <ProfileGrid 
                button={ {label: "New Reference", callback: openNewReferenceModal} }
                noDataMessage="No references to display. Contact your recruiter for more information."
                containerHeader={"References"} 
                itemHeaders={["Work Experience", "Reference Name", "Job Title", "Phone", "Email Address"]} 
                items = {props.profileData.references.map((record) => {
                    const exp = props.profileData.employmentHistories.find(h => h.employmentHistoryId?.toString() === record.historyId?.toString()) ?? null;
                    
                    return { 
                        rows: [
                            exp && 
                            <div data-testid="facility-data">
                                <div data-testid="ref-facility">{record.facility}</div>
                                <div>- {record.facilityCity}, {record.facilityState}</div>
                                <div>
                                    <span>{`(${DateService.getMonthDayYearByDate(exp.startDate)?.month} ${DateService.getMonthDayYearByDate(exp.startDate)?.year} - `}</span>  
                                    <span>{ exp.endDate ? `${DateService.getMonthDayYearByDate(exp.endDate)?.month} ${DateService.getMonthDayYearByDate(exp.endDate)?.year})` : "Present)"}</span>
                                </div>
                            </div>,
                            record.firstName && record.lastName && <div>{record.firstName} {record.lastName}</div>,
                            record.title,
                            record.phoneNumber,
                            record.email
                        ]
                    }
                })
            }/>}
            {isMobile && 
                <ProfileGridMobile parentContainerHeader={"References"}
                button={ {label: "New Reference", callback: openNewReferenceModal} }
                noDataMessage="No references to display. Contact your recruiter for more information."
                containers={
                    props.profileData.references.map(record => {
                        const exp = props.profileData.employmentHistories.find(h => h.employmentHistoryId?.toString() === record.historyId?.toString()) ?? null;
                        return {
                            header: <>
                                        <div data-testid="ref-facility">{record.facility}</div>
                                        <div>{record.facilityCity}, {record.facilityState}</div>
                                    </>,
                            data: {
                                headers: ["Start/End", "Reference Name", "Job Title", "Phone", "Email Address"],
                                id: record.referenceId?.toString(),
                                items: [
                                    exp && 
                                        <div>
                                            {`${DateService.getMonthDayYearByDate(exp.startDate)?.month} ${DateService.getMonthDayYearByDate(exp.startDate)?.year } - ${exp.endDate ? `${DateService.getMonthDayYearByDate(exp.endDate)?.month} ${DateService.getMonthDayYearByDate(exp.endDate)?.year}` : "Present"}`}
                                        </div>,
                                        record.firstName && <div>{record.firstName} {record.lastName}</div>,
                                        record.title && <div>{record.title}</div>,
                                        record.phoneNumber && <div>{record.phoneNumber}</div>,
                                        record.email && <div>{record.email}</div>
                                ]
                            }
                        }
                    })
                } />
            }
            {showNewReferenceModal && <ReferenceModal cancelCallback={closeNewReferenceModal} saveCallback={saveNewReference}/>}
        </>)
    
}