import { useState } from "react";
import { DropboxUploadResult } from "../tp-core-types/DropboxUpload";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import "./CredentialsDropbox.scss"

type Props = {
    file: DropboxUploadResult | undefined;
    uploadedFileName: string;
    handleAddDocument: (label: string) => void;
    handleChangeFile: () => void;
    closeModal: () => void;
    attaching: boolean;

}

export default function DesktopUploadModal(props: Props) {
    const [newDocumentLabel, setNewDocumentLabel] = useState<string>("");
    const [ uploadDisabled, setUploadDisabled ] = useState<boolean>(true);

    return(
        <div className="modal-container-background">
                <div data-testid="upload-modal" className={`upload-modal${props.file === undefined || props.attaching ? "-loading" : ""}`}>
                    <div className="modal-header">
                        <div/>
                        <div>Name Your Document</div>
                        {!props.attaching ? <div className="modal-close-button" onClick={() => props.closeModal()} data-testid="close-upload-modal">X</div> : <div/> }
                    </div>
                        {props.file && !props.attaching
                        ? 
                            <div className="modal-content">
                                <>
                                    <div className="modal-document-preview">
                                        <div className="document-display">
                                            {
                                                props.file?.images 
                                                ? 
                                                    <>
                                                        {props.file.images.map((img) => <img className="doc-preview-item" src={img} alt="document-preview"/>)}
                                                    </>
                                                : 
                                                    <></>
                                            }
                                        </div>
                                        <div className="document-preview-filename">{props.uploadedFileName}</div>
                                    </div>
                                    <div className="modal-input-container">
                                        <div className="modal-inputs">
                                            <label htmlFor="input-docname">Document Label:</label>
                                            <input className="doc-label-input" id="input-docname" placeholder="Name your document" onChange={(e) => {setNewDocumentLabel(e.target.value); setUploadDisabled(e.target.value.length === 0) }} value={newDocumentLabel}/>
                                        </div>
                                        <div className="modal-button-container">
                                            <button data-testid="change-file-button" className="modal-change-button" onClick={props.handleChangeFile}>Choose Different File</button>
                                            <button data-testid="upload-file-button" className="modal-upload-button" disabled={ uploadDisabled } onClick={() => props.handleAddDocument(newDocumentLabel)}>Upload Document</button>
                                        </div>
                                    </div>
                                    </>
                            </div>
                        : <LoadingOverlay/>}
                </div>
            </div>  
    )
}