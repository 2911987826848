/* eslint-disable @typescript-eslint/no-explicit-any */

import "./ProfileGridMobile.scss"
import { ProfileNewButton } from "./ProfileNewButton";
import plusSignWhite from "../../assets/plus_sign_white.svg";
import React from "react";

type Props = {
    parentContainerHeader: string,
    containers: { header: any, data: {headers: string[], items: any[], id?: string | null}}[],
    noDataMessage?: string,
    button?: ProfileNewButton
}

export default function ProfileGridMobile(props: Props){
    if(props.noDataMessage?.length === 0) props.noDataMessage = `No ${props.parentContainerHeader} data available yet.`;
    return (<div id="ProfileGridMobile">
        <div className="parentContainer">
            <div className="parentContainerHeader">{props.parentContainerHeader}</div>
            {props.containers.length > 0 &&
            <>
                {props.containers.map((container, i) => {
                    return (
                        <div data-key={container.data.id} key={container.data.id} className="containerData" data-testid="container">
                            <div className="containerWrapper">
                                <div key={`${container.header}_${i}`} className="containerHeader" data-testid="containerHeader">{container.header}</div> 
                                <div className="containerItems">
                                {container.data.items.map((item, index) => { return(
                                    <React.Fragment key={`${i}_${index}`} >
                                        <div  className="itemHeader" data-testid="itemHeader">{container.data.headers[index]}</div>
                                        { item ? <div className="itemValue" data-testid="itemValue">{item}</div> : "-" }
                                    </React.Fragment>)
                                }) }
                                </div>
                            </div>
                        </div>
                    )
                })}   
            </>
            }

            {props.containers.length === 0 &&
                <div className="no-data">{props.noDataMessage}</div>
            }

        </div>    
       
        {props.button !== undefined && 
        <button className="header-button" onClick={props.button.callback}>
            <div className="header-button-content">
                <img src={plusSignWhite} alt=""/>
                <div className="header-button-label-text">{props.button.label}</div>
            </div>
        </button>}
    </div>)
}