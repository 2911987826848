import { ReactElement, useEffect, useState, cloneElement } from "react";
import "./SingleBackgroundQuestion.scss"
import { BackgroundQuestion } from "./BackgroundQuestion";
import { BackgroundAnswer } from "./BackgroundAnswer";

type Props = {
    backgroundQuestion: BackgroundQuestion;
    setQuestionAnswer: (answer: BackgroundAnswer) => void;
    currentState: BackgroundAnswer;
    isRequired: boolean;
    FollowUpQuestion: {component: ReactElement,
                       isVisible: boolean } | null
    isCaliforniaResident: boolean | null
}

export default function SingleBackgroundQuestion(props: Props)
{
    useEffect(() => {
        props.setQuestionAnswer({isAnswered: props.currentState.isAnswered, answer: props.currentState.answer})
    }, []);

    const [yesSelected, setYesSelected] = useState<boolean | null>(null);    

    function toggleSelection(isYes: boolean) {
        setYesSelected(isYes)
        props.setQuestionAnswer({isAnswered: true, answer: isYes});
    }

    return (
        <>
            { !props.isCaliforniaResident && 
                <div className="SingleBackgroundQuestion">
                    <div data-testid={"q" + props.backgroundQuestion.questionNumber} id={"question-" + props.backgroundQuestion.questionNumber} className="question-container">
                        <div className="main-question-container">
                            <div className="question"><span dangerouslySetInnerHTML={{__html: props.backgroundQuestion.questionText}}></span>{props.isRequired && <span>*</span>}</div>
                            <div className="selections">
                                <div className="yes-no-button-container">
                                    <div className={"selection-item yes-border " + (props.currentState.answer && props.currentState.isAnswered ? "yes-no-selected" : "unselected")} onClick={() => toggleSelection(true)}>Yes</div>
                                    <div className={"selection-item no-border " + (!props.currentState.answer && props.currentState.isAnswered ? "yes-no-selected" : "unselected")} onClick={() => toggleSelection(false)}>No</div>
                                </div>
                            </div>
                        </div>
                        <div className="follow-up-question-container">
                            {props.FollowUpQuestion && props.FollowUpQuestion.isVisible && props.FollowUpQuestion.component}                                
                        </div>
                    </div>
                </div>
            }
        </>
        
    )
}