import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "./SelectButtonWrapper.scss";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";

type Props = {
    id: string;
    name: string;
    value: number;
    onChange?: (e: SelectButtonChangeEvent) => void;
    options: object[];
    optionLabel: string;
    className: string;
};

export default function SelectButtonWrapper(props: Props) {
    const itemTemplate = (option: { icon: string; name1: string; name2: string }) => {
        return (
            <div>
                {option.icon && <img src={option.icon} alt={option.name1 + " " + option.name2}></img>}
                <span className="optionText">{option.name1}</span>
                {option.name2 && <span>{option.name2}</span>}
            </div>
        );
    };

    return (
        <div id="SelectButtonWrapper">
            <SelectButton
                className={props.className}
                value={props.value}
                onChange={props.onChange}
                itemTemplate={itemTemplate}
                optionLabel={props.optionLabel}
                options={props.options}
            />
        </div>
    );
}
