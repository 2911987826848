import TPCoreAPI from "../../tp-core-api/TPCoreAPI"
import { ElectronicMedicalRecordsSystem, EmploymentHistory, EmploymentHistoryFormData, EmploymentHistoryOptionsResult, ProfileEmploymentHistoryData } from "../../tp-core-types/EmploymentHistory"
import ProfileModal from "../ProfileModal"
import { useError } from "../../utilities/context/useError"
import EmploymentHistoryForm from "../../shared-forms/EmploymentHistoryForm"
import './ProfileEmploymentHistoryModal.scss';
import { useEffect, useState } from "react"

export type Props = {
    cancelCallback: () => void,
    saveCallback: (history: ProfileEmploymentHistoryData) => void,
    recordNumber: number
}

export default function ProfileEmploymentHistoryModal(props: Props){
    const {setError} = useError();
    const [historyOptions, setHistoryOptions] = useState<EmploymentHistoryOptionsResult>();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
    const [saveData, setSaveData] = useState<EmploymentHistoryFormData>({} as EmploymentHistoryFormData);

    useEffect(() => {
        TPCoreAPI.getEmploymentHistoryOptions()
            .then((options: EmploymentHistoryOptionsResult) => {
                sortEMRs(options.electronicMedicalRecordsSystems)
                setHistoryOptions(options);
            })
            .catch(()=>{
                setError(new Error("Something went wrong. Please try again."));
                return;
            });                
    }, []);

    const sortEMRs = (electronicMedicalRecordsSystems: ElectronicMedicalRecordsSystem[]) => {
        electronicMedicalRecordsSystems.sort((a, b) => {
            const isPopularSortValue = a.isPopular === b.isPopular ? 0 : (a.isPopular ? -1 : 1);
            const nameSortValue = a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            return isPopularSortValue || nameSortValue;
        });
    }
    const formDataUpdated = (updatedValues: EmploymentHistoryFormData) => {
        let buttonState = true;
        const isAgencyRequired = historyOptions?.employmentTypes.find(t => t.employmentHistoryTypeId === updatedValues.employmentTypeId)?.showAgency;
        const professionHasNoAssociatedSpecialties = historyOptions?.professions.find(p => p.professionId === updatedValues.professionId)?.specialties.length === 0
        const hasValidFloatSelection = (updatedValues.answerIsFloat && updatedValues.unitsFloated) || (updatedValues.answerIsFloat !== null && !updatedValues.answerIsFloat)
        if(
            updatedValues.employmentTypeId &&
            updatedValues.facilityName &&
            (updatedValues.agencyName || !isAgencyRequired) &&
            updatedValues.city &&
            updatedValues.stateId &&
            updatedValues.startMonth &&
            updatedValues.startYear &&
            ((updatedValues.endMonth && updatedValues.endYear) || updatedValues.iStillWorkHere) &&
            updatedValues.professionId &&
            updatedValues.emrs.length > 0 &&
            (professionHasNoAssociatedSpecialties || updatedValues.specialties.length > 0) && 
            (professionHasNoAssociatedSpecialties || hasValidFloatSelection)           

        ) {
            buttonState = false;
        }
        setSaveButtonDisabled(buttonState);
        setSaveData(updatedValues);
    }
    async function saveRecordtoDB(){
        const shouldSendStaffingAgency = historyOptions?.employmentTypes.find(t => t.employmentHistoryTypeId === saveData.employmentTypeId)?.showAgency;
        const professionHasAssociatedSpecialties: boolean = historyOptions?.professions.find(p => p.professionId === saveData.professionId)?.specialties.length !== 0

        const data: EmploymentHistory = {
            employmentHistoryId: null,
            employmentTypeId: saveData.employmentTypeId as number,
            facilityName: saveData.facilityName as string,
            city: saveData.city as string,
            stateId: saveData.stateId as number,
            startMonth: saveData.startMonth as number,
            startYear: saveData.startYear as number,
            endMonth: saveData.endMonth,
            endYear: saveData.endYear,
            professionId: saveData.professionId,
            agencyName: shouldSendStaffingAgency ? saveData.agencyName : null,
            isCharge: professionHasAssociatedSpecialties && saveData.isCharge,
            unitsFloated: ( professionHasAssociatedSpecialties && saveData.answerIsFloat ) ? saveData.unitsFloated : null,
            shiftPerMonth: saveData.shiftPerMonth,
            specialties: saveData.specialties,
            emrs: saveData.emrs,
            deleted: false,
            isVerified: false      
        }

        const postResponse = await TPCoreAPI.postProfileHcpEmploymentHistory(data);
        const profession = historyOptions?.professions.find(p => p.professionId === data.professionId);
        const specialtyStringArray =  profession?.specialties.filter(s => data.specialties.includes(parseInt(s.id))).map(s => s.name);
        

        let endDate = null;
        if (!saveData.iStillWorkHere && data.endMonth && data.endYear) {
            endDate = new Date(`${data.endMonth}/01/${data.endYear}`);
        }

        const newProfileData : ProfileEmploymentHistoryData = {
            employmentHistoryId: postResponse.employmentHistoryId,
            employmentType: historyOptions?.employmentTypes.find(et => et.employmentHistoryTypeId === data.employmentTypeId)?.name || "",
            facilityName: data.facilityName,
            city: data.city,
            state: historyOptions?.states.find(s => s.stateId === data.stateId)?.name || "",
            profession: profession?.name || "",
            agencyName: data.agencyName,
            isCharge: data.isCharge,
            unitsFloated: data.unitsFloated,
            specialties:specialtyStringArray || [],
            startDate: new Date(`${data.startMonth}/01/${data.startYear}`),
            endDate: endDate
        }
        
        
        props.saveCallback(newProfileData);
    }
    const emptyFormData: EmploymentHistoryFormData = {
        employmentHistoryId: null,
        employmentTypeId: null,
        facilityName: null,
        city: null,
        stateId: 0,
        startMonth: null,
        startYear: null,
        endMonth: null,
        endYear: null,
        iStillWorkHere: false,
        professionId: 13,
        agencyName: null,
        isCharge: false,
        answerIsFloat: null,
        unitsFloated: null,
        shiftPerMonth: null,
        specialties: [],
        emrs: []
    }
    return (
        <div data-testid="employment-history-modal">
            {historyOptions && <ProfileModal 
                cancelCallback={props.cancelCallback} 
                saveCallback={saveRecordtoDB}   
                saveButtonText={"Save Record"} 
                headerText={"Add New Employment Record"}             
                subHeaderText={null}
                disableSaveButton={saveButtonDisabled}
                
                >          
                <div className="profile-employment-history-modal-container">
                    <EmploymentHistoryForm 
                        options={historyOptions}
                        recordTitle={`Employment History ${props.recordNumber}`}
                        historyFormData={emptyFormData}                  
                        onChange={(updatedValues: EmploymentHistoryFormData) => formDataUpdated(updatedValues) } 
                    />            
                </div>
            </ProfileModal>}
        </div>
    )
}