import { JobSearchMatchType } from "../tp-core-types/JobSearchMatchType";
import { ResultMatchTypes } from "../tp-core-types/ResultMatchTypes";


export function getJobCardHeaderMatchType(matchTypes: ResultMatchTypes){
    const types = [matchTypes.shiftsPerWeek, matchTypes.specialty, matchTypes.location];
    if(types.includes(JobSearchMatchType.CloseMatch) ){
        return {className:"closeMatch", displayText: "Close Preference Match"};
    } else {
        return {className:"exactMatch", displayText: "Exact Preference Match!"};
    }
}

export function getJobCardBodyMatchType(matchType: JobSearchMatchType){
    switch (matchType){
        case JobSearchMatchType.ExactMatch:
            return {className:""};
        case JobSearchMatchType.CloseMatch:
            return {className:"closeMatch"};
        default:
            return {className:""};
    }
}