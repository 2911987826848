import { ReactElement, useLayoutEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

type Props = {
  address: string
};

export default function MapWrapper(props: Props) {
  const [mapRef, setMapRef] = useState<HTMLElement | null>();

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <></> 
    return <></>;
  };

  const getCoordinatesFromAddressAndReturnMap = async (mapDiv: HTMLElement, address: string) => {
    const geocoder = new window.google.maps.Geocoder();
    const result = await( geocoder.geocode({address: address}) )

    const options = {
        center: {
            lat: result.results[0].geometry.location.lat(), 
            lng: result.results[0].geometry.location.lng()
        }, 
        zoom: parseInt(process.env.REACT_APP_GOOGLE_MAPS_ZOOM_LEVEL!)
    }
    return new window.google.maps.Map(mapDiv, options);
  };

  useLayoutEffect(() => {
    if (mapRef) {
      getCoordinatesFromAddressAndReturnMap(mapRef, props.address);
    }
  }, [props.address, mapRef]);

  return (
      <Wrapper apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} render={render}>
        <div className="map" data-testid="map" ref={newRef => setMapRef(newRef)}></div>
      </Wrapper>
  );
}