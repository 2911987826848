import './SubmitStatement.scss';

export default function SubmitStatement() {

    function handleSubmitStatement(){
        window.open(
            "https://forms.office.com/pages/responsepage.aspx?id=LYnVKWMTF0KUZmScLyHJMKgeI09xnJVKlWwjL4vzGNBUOEFVTFZRMjFNUDhYMlVJM1o3RDkzSzUxTiQlQCN0PWcu",
            "_blank"
        )
    }

    return(
        <div id="SubmitStatement" data-testid="submit-statement" className="submit-statement-container">
            <div className="spacer-div"></div>
            <div className="submit-stament-sub-container">
                <div className="submit-statement-text">
                    <div>Please click the button below to tell us more.</div>
                </div>
                <div className="submit-statement-button-container">
                    <button className="submit-statement-button" onClick={handleSubmitStatement}>Submit Statement</button>
                </div>
            </div>
        </div>
    );
}