import { GetHcpDocumentsAndSignaturesResponse } from "../tp-core-types/GetHcpDocumentsAndSignaturesResponse";
import { Session } from "../utilities/Session";

const TPCoreAPIEDocumentsAndSignatures = {
    getHcpDocumentsAndSignatures: async (): Promise<GetHcpDocumentsAndSignaturesResponse> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/DocumentsAndSignatures/getDocumentsAndSignatures`, 
            options
        )

        return response.ok ? response.json() : Promise.reject({});
    },
}

export default TPCoreAPIEDocumentsAndSignatures;