import React, { useEffect, useState } from "react";
import "./DragNDrop.scss";

type Props = {
    onFilesSelected: (file: File | null) => void
}
export default function DragNDrop(props: Props) {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    event.target.value = "";
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFile = event.dataTransfer.files[0]
      setFile(newFile);
    }
  };

  useEffect(() => {
    props.onFilesSelected(file);
  }, [file]);

  return (
    <section className="drag-drop" >
      <div
        data-testid="drag-drop-container"
        className="document-uploader upload-box"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <div>
              <p>Drop new files here</p>
              <p>
                Supported files: pdf, doc, docx, jpg, jpeg, png, gif, bmp, jfif
              </p>
            </div>
          </div>
          <input
            type="file"
            hidden
            id="file-picker"
            data-testid="file-picker"
            onChange={(e) => handleFileChange(e)}
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp,.jfif"
          />
        </>
      </div>
    </section>
  );
}

