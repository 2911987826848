import { useContext, useEffect, useState } from "react";
import DesktopTRSHeader from "./DesktopTRSHeader";
import MobileTRSHeader from "./MobileTRSHeader";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { useNavigate } from "react-router-dom";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import { TRSHeaderContext } from "../utilities/context/TRSHeaderContextProvider";

export default function TRSHeader() {
  const navigate = useNavigate()
  const userInfoModel = useContext(UserInfoModel);
  const [isMobile, setIsMobile] = useState(false);
  const [showUserMenuModal, setShowUserMenuModal] = useState(false);
  const [applicationComplete, setApplicationComplete] = useState<boolean>(false);
  
  const getApplicationComplete = async () => {
    if (userInfoModel.token !== "") { 
        const user = await TPCoreAPI.getUserInfo();
        setApplicationComplete(user.applicationComplete || userInfoModel.userInfo.applicationComplete);
    }
  }

  const {isTRSHeaderVisible} = useContext(TRSHeaderContext)

  useEffect(() => {
      getApplicationComplete();
  }, [navigate, userInfoModel.token])

  useEffect(() => {
      const mobileMediaQuery = window.matchMedia("(max-width: 1250px)");
  
      const handleMobileChange = (e: {
        matches: boolean | ((prevState: boolean) => boolean);
      }) => {
        setIsMobile(e.matches);
      };
      mobileMediaQuery.addEventListener("change", handleMobileChange);
      setIsMobile(mobileMediaQuery.matches);
    }, [setIsMobile]);

  return (
        <>
          { isTRSHeaderVisible && isMobile &&
            <MobileTRSHeader applicationComplete={applicationComplete} setShowUserMenuModal={setShowUserMenuModal} showUserMenuModal={showUserMenuModal}/>
          }
          { !isMobile &&
            <DesktopTRSHeader applicationComplete={applicationComplete} setShowMenuModal={setShowUserMenuModal} showUserMenuModal={showUserMenuModal}/> 
          }
        </>
  )
}