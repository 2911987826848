export class BackgroundAnswers {
    public isUsCitizen: boolean | null = null;
    public isAuthorizedToWorkInUs: boolean | null = null;
    public hasMilitaryService: boolean | null = null;
    public militaryStartDate: Date | null = null;
    public militaryEndDate: Date | null = null;
    public isCaliforniaResident: boolean | null = null;
    public liabilityAction: boolean | null = null;
    public licenseSuspended: boolean | null = null;
    public canPerformTravelerTasks: boolean | null = null;
    public convicted: boolean | null = null;
    public hasHadMisconductActivity: boolean | null = null;
    public isBilingual: boolean | null = null;

    constructor(json: any) {
        for(const prop in json) {
            if (!json.hasOwnProperty(prop)) {
                continue;
            }

            if(prop === "militaryStartDate" || prop === "militaryEndDate") {
                this[prop]= json[prop] ? new Date(json[prop]) : null;
            }
            else {
                this[prop as keyof BackgroundAnswers] = json[prop] != null ? json[prop] : null;
            }
        }
    }
}
