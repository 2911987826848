import { useEffect, useRef, useState } from "react";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import plusSign from "../../assets/plus_sign.svg";

import { CertificationType } from "../../tp-core-types/CertifictionType";
import NavigationHeader from "../navigation-header/NavigationHeader";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import CertificationItem from "./CertificationItem";
import { Certification } from "../../tp-core-types/Certification";
import { CertificationsResult } from "../../tp-core-types/CertificationsResult";
import "./Certifications.scss";
import { useError } from "../../utilities/context/useError";

export default function Certifications() {
    const [certificationTypes, setCertificationTypes] = useState<CertificationType[]>([]);
    const [certifications, setCertifications] = useState<Certification[]>([]);
    const [newRecordDisabled, setNewRecordDisabled] = useState<boolean>(true);
    const {setError} = useError()
    const certificationLength = useRef<number>(0);

    async function getCertificationTypes() {
        setCertificationTypes((await TPCoreAPI.getCertificationTypes()).certificationTypes);
    }

    async function getCertifications() {
        const certificationsResult: CertificationsResult = await TPCoreAPI.getCertifications()
        const certificationsArray = certificationsResult.certifications

        if (certificationsArray && certificationsArray.length === 0) {
            setCertifications([{
                certId: undefined,
                certificationTypeId: 1,
                expirationDate: undefined,
                saved: false,
                createdOrder: 1,
                verified: false,
                deleted: false,
                certificationType: "",
                completionDate: undefined,
                issuedBy: ""
            },
            {
                certId: undefined,
                certificationTypeId: undefined,
                expirationDate: undefined,
                saved: false,
                createdOrder: 0,
                verified: false,
                deleted: false,
                certificationType: "",
                completionDate: undefined,
                issuedBy: ""
            } 
        ]);
        }

        else if (certificationsArray && certificationsArray.length === 1) {

            if (certificationsArray[0].createdOrder === 0) {
                setCertifications([
                    {
                        certId: undefined,
                        certificationTypeId: 1,
                        expirationDate: undefined,
                        saved: false,
                        createdOrder: 1,
                        verified: false,
                        deleted: false,
                        certificationType: "",
                        completionDate: undefined,
                        issuedBy: ""
                    },
                    certificationsArray[0]
                ]) 
            } else {
                setCertifications([
                    certificationsArray[0],
                    {
                        certId: undefined,
                        certificationTypeId: undefined,
                        expirationDate: undefined,
                        saved: false,
                        createdOrder: 0,
                        verified: false,
                        deleted: false,
                        certificationType: "",
                        completionDate: undefined,
                        issuedBy: ""
                    } 
                ]) 
            }
        }
        else {
            setCertifications(certificationsArray.sort((a, b) => b.createdOrder - a.createdOrder))
        }
    }   

    function isFormComplete() {
        if (certifications.find((c) => c.saved === true)) {
            return true
        }
        else {
            return false
        }
    } 

    useEffect(() => {
        getCertificationTypes();
        getCertifications();
    }, []);

    useEffect(() => {
        if (certifications.length !== 0){
            if (certifications.find((c) => c.saved === false)) {
                setNewRecordDisabled(true);
            } else {
                setNewRecordDisabled(false);
            }
        }
    }, [certifications])


    useEffect(() => { 
        if(certifications.length > certificationLength.current) {
            scrollToTop();
        }
        certificationLength.current = certifications.length;
    }, [certifications.length]);

    const scrollToTop = () => {        
        const helperText = document.getElementsByClassName("application-helper-text")[0];
        setTimeout(()=>helperText.scrollIntoView({block: 'center'}), 0);
    };

    async function onSaveRecord(certification: Certification, file: File | undefined): Promise<boolean> {
        try {
            await TPCoreAPI.saveCertification(certification, file);
            getCertifications();
            return new Promise((resolve, reject) => resolve(true))
        } catch {
            setError(new Error("It looks like something went wrong. Please double check your certification file and try again."))
        return new Promise((resolve, reject) => resolve(false))

        }
        return new Promise((resolve, reject) => resolve(false))
    }

    async function onDeleteRecord(certification: Certification): Promise<void>{
        certification = {...certification, deleted:true}
        if(certification.saved)
        {
            const result = await TPCoreAPI.saveCertification(certification, undefined);
            if (result.certId === certification.certId )
            {
                const newCertifications = [...certifications];
                const index : number = newCertifications.findIndex(c => c.createdOrder === certification.createdOrder && c.certId === certification.certId);
                newCertifications.splice(index, 1);
                setCertifications(newCertifications);
            }
        }
        else
        {
            const newCertifications = [...certifications];
            const index : number = newCertifications.findIndex(c => c.createdOrder === certification.createdOrder && c.certId === certification.certId);
            newCertifications.splice(index, 1);
            setCertifications(newCertifications);
        }
    }

    function setCertificationStepComplete(): Promise<void> {
        return TPCoreAPI.postWebApplicationStep({StepName:"Certifications", StepComplete: true})
    }

    function createNewRecord() {
        if (certifications) {
            const newCertifcationRowData = [...certifications];
            newCertifcationRowData.unshift({
                certId: undefined,
                certificationTypeId: undefined,
                expirationDate: undefined,
                createdOrder: Math.max(...certifications.map(c => c.createdOrder)) + 1,
                saved: false,
                verified: false,
                deleted: false,
                certificationType: "",
                completionDate: undefined,
                issuedBy: ""
            });
            setCertifications(newCertifcationRowData);
        }
    }

    function getCertificationTitle(certification: Certification, index: number) : string {
        if(certification.saved) {
            return certificationTypes.find((ct) => ct.certTypeId === certification.certificationTypeId)?.certName ?? `Certification`
        } else {
            const numberOfSavedCertifications = certifications.filter((c) => c.saved).length

            if (numberOfSavedCertifications === 0) {
                return `Certification ${index + 1}`
            } else {
                return `Certification ${numberOfSavedCertifications + 1}`
            }
        }
    }

    return (
        <div id="hcp-certifications">
            <NavigationHeader selectedStep={NavigationSteps.Certifications}  showNavigationBar={true}  />

            <div className="application-header">
                <PreviousNextButtonHeader 
                    selectedStep={NavigationSteps.Certifications}
                    isFormComplete={isFormComplete()}
                    callbackOnSave={setCertificationStepComplete}
                    />
                <div className="title">
                    Certifications
                    <button className="new-record-button" hidden={newRecordDisabled} disabled={newRecordDisabled} onClick={() => createNewRecord()}>
                        <div className="new-record-button-content">
                            <img src={plusSign} alt=""/>
                            <div>New Record</div>
                        </div>
                    </button>
                </div>
            </div>

            <div className="application-page-container">
                    <div className="application-helper-text">
                        Your chances of a successful submission increase if you provide all your certifications.
                        <br/><br/>
                        Acceptable file types: pdf, doc, docx, jpg, jpeg, png, gif, bmp, and jfif. Maximum file size: 30MB.
                    </div>
                <div className="certification-components">
                    {certifications && certificationTypes.length > 0 && certifications.map((c, i) => {
                        return (
                            <div key={c.certificationTypeId + "-" + i} className="certification-component">
                                <CertificationItem deleteRecordDisabled={certifications.length <= 1} certificationTypes={certificationTypes} onDeleteRecord={(cert) => onDeleteRecord(cert)} onSaveRecord={onSaveRecord} certification={c} certificationTitle={getCertificationTitle(c, i)}/>
                            </div>
                        )})
                    } 
                </div>
            </div>
        </div>
    ); 
}
