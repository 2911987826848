import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import { JobModel } from "../tp-core-types/JobModel";
import "./JobSearchFacilityDetails.css";
import scrubColorIcon from "../assets/scrub_color_icon.png";
import bedCountIcon from "../assets/bed_count_icon.png";
import chartingSystemIcon from "../assets/charting_system_icon.png";
import erIcon from "../assets/er_icon.png";
import locationIcon from "../assets/location_icon.png";
import MapWrapper from "../map/MapWrapper";
import displayZip from "../utilities/displayZip";
import { SyntheticEvent } from "react";
import isArchivedJob from "../utilities/isArchivedJob";

type Props = {
  jobDetails: JobDetailsModel;
};

export default function JobSearchFacilityDetails(props: Props) {
  function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
      event.currentTarget.src =
        "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
  }

  return (
    <div data-testid="facility-details-container">
      <div data-testid="header" className={isArchivedJob(props.jobDetails)? "header bold archived" : "header bold"}>
        Facility: {props.jobDetails.facility.facilityName}
      </div>
      <div className="facilityImageContainerMobile">
        <img
          className="facilityImageMobile"
          src={props.jobDetails.facility.imageUrl}
          onError={addDefaultSrc}
          alt="facility"
          data-testid="mobile-facility-image"
        />
      </div>
      <div data-testid="info" className="facilityInfo">
        <div data-testid="scrubColor" className="facilityInfoItem">
          <img
            className="facility-detail-icon"
            src={scrubColorIcon}
            alt=""
          ></img>
          Scrub Color: <span className="bold">{createScrubColorString()}</span>
        </div>
        <div data-testid="bedCount" className="facilityInfoItem">
          <img className="facility-detail-icon" src={bedCountIcon} alt=""></img>
          Bed Count: <span className="bold">{createBedCountString()}</span>
        </div>
        <div data-testid="charting" className="facilityInfoItem">
          <img
            className="facility-detail-icon"
            src={chartingSystemIcon}
            alt=""
          ></img>
          Charting System:{" "}
          <span className="bold">{createChartingString()}</span>
        </div>
        <div data-testid="trauma" className="facilityInfoItem">
          <img className="facility-detail-icon" src={erIcon} alt=""></img>
          Trauma Center:{" "}
          <span data-testid="traumalevel" className="bold">
            {createTraumaLevelString()}
          </span>
        </div>
      </div>
      <div className="facilityWrapper">
        <div data-testid="location" className="facilityInfo">
          <div data-testid="address" className="facilityInfoItem bold">
            <img
              className="facility-detail-icon"
              src={locationIcon}
              alt=""
            ></img>
            {createAddressString()}
          </div>
        </div>
        <div className="mapContainerBorder">
          <div className="mapContainer" data-testid="map-container">
            <MapWrapper address={createAddressString()} />
          </div>
        </div>
      </div>
    </div>
  );

  function createAddressString() {
    let addressString = "";

    if (props.jobDetails.facility.address1) {
      addressString += props.jobDetails.facility.address1;
    }

    if (props.jobDetails.facility.address2) {
      addressString += " " + props.jobDetails.facility.address2;
    }

    if (
      props.jobDetails.facility.address1 ||
      props.jobDetails.facility.address2
    ) {
      addressString += ", ";
    }

    addressString +=
      props.jobDetails.facility.city + ", " + props.jobDetails.facility.state;

    if (props.jobDetails.facility.zip) {
      addressString += " " + displayZip(props.jobDetails.facility.zip);
    }
    return addressString;
  }

  function createTraumaLevelString() {
    if (
      props.jobDetails.facility.traumaLevel &&
      props.jobDetails.facility.traumaLevel !== "null"
    ) {
      return "Level " + props.jobDetails.facility.traumaLevel;
    }
    return "Not Available";
  }

  function createBedCountString() {
    if (props.jobDetails.facility.bedCount) {
      if (props.jobDetails.facility.bedCount > 1) {
        return props.jobDetails.facility.bedCount + " Beds";
      } else {
        return props.jobDetails.facility.bedCount + " Bed"
      }
    }
    return "Not Available";
  }

  function createChartingString() {
    if ( props.jobDetails.facility.chartingSystems && props.jobDetails.facility.chartingSystems.length > 0) {
      return props.jobDetails.facility.chartingSystems.join(", ");
    }
    return "Not Available";
  }

  function createScrubColorString() {
    if (props.jobDetails.facility.scrubColor) {
      return props.jobDetails.facility.scrubColor
    }
    return "Not Available";
  }
}
