import "./LoadingOverlay.css";
import loading_spinner from "../assets/loading_spinner.gif";
type Props = {
    displayText?: string
}
export default function LoadingOverlay({displayText = "" }: Props) {
    return(
    <div className="loadingOverlayContainer" data-testid="loadingOverlayContainer">
            <div className="loadingOverlaySubContainer">
                {displayText}
            <img src={loading_spinner} alt="loading"/>
        </div>
    </div>)
}