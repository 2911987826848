import { ReactNode, useEffect, useState } from "react";
import xIcon from "../assets/x.png";
import "./ProfileModal.scss"


type Props = {
    cancelCallback: ()=> void;
    saveCallback: ()=> void;
    saveButtonClass?: string;
    cancelButtonClass?: string;
    saveButtonText: string;
    headerText: string;
    subHeaderText: string | null;
    disableSaveButton: boolean;
    children: ReactNode
}

export default function ProfileModal(props : Props){
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    const cancelClass = props.cancelButtonClass !== undefined  ? props.cancelButtonClass : "cancel-button";
    const saveClass = props.saveButtonClass !== undefined ? props.saveButtonClass : "save-button";

    const [isMobile, setIsMobile] = useState(getIsMobile());
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])
    
return (
    <div data-testid="profile-modal" id="modal-background">
        <div className="profile-modal-container" >
            <div data-testid="profile-header" className="profile-header">
                <div className="profile-title">{props.headerText}</div>
                {!isMobile && <div className="profile-title-buttons">
                    <button className={cancelClass} onClick={props.cancelCallback} disabled={false}>Cancel</button>
                    <button className={saveClass} disabled={props.disableSaveButton} onClick={props.saveCallback}>{props.saveButtonText}</button>
                </div>}
                {isMobile && <img
                        alt="Close Dialog"
                        className="profile-x-button"
                        src={xIcon}
                        onClick={props.cancelCallback}
                    />}
            </div>
            <div className="profile-content">
                <div className="modal-content">
                    {props.subHeaderText && <div className="modal-content-title" data-testid="profile-modal-sub-header">{props.subHeaderText}</div>}
                    {props.children}
                </div>
            </div>
                {isMobile &&  <div data-testid="profile-footer" className="profile-footer">
                <button className={cancelClass} onClick={props.cancelCallback}>Cancel</button>
                <button className={saveClass} disabled={props.disableSaveButton} onClick={props.saveCallback}>{props.saveButtonText}</button>
                </div>}
        </div>       
    </div>
    )
}