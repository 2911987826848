import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import isArchivedJob from "../utilities/isArchivedJob";
import "./JobSearchAboutTrsDetails.css";

type Props = {
    jobDetails: JobDetailsModel
};

export default function JobSearchAboutTrsDetails(props: Props) {
    return (
        <div data-testid="about-trs-info-container">
            <div data-testid="header" className={isArchivedJob(props.jobDetails)?"header bold archived":"header bold"}>About TRS Healthcare</div>
            <p className="aboutTrsText">We empower healthcare professionals to achieve their personal and professional goals.
            We're committed to supporting you through every step of your travel healthcare experience.
            Our team is dedicated to caring for you so you can do the important work of caring for others.
            At TRS Healthcare, our healthcare professionals come first.</p>
        </div>
    );
}