import { useContext, useEffect, useId, useState } from "react"
import "./SignUpForm.scss"
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { Session } from "../../utilities/Session";
import SelectProfessionDropdown from "../../select-profession-dropdown/SelectProfessionDropdown";
import { Profession } from "../../tp-core-types/JobSearchOptionsModel";
import SelectLeadSources from "../../select-leadsources/SelectLeadSources";
import UserInfoModel from "../../tp-core-types/UserInfoModel";
import { SignupFailure } from "../../tp-core-types/SignupFailure";
import ErrorMesage from "../../error-message/inlilne-error-message/ErrorMessage";
import { Validator } from "../../validator/Validator";
import { LeadSourcesModel } from "../../tp-core-types/LeadSourcesModel";

type Props = {
    setVisibility: (isVisible: boolean) => void
    successfulLogin: () => void;
}

export default function SignUpForm(props: Props){
    const userInfoModel = useContext(UserInfoModel) 
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profession, setProfession] = useState("13");
    const [referredBy, setReferredBy] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [dropDownProfessions, setDropdownProfessions] = useState<Profession[]>([]);
    const [showPasswordValidationError, setShowPasswordValidationError] = useState(false)

    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

    const [hasAgreedToPolicy, setHasAgreedToPolicy] = useState(false);
    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
    
    const [signupFailureMessage, setSignupFailureMessage] = useState("");
    const [leadSources, setLeadSources] = useState<LeadSourcesModel[]>([]);

    useEffect(() => {
        getProfessions();
        getLeadSources();
    }, [])

    useEffect(() => {
        const isValidForm = !!(email && phoneNumber && firstName && lastName && profession &&
                           hasAgreedToPolicy && password && passwordConfirmation && isEmailValid && isPhoneNumberValid && !showPasswordValidationError);

        setIsSubmitButtonEnabled(isValidForm);
    }, [email, phoneNumber, firstName, lastName, profession, referredBy, password, hasAgreedToPolicy, passwordConfirmation, showPasswordValidationError, isEmailValid, isPhoneNumberValid])
    useEffect(()=>{
        //
    },[leadSources])

    async function getLeadSources() {
        const sources =  await TPCoreAPI.getLeadSourceResults();
        setLeadSources(sources);
    }

    const createAccount = async () => {
        let referredById = referredBy;
        setIsSubmitButtonEnabled(false);
        if(!validatePassword()){
            return;
        }
        try {            
            if(referredById !=="28" && referredById !== "85"){
                const decodedUTMSource = decodeURI(Session.getUtmSource() ?? "No UTM source found");
                const returnReferralId = leadSources.find(l => l.webFriendlyName.toLowerCase() === decodedUTMSource?.toLowerCase())?.id || null;
                if(returnReferralId !== null)
                    referredById = returnReferralId.toString();
                else if (referredById === "")
                    referredById = "121"
            }

            const response = await TPCoreAPI.postHcpSignUp(email, phoneNumber, firstName, lastName,profession, referredById ?? "121", password);
            Session.setHcpLoginToken(response.token);
            userInfoModel.getAndSetToken();
        } catch(e) {
            setSignupFailureMessage((e as SignupFailure).message)
            setIsSubmitButtonEnabled(true);
            return;
        }
        try {
            const hubDataResponse = await TPCoreAPI.getUserHubData(email, password);
            Session.setHcpHubLoginData(hubDataResponse);
        } catch (e) {
        }
        props.successfulLogin();
        props.setVisibility(false);
    }

    const getProfessions = async () => {
        const professions = await TPCoreAPI.getProfessions();
       
        setDropdownProfessions(professions);
    }

    const validatePhoneNumber = (phoneNumber: string) => {
        setIsPhoneNumberValid(Validator.validatePhoneNumber(phoneNumber));
    }

    const validatePhoneNumberInput = (phoneNumberText: string) => {
        const regex = new RegExp(/^\d+$/);
        if(regex.test(phoneNumberText) || phoneNumberText === ""){
            setPhoneNumber(phoneNumberText);
        }
    }

    const validateEmail = (email: string) => {
        setEmail(email);
        setIsEmailValid(Validator.validateEmail(email))
    }


    const validatePassword = () => {
        const doPasswordsMatch = password === passwordConfirmation;
        setShowPasswordValidationError(!doPasswordsMatch)
        return doPasswordsMatch;
    }

    const handlePasswordFocusLost = () => {
        if(passwordConfirmation !== "") {
            validatePassword();
        }
    }

    const removeErrorMessage = () => {
        setSignupFailureMessage("");
        setIsEmailValid(true);
    }

    const emailInputId = useId();
    const phoneInputId = useId();
    const firstNameInputId = useId();
    const lastNameInputId = useId();
    const passwordId = useId();
    const confirmPasswordId = useId();
    const policyCheckboxId = useId();

    return(
        <div id="SignUpForm">
            <div className="signup-body-container" data-testid="signup-modal">
                <div className="signup-body">
                    <form className="signup-form-container" onSubmit={(e)=>e.preventDefault()}>
                        <div className="signup-modal-field">
                            <label htmlFor={emailInputId}>Email Address*</label>
                            <input id={emailInputId} type="email" inputMode="email" autoComplete="email" data-testid="signup-modal-email" onFocus={removeErrorMessage} onBlur={(e) => {validateEmail(e.target.value)}}></input>
                            { email && !isEmailValid &&
                                <ErrorMesage message="Please enter a valid email"></ErrorMesage>
                            }
                        </div>

                        <div className="signup-modal-field" data-testid="phone-number-field">
                            <label htmlFor={phoneInputId}>Phone Number*</label>
                            <input id={phoneInputId} name="phone" type="text" inputMode="numeric" maxLength={10} data-testid="signup-modal-phone-number" value={phoneNumber} onChange={(e) => {validatePhoneNumberInput(e.target.value)}} onBlur={(e) => {validatePhoneNumber(e.target.value)}}></input>
                            { !isPhoneNumberValid &&
                                <ErrorMesage message="Please enter a 10 digit number"></ErrorMesage>
                            }
                        </div>

                        <div className="signup-modal-field">
                            <label htmlFor={firstNameInputId}>First Name*</label>
                            <input id={firstNameInputId} type="text" data-testid="signup-modal-first-name"
                             autoComplete="given-name" onChange={(e) => {setFirstName(e.target.value)}}></input>
                        </div>

                        <div className="signup-modal-field">
                            <label htmlFor={lastNameInputId}>Last Name*</label>
                            <input id={lastNameInputId} type="text" data-testid="signup-modal-last-name" 
                            autoComplete="family-name" onChange={(e) => {setLastName(e.target.value)}}></input>
                        </div>

                        <div className="signup-modal-field">
                            <SelectProfessionDropdown isRequired={true} professions={dropDownProfessions} onProfessionChange={(profession: Profession)=>{setProfession(profession.id)}} selectedProfessionId={profession}></SelectProfessionDropdown>
                        </div>

                        <div className="signup-modal-field">
                            <SelectLeadSources leadSources={leadSources} onLeadsourceChange={(selectedLeadsource)=>{setReferredBy(selectedLeadsource.id.toString())}}></SelectLeadSources>
                        </div>

                        <div className="signup-modal-field">
                            <label htmlFor={passwordId}>Set Password*</label>
                            <input id={passwordId} className="signup-modal-password" type="password" 
                                data-testid="signup-modal-password" 
                                onChange={(e) => {setPassword(e.target.value)}}
                                onBlur={handlePasswordFocusLost}
                                maxLength={30}/>
                        </div>

                        <div className="signup-modal-field">
                            <label htmlFor={confirmPasswordId}>Confirm Password*</label>
                            <input 
                                id={confirmPasswordId} className="signup-modal-validate-password" 
                                type="password" data-testid="signup-modal-validate-password" 
                                onChange={(e) => {setPasswordConfirmation(e.target.value)}}
                                onBlur={validatePassword}
                                maxLength={30}
                            />
                            { showPasswordValidationError &&
                                <ErrorMesage message="Passwords must match"></ErrorMesage>
                            }
                        </div>
                    </form>

                    <label htmlFor={policyCheckboxId} className="policy-container">
                        <input id={policyCheckboxId} type="checkbox" data-testid="signup-modal-agree" onChange={(e) => {setHasAgreedToPolicy(e.target.checked)}}/>
                        <div className="policy-text">
                            I agree to receive emails, phone calls, and/or automated text messages from TRS Healthcare 
                            about employment opportunities, positions to which I've been submitted, and my 
                            employment with TRS Healthcare at the number(s) and email(s) provided. Please note that 
                            phone calls may be recorded for quality assurance. See the privacy policy for more details.            
                        </div>
                    </label>

                    <button className="login-modal-button" data-testid="signup-modal-submit" disabled={!isSubmitButtonEnabled} 
                            onClick={createAccount}>
                        Create Account
                    </button>
                    {(signupFailureMessage !== "") && 
                        <ErrorMesage message={signupFailureMessage}></ErrorMesage>
                    }   
                </div>
            </div>
        </div>
    )
}