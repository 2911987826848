import { ChangeEvent, SyntheticEvent, useEffect, useId, useState } from "react";
import { CertificationType } from "../../tp-core-types/CertifictionType";
import DatePickerWrapper from "../../date-picker-wrapper/DatePickerWrapper";
import { FormEvent } from "primereact/ts-helpers";
import { Certification } from "../../tp-core-types/Certification";
import AttachmentIcon from "./../../assets/attachment-icon.png"
import "./CertificationItem.scss"
import TrashCan from "../../assets/trash-can.svg";
import DisabledTrashCan from "../../assets/disabled-trash-can.svg"
import Verified from "../../assets/verified.png"

interface Props {
    certificationTypes: CertificationType[];
    onSaveRecord: (certification: Certification, file: File | undefined) => Promise<boolean>;
    onDeleteRecord: (certification: Certification) => void;
    certification: Certification;
    certificationTitle: string;
    deleteRecordDisabled: boolean;
}

export default function CertificationItem(props: Props) {
    const [certificationTypeId, setCertificationTypeId] = useState<number | undefined>(props.certification?.certificationTypeId);
    const [expirationDate, setExpirationDate] = useState<Date | undefined>();
    const [certificationTitle, setCertificationTitle] = useState<string>(props.certificationTitle);
    const [file, setFile] = useState<File | undefined>();
    const [canUploadCert, setCanUploadCert] = useState<boolean>(false);

    const certificationTypeSelectId = useId();
    const expirationDateId = useId();

    const [disableInputs, setDisableInputs] = useState<boolean>(false);
    useEffect(() => {
        const certificationType = props.certificationTypes.find((type) => type.certTypeId === certificationTypeId);
        setCanUploadCert(certificationType?.canAttachCert ?? false);
    }, [certificationTypeId, props.certificationTypes])

    function selectCertificationType(event: ChangeEvent<HTMLSelectElement>) {
        const typeId: number = parseInt(event.target.value)
        const certificationType = props.certificationTypes.find((type) => type.certTypeId === typeId);
        setCertificationTitle(certificationType?.certName ?? props.certificationTitle)
        setCertificationTypeId(typeId);
    }

    function selectExpirationDate(event: FormEvent<Date, SyntheticEvent<Element, Event>>) {
        setCertificationTitle(props.certificationTypes.find((type) => type.certTypeId === certificationTypeId)?.certName ?? props.certificationTitle)
        setExpirationDate(event.value ?? undefined);
    }

    function certificationHasExpiration() {
        return props.certificationTypes.find((type) => type.certTypeId === certificationTypeId)?.hasExpireDate ?? false;
    }

    async function saveRecord() {

        setDisableInputs(true);

        let wasRecordSaveSuccessfully;
        if (!certificationHasExpiration()) {
            wasRecordSaveSuccessfully = await props.onSaveRecord({
                certId: undefined, certificationTypeId: certificationTypeId, expirationDate: undefined, createdOrder: props.certification.createdOrder, saved: false, deleted: false, verified: false,
                certificationType: "",
                completionDate: undefined,
                issuedBy: ""
            }, file);
        } else {
            wasRecordSaveSuccessfully = await props.onSaveRecord({
                certId: undefined, certificationTypeId: certificationTypeId, expirationDate: expirationDate, createdOrder: props.certification.createdOrder, saved: false, deleted: false, verified: false,
                certificationType: "",
                completionDate: undefined,
                issuedBy: ""
            }, file);
        }
        if(wasRecordSaveSuccessfully === false) {
            setDisableInputs(false);
        }
    }

    function deleteRecord(){
        props.onDeleteRecord(props.certification);
    }

    function shouldSaveRecordButtonBeEnabled() {
        if (certificationTypeId) {
            if (!certificationHasExpiration()) {
                return true
            } else if (expirationDate) {
                return true
            }
        }
        return false
    }

    function fileUploadInputChange(event: any) {
        setFile(event.target.files[0])
        event.target.value = "";
    } 

    function onRemoveFile(){
        setFile(undefined);
    }

    const editableCertificationHTML = (
        <div className="certification-item">
            <h2>{certificationTitle}</h2>
            <div className="certification-item-form">
                <label htmlFor={certificationTypeSelectId}>Certification*</label>
                <select id={certificationTypeSelectId} className="certification-dropdown" onChange={selectCertificationType} disabled={disableInputs}>
                    <option disabled selected className="empty-dropdown-item"></option>
                    {props.certificationTypes.map((type) => {
                        if (certificationTypeId === type.certTypeId) {
                            return (<option selected key={type.certTypeId} value={type.certTypeId}>{type.certName}</option>)
                        } else {
                            return (<option key={type.certTypeId} value={type.certTypeId}>{type.certName}</option>)
                        }
                    })}
                </select>
                {certificationHasExpiration() && (
                    <>
                        <label htmlFor={expirationDateId}>Expiration Date*</label>
                        <DatePickerWrapper inputId={expirationDateId} value={expirationDate} onChange={selectExpirationDate} disabled={disableInputs}/>
                    </>
                )}
                
                { canUploadCert && 
                        <div className="upload-container">
                            <div className="upload-file-container">
                                {file && !disableInputs && 
                                    <div className="remove-file-button" data-testid="removeFileButton" onClick={onRemoveFile}>X</div>
                                }
                                <div className="file-name">{file?.name}</div>
                            </div>
                            <label className="file-picker-container">
                                <div className="upload-button">
                                    <img className="upload-icon" src={AttachmentIcon} alt="Upload Document"></img>
                                    <input className="file-picker" name="filePicker" type="file" data-testid="filePicker" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp,.jfif"
                                        onChange={fileUploadInputChange} disabled={disableInputs} />    
                                    Upload
                                </div>
                            </label>
                        </div>
                }

                
            </div>
            <div className="certification-buttons">
                <div className="delete-record-button-container">
                    <button data-testid="delete-button" className="delete-record-button" onClick={deleteRecord} disabled={props.deleteRecordDisabled || disableInputs}>
                        { props.deleteRecordDisabled  && <img src={DisabledTrashCan} alt="trash can"/> }
                        { !props.deleteRecordDisabled && <img src={TrashCan} alt="trash can"/> }
                        <div className="delete-record-text">Delete Record</div>
                    </button>
                </div>
                <button className="save-record-button" onClick={saveRecord} disabled={!shouldSaveRecordButtonBeEnabled() || disableInputs}>
                    Save Record
                </button>
            </div>
        </div>
    );

    const savedCertificationHTML = (
        <div className="certification-item">
            <div className="record-header-text">{certificationTitle} {props.certification.verified && <img src={Verified} className="verified-header-checkmark" alt="green checkmark"/>} </div>
            <div className="saved-certification-item-body saved">
                <div className="certification-item-saved-title">
                    {props.certificationTypes.find((type) => type.certTypeId === props.certification?.certificationTypeId)?.certName}
                </div>
                { certificationHasExpiration() && <div className="certification-item-saved-expiration">{props.certification?.expirationDate ? props.certification?.expirationDate.toLocaleDateString() : ""}</div> }
                {props.certification.verified && <div className="verified-text">
                    <img className="verified-text-checkmark" src={Verified} alt="Verified Record Icon" data-testid="verified-green-check"/>
                    <div>TRS has verified this record. It cannot be deleted.</div> 
                </div>
                }
            </div>
            {
                !props.certification.verified &&
                <div className="delete-record-button-container">
                <button data-testid="delete-button" className="delete-record-button" onClick={deleteRecord} disabled={props.deleteRecordDisabled}>
                    { props.deleteRecordDisabled  && <img src={DisabledTrashCan} alt="trash can"/> }
                    { !props.deleteRecordDisabled && <img src={TrashCan} alt="trash can"/> }
                    <div className="delete-record-text">Delete Record</div>
                </button>
            </div>
            }
            
        </div>
    );

    return(
    <div id="CertificationItem">
        {props.certification?.saved ? savedCertificationHTML : editableCertificationHTML}
    </div>)
}
