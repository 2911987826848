import { useEffect, useState } from "react";
import { HcpProfileData } from "../tp-core-types/HcpProfileData"
import displayAsDate from "../utilities/displayAsDate";
import "./ProfileEducation.scss"
import ProfileGrid from "./ProfileGrid/ProfileGrid";
import ProfileGridMobile from "./ProfileGrid/ProfileGridMobile";
import { HcpDegree } from "../tp-core-types/HcpDegree";

type Props = {
    profileData: HcpProfileData
}

export default function ProfileEducation(props: Props){
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])
    function sortStringDate(a: HcpDegree, b: HcpDegree){
        const dateA = new Date(a.degreeDate);
        const dateB = new Date(b.degreeDate);
        return dateA < dateB ? 1 : -1;
    }
    return(
    <div id="ProfileEducation">
        {isMobile && 
            <ProfileGridMobile 
                parentContainerHeader={"Education"} 
                noDataMessage="No education history to display. Contact your recruiter for more information."
                containers = {
                    props.profileData.degrees.sort(sortStringDate).map(d => { 
                            return {
                                header: d.school + " " + d.degreeType,
                                data: {
                                    id: d.degreeId?.toString(),
                                    headers: ["Field of Study", "Location", "Graduation Date"],
                                    items: [
                                        d.degreeField || "-", d.location, 
                                        d.degreeDate && <div data-testid="degree-date">{displayAsDate(d.degreeDate)}</div>
                                        ]
                                }
                            }
                        }
                    )
                }
            />
        }

        {!isMobile && 
            <ProfileGrid 
                containerHeader={"Education"} 
                noDataMessage="No education history to display. Contact your recruiter for more information."
                itemHeaders={["Type", "Field of Study", "School", "Location", "Graduation Date"]} 
                items={
                    props.profileData.degrees.sort(sortStringDate).map((degree) => {
                        return { rows: [
                            degree.degreeType, 
                            degree.degreeField, 
                            degree.school, 
                            degree.location, 
                            degree.degreeDate && <div data-testid="degree-date">{displayAsDate(degree.degreeDate)}</div>
                        ] as string[]}
                    })}
            />
        }
    </div>
    )
}