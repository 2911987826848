import { useEffect, useRef, useState } from "react";
import "./EmploymentHistory.scss";
import NavigationHeader from "../navigation-header/NavigationHeader";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { ElectronicMedicalRecordsSystem, EmploymentHistoryGetResult, EmploymentHistoryOptionsResult } from "../../tp-core-types/EmploymentHistory";
import EmploymentHistoryRecord from "./EmploymentHistoryRecord";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import plusSign from "../../assets/plus_sign.svg";
import { EmploymentHistory as EmploymentHistoryType } from "../../tp-core-types/EmploymentHistory";
import { HcpWebApplicationCompletedStep } from "../../tp-core-types/HcpWebApplicationCompletedStep";

export default function EmploymentHistory() {
    const [options, setOptions] = useState<EmploymentHistoryOptionsResult>();
    const [newRecordDisabled, setNewRecordDisabled] = useState<boolean>(true);
    const [employmentHistoryRecords, setEmploymentHistoryRecords] = useState<EmploymentHistoryType[]>([]);
    const [isEditButtonEnabled, setIsEditButtonEnabled] = useState<boolean>(true);
    const newRecordButtonId = "newRecordButton";
    const employmentHistoryRecordsPreviousLength = useRef<number>(0);

    useEffect(() => {
        TPCoreAPI.getEmploymentHistoryOptions()
        .then((options: EmploymentHistoryOptionsResult) => {
            sortEMRs(options.electronicMedicalRecordsSystems)
            setOptions(options);
        })        
        .catch(() => {
            return;
        });

        TPCoreAPI.getEmploymentHistory()
            .then((history: EmploymentHistoryGetResult) => {
                if (history.records.length > 0) {
                    setEmploymentHistoryRecords(sortEmploymentRecords(history.records));
                    setNewRecordDisabled(false);
                }
                else {
                    createNewRecord();
                }
            })
            .catch(() => {
                return;
            }); 
    }, []);

    useEffect(() => { 
        if(employmentHistoryRecords.length > employmentHistoryRecordsPreviousLength.current) {
            scrollToTop();
        }
        employmentHistoryRecordsPreviousLength.current = employmentHistoryRecords.length;
    }, [employmentHistoryRecords.length]);

    const updateEditRecordButton = (stateUpdate: boolean) => {
        setIsEditButtonEnabled(stateUpdate);
        setNewRecordDisabled(!stateUpdate);
    }

    const sortEmploymentRecords = (records: EmploymentHistoryType[]) => {
        const currentJobs = records.filter(r => !r.endMonth && !r.endYear && r.employmentHistoryId !== null);
        const previousJobs = records.filter(r => r.endMonth && r.endYear && r.employmentHistoryId !== null);
        const emptyJob = records.filter(r => r.employmentHistoryId === null);

        previousJobs.sort((a, b) => {
            const recordADate = new Date(Number(a.endYear), Number(a.endMonth), 1);
            const recordBDate = new Date(Number(b.endYear), Number(b.endMonth), 1);

            return recordADate < recordBDate ? 1 : -1
        })

        currentJobs.sort((a, b) => {
            const recordADate = new Date(a.startYear, a.startMonth, 1);
            const recordBDate = new Date(b.startYear, b.startMonth, 1);

            return recordADate < recordBDate ? 1 : -1
        })
        return (emptyJob.concat(currentJobs.concat(previousJobs))).reverse();
    }

    const updateEmploymentHistoryRecords = async (record: EmploymentHistoryType) => {    
        let indexOfRecordToUpdate;
        
        let found = employmentHistoryRecords.find(r=> {
            return r.employmentHistoryId === record.employmentHistoryId
        });

        if(found){
            indexOfRecordToUpdate = employmentHistoryRecords.indexOf(found);
            found = record; 
        } else {
            const nullRecord = employmentHistoryRecords.find(r => r.employmentHistoryId == null);
            if (nullRecord !== undefined){
                indexOfRecordToUpdate = employmentHistoryRecords.indexOf(nullRecord);
            }   
        }
        
        const newList = [...employmentHistoryRecords]
        if(indexOfRecordToUpdate !== undefined) {
            if(record.deleted) {
                newList.splice(indexOfRecordToUpdate, 1);
                if(employmentHistoryRecords.length >= 1) {
                    updateEditRecordButton(true);
                }
            }
            else {
                newList[indexOfRecordToUpdate] = record
            }
        }

        const shouldDisable = newList.filter(r => r.employmentHistoryId === null).length > 0
        setNewRecordDisabled(shouldDisable);

        await setEmploymentHistoryRecords(sortEmploymentRecords(newList));
    }

    const scrollToTop = () => {       
        setTimeout(()=>
            {
                const helperText = document.getElementsByClassName("application-helper-text")[0];
                helperText.scrollIntoView({block: 'center'})
            }, 10);
    };

    useEffect(() => {
        setTimeout(()=>{
            const helperText = document.getElementsByClassName("record-title")[0];
            if(helperText) {
                helperText.scrollIntoView()
            }
        }, 200);
    }, [isEditButtonEnabled])

    function sortEMRs(electronicMedicalRecordsSystems: ElectronicMedicalRecordsSystem[]) {
       electronicMedicalRecordsSystems.sort((a, b) => {
            const isPopularSortValue = a.isPopular === b.isPopular ? 0 : (a.isPopular ? -1 : 1);
            const nameSortValue = a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            return isPopularSortValue || nameSortValue;
        });
    }

     async function createNewRecord() {
        setNewRecordDisabled(true);
        const newEmploymentHistoryRecord: EmploymentHistoryType = {
            employmentHistoryId: null,
            employmentTypeId: 0,
            facilityName: "",
            city: "",
            stateId: 0,
            startMonth: 0,
            startYear: 0,
            endMonth: null,
            endYear: null,
            professionId: 0,
            agencyName: null,
            isCharge: false,
            unitsFloated: "",
            shiftPerMonth: null,
            specialties: [],
            emrs: [],
            deleted: false,
            isVerified: false
        }
        

        const newList = [...employmentHistoryRecords, newEmploymentHistoryRecord]
        await setEmploymentHistoryRecords(newList);

    }
    
    async function setEmploymentStepCompleted() {
        const completedStep: HcpWebApplicationCompletedStep = {
            StepName: "Employment",
            StepComplete: true
        }
        await TPCoreAPI.postWebApplicationStep(completedStep)
    }

    return (
        <div id="EmploymentHistory">
            <NavigationHeader selectedStep={NavigationSteps.Employment} showNavigationBar={true} />
            <div className="application-header">
                <PreviousNextButtonHeader
                    selectedStep={NavigationSteps.Employment}
                    isFormComplete={!newRecordDisabled}
                    callbackOnSave={setEmploymentStepCompleted}
                />
                <div className="title">
                    Employment History
                    <button id={newRecordButtonId} data-testid="new-record-button" className="new-record-button" hidden={newRecordDisabled || !isEditButtonEnabled} disabled={newRecordDisabled} onClick={createNewRecord}>
                        <div className="new-record-button-content">
                            <img src={plusSign} alt=""/>
                            <div>New Record</div>
                        </div>
                    </button>
                </div>
            </div>
            <div className="application-page-container" data-testid='scroll-container'>
                <div className="application-helper-text">Include relevant clinical employment history for the last seven years. Only list per diem employment if it filled a gap of 30+ days.</div>
                <div className="records-container" data-testid="records-container-id">
                    {                        
                        employmentHistoryRecords.map((record: EmploymentHistoryType, index: number) => {
                            return (
                                <EmploymentHistoryRecord
                                    isEditButtonEnabled={isEditButtonEnabled} 
                                    isDeleteButtonEnabled={employmentHistoryRecords.length > 1 && !record.isVerified} 
                                    key={record.employmentHistoryId !== null ? record.employmentHistoryId : "new component"} 
                                    options={options} 
                                    data={record} 
                                    updateEmploymentHistoryRecords={updateEmploymentHistoryRecords} 
                                    recordTitle={"Employment History " + (index+1)}
                                    updateEditButton={updateEditRecordButton}
                                />
                            );
                        })
                    }                    
                </div> 
            </div>
        </div>
    );
}
