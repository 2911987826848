import { SetStateAction, memo, useContext, useEffect, useRef, useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { JobSearchResultsModel } from "../tp-core-types/JobSearchResultsModel";
import JobSearchCard from "../job-search-card/JobSearchCard";
import { JobModel } from "../tp-core-types/JobModel";
import "./JobSearchResults.scss";
import JobSearchDetails from "../job-search-details/JobSearchDetails";
import { SearchPageUrlParameters } from "../job-search-page/SearchPageUrlParameters";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import LoginModal from "../login-modal/LoginModal";
import { PloppableContext } from "../utilities/context/PloppableContextProvider";
import { TRSHeaderContext } from "../utilities/context/TRSHeaderContextProvider";
import { JobSearchMatchType } from "../tp-core-types/JobSearchMatchType";

type Props = {
    urlParameters: SearchPageUrlParameters;
};

export default memo(function JobSearchResults(props: Props) {
    const userInfoModel = useContext(UserInfoModel);
    const [jobSearchResults, setJobSearchResults] = useState(null as JobSearchResultsModel | null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedJob, setSelectedJob] = useState(null as JobModel | null);
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [loginModelText, setloginModelText] = useState("Your session has expired, please log in again.");
    const [showAsLoginForm, setShowAsLoginForm] = useState(true);
    const isPloppable = useContext(PloppableContext).isPloppable;
    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    const {setIsTRSHeaderVisible} = useContext(TRSHeaderContext)

    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout);
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout);
        };
    }, []);

    useEffect(() => {
        getJobSearchResults(props);
    }, [props]);

    const handleLogout = async () => {
        setShowAsLoginForm(true)
        setloginModelText("Your session has expired, please log in again.")
        setShowLoginModalVisibility(true);
    };

    const handleLogin = async () => {
        setShowLoginModalVisibility(false);
    };

    const getJobSearchResults = async (props: Props) => {
        await TPCoreAPI.getJobSearchResults(
            props.urlParameters.professionId ?? "13",
            props.urlParameters.specialtyIds,
            props.urlParameters.stateIds,
            props.urlParameters.cityIds,
            props.urlParameters.shiftCategoryId,
            props.urlParameters.numberOfShifts
        ).then((results: JobSearchResultsModel) => {
            sortJobResults("default", results);
            return results;
        }).then((results: JobSearchResultsModel) => {
            setIsLoading(false);
    
            if (results && results.jobList && results.jobList.length > 0 && !isMobile) {
                setSelectedJob(results.jobList[0]);
            } 
        })
    };

    const sortJobResults = (sortType: string | null, results: JobSearchResultsModel | null) => {
        if (!results) return;

        switch (sortType) {
            case "default": {
                results.jobList.sort((a: JobModel, b: JobModel) => {
                    const aExact = a.matchTypes?.location === JobSearchMatchType.ExactMatch && a.matchTypes.specialty === JobSearchMatchType.ExactMatch && a.matchTypes.shiftsPerWeek === JobSearchMatchType.ExactMatch
                    const bExact = b.matchTypes?.location === JobSearchMatchType.ExactMatch && b.matchTypes.specialty === JobSearchMatchType.ExactMatch && b.matchTypes.shiftsPerWeek === JobSearchMatchType.ExactMatch

                    return (
                        Number(bExact) - Number(aExact) ||
                        ((a["grade" as keyof JobModel] ?? 99) as number) - ((b["grade" as keyof JobModel] ?? 99) as number) ||
                        (b["grossWeeklyPay" as keyof JobModel] as number) -
                            (a["grossWeeklyPay" as keyof JobModel] as number)
                    );
                });

                break;
            }
            case "pay-descending": {
                results.jobList.sort((a: JobModel, b: JobModel) => {
                    return (
                        (b["grossWeeklyPay" as keyof JobModel] as number) -
                        (a["grossWeeklyPay" as keyof JobModel] as number)
                    );
                });

                break;
            }
            case "shift-descending": {
                results.jobList.sort((a: JobModel, b: JobModel) => {
                    const shiftNumberA = a.shift.split("x")[0];
                    const shiftNumberB = b.shift.split("x")[0];
                    return (
                        (shiftNumberB as unknown as number) - (shiftNumberA as unknown as number) ||
                        (b["grossWeeklyPay" as keyof JobModel] as number) -
                            (a["grossWeeklyPay" as keyof JobModel] as number)
                    );
                });

                break;
            }
            case "shift-ascending": {
                results.jobList.sort((a: JobModel, b: JobModel) => {
                    const shiftNumberA = a.shift.split("x")[0];
                    const shiftNumberB = b.shift.split("x")[0];

                    return (
                        (shiftNumberA as unknown as number) - (shiftNumberB as unknown as number) ||
                        (b["grossWeeklyPay" as keyof JobModel] as number) -
                            (a["grossWeeklyPay" as keyof JobModel] as number)
                    );
                });

                break;
            }
        }
        setJobSearchResults({ ...results });
    };

    const divRef = useRef<HTMLDivElement>(null);

    const handleJobClick = (job: JobModel) => {
        if (isPloppable && isMobile) {
            window.location.href = `${window.location.origin}/careers/find-a-job/${job.jobId}?returnUrl=${encodeURIComponent(window.location.href)}`
        } 
        else{
            setSelectedJob(job);
            setIsTRSHeaderVisible(false);
        }
    };

    const clearJobSelection = () => {
        setSelectedJob(null);
        setIsTRSHeaderVisible(true);
    };

    const handleSortOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        sortJobResults(event.target.selectedOptions[0].getAttribute("data-sort"), jobSearchResults);

        if (!isMobile) {
            setSelectedJob(jobSearchResults?.jobList[0] as SetStateAction<JobModel | null>);
        }

        divRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    };

    const noMatchesFound = ()=>{
        return <>
        <div className="noMatchesFound">
            <p className="titleLine">Hmm… we didn't find a match for your search.</p>
            <p className="subtitle">Please adjust your filters to broaden your search.</p>
            <p className="dontWorry">Don't worry if you can't find the ideal position; jobs are updated daily.</p>

            { userInfoModel.token === "" && <>
                <p>You can create an account, and we'll remind you to return.</p>
                <button className="bold registerButtonContainer" data-testid="register-for-account-button" onClick={(e)=> {
                    setloginModelText("Log into your account or sign up below");
                    setShowAsLoginForm(false);
                    setShowLoginModalVisibility(true);
                    }}>
                    Create an Account
                </button>
                </>
            }
            <p>
                You can also call us at <b>(888) 987-8233</b> during regular business hours, Monday through Friday, 8
                a.m. to 5 p.m. Central Time, to speak with a recruiter about your options.
            </p>
        </div>
        </>
    }

    return (
        <>

            <div id="JobSearchResults">
                <div className="left-pane-container">
                    <div className="job-details-left-pane">
                        <div className="left-pane-header">
                            <div className="left-pane-header-job-openings">
                                {jobSearchResults
                                        ? `${jobSearchResults.jobCount} Unique ${
                                            jobSearchResults.jobCount === 1 ? "Job" : "Jobs"
                                        } (${jobSearchResults.positionCount} Total ${
                                            jobSearchResults.positionCount === 1 ? "Opening" : "Openings"
                                        })`
                                        : "Loading..."}
                            </div>
                            <div className="left-pane-header-filters">
                                <label className="sort-label">Sort</label>
                                <select
                                    className="sort-options"
                                    data-testid="sort-options"
                                    onChange={handleSortOptionChange}
                                    disabled={isLoading}
                                >
                                    <option className="default" value="grossWeeklyPay" data-sort="default">
                                        Preference: Exact to Close Match
                                    </option>
                                    <option className="pay" value="grossWeeklyPay" data-sort="pay-descending">
                                        Pay: Highest to Lowest
                                    </option>
                                    <option className="shiftHighestToLowest" value="shift" data-sort="shift-descending">
                                        Number of Shifts: High to Low
                                    </option>
                                    <option className="shiftLowestToHighest" value="shift" data-sort="shift-ascending">
                                        Number of Shifts: Low to High
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div ref={divRef} className="jobListStyle" data-testid="job-list">
                                {jobSearchResults &&
                                    jobSearchResults.jobList.map((job) => {
                                        return (
                                            <JobSearchCard
                                                isArchived={false}
                                                job={job}
                                                key={job.jobId}
                                                handleJobClick={handleJobClick}
                                                selected={job.jobId === selectedJob?.jobId}
                                            ></JobSearchCard>
                                        );
                                    })}
                                {jobSearchResults?.jobCount === 0 && isMobile && noMatchesFound()}
                                {jobSearchResults?.jobCount === 0 && !isMobile && (
                                    <div className="noMatchesFound">No results to show</div>
                                )}
                        </div>
                    </div>
                </div>
                {selectedJob && (
                    <JobSearchDetails
                        neverShowActionButtons={false}                        
                        jobId={selectedJob.jobId}
                        returnToResultsCallback={clearJobSelection}
                        submittedJobDetails={null}
                    />
                )}
                {jobSearchResults?.jobCount === 0 && !isMobile && (
                    <div
                        className={
                            userInfoModel.token !== "" ? "right-pane-logged-in" : "right-pane-with-secondary-header"
                        }
                        data-testid="right-pane"
                    >
                    {noMatchesFound()}
                    </div>
                )}
            </div>
            <LoginModal
                isLoginForm={showAsLoginForm}
                isVisible={showLoginModalVisibility}
                setVisibility={(value: boolean) => setShowLoginModalVisibility(value)}
                successfulLogin={handleLogin}
                headerText={loginModelText}
                canBeDismissed={true}
            ></LoginModal>
        </>
    );
});
