import { useEffect, useState } from "react";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import "./ProfilePage.scss";
import { HcpProfileData } from "../tp-core-types/HcpProfileData";
import ProfileBiodata from "./ProfileBiodata";
import ProfileLicenseInfo from "./ProfileLicenseInfo";
import ProfileEducation from "./ProfileEducation";
import ProfileEmploymentHistory from "./EmploymentHistory/ProfileEmploymentHistory";
import ProfileCertifications from "./Certifications/ProfileCertifications";
import ProfileReferences from "./References/ProfileReferences";

export default function ProfilePage() {
    const [profileData, setProfileData] = useState<HcpProfileData>()
    const getData = async () => {
        const profileData: HcpProfileData = await TPCoreAPI.getHcpProfileData();

        setProfileData(profileData);
    }

    useEffect(()=>{
        getData();
    },[])

    function refreshProfile(): void {
        getData();
    }

    return (
        <div id="ProfilePage">
            <GenericPageHeader title="Profile"></GenericPageHeader>
            { profileData &&
                <div className="page-container">
                    <ProfileBiodata profileData={profileData} />
                    <ProfileLicenseInfo profileData={profileData} />
                    <ProfileEducation profileData={profileData} />
                    <ProfileEmploymentHistory profileData={profileData} onChange={refreshProfile}/>
                    <ProfileCertifications profileData={profileData} />
                    <ProfileReferences profileData={profileData} onChange={refreshProfile} />
                </div>
            }
        </div>
    )
}