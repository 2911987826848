import { ShiftCategory } from "../tp-core-types/ShiftCategory";
import sun_icon from "../assets/sun.svg";
import moon_icon from "../assets/moon.svg";
import sun_moon_icon from "../assets/sun-moon.svg";

export default function getShiftDisplay(shiftCategory: number) {
    switch (shiftCategory) {
        case ShiftCategory.Days:
            return {"icon": sun_icon, "type": "Days", "detailText": "Day Shift" };
        case ShiftCategory.Nights:
            return {"icon": moon_icon, "type": "Nights", "detailText": "Night Shift" };
        case ShiftCategory.DaysOrNights:
            return {"icon": sun_moon_icon, "type": "Days or Nights", "detailText": "Day or Night Shift"};
        default:
            return {"icon": sun_moon_icon, "type": "Days or Nights", "detailText": "Day or Night Shift"};
    }
}