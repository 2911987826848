import { SyntheticEvent, useState } from "react";
import { DashboardAssignment } from "../../../tp-core-types/DashboardOnboardingContractOfferResult";
import DateService from "../../../utilities/dateService";
import displayAsDate from "../../../utilities/displayAsDate";
import pdf_icon from "../../../assets/pdf_icon.png";
import "./DashboardAssignments.scss";
import { useNavigate } from "react-router-dom";
import TPCoreAPIDocuments from "../../../tp-core-api-common/TPCoreAPIDocuments";

const getIsMobile = (): boolean => {
    return window.matchMedia("(max-width: 1250px)").matches;
}

type Props = {
    dashboardAssignment: DashboardAssignment;
}
function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
    event.currentTarget.src =
        "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
    }
export default function DashboardAssignments(props:Props){
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const HCPA_DOCUMENTMASTERID = 54;
    const navigate = useNavigate();
    const hasContract = props.dashboardAssignment.isSigned;
    const isContractSignable = !hasContract && props.dashboardAssignment.isSignable;
    async function openFile(){
        let waitingPage;
        try{
            waitingPage = window.open("document-loading.html")!;
            const myData= (await TPCoreAPIDocuments.getSignedContractDocument(props.dashboardAssignment.contractId, HCPA_DOCUMENTMASTERID));
            const file = window.URL.createObjectURL(myData);
            waitingPage.location.href = file;
        }
        catch{
            waitingPage?.close();
        }        
    }
    const handleGetContract = async () => {
        navigate("/review-and-sign-contract", {state: {contractId: props.dashboardAssignment.contractId, documentMasterId: HCPA_DOCUMENTMASTERID, returnLocation: "/dashboard"}});
    }
    return (
        <div id='dashboard-assignment' data-testid="dashboard-assignment-main">
            <div className="container-header">
                <div>Current assignment</div>
                <div className="start-end-dates">{DateService.getMonthAbbrWithDate(props.dashboardAssignment.startDate)} - {DateService.getMonthAbbrWithDate(props.dashboardAssignment.endDate)}</div>
            </div>
            <div className="assignment-body">
                <img 
                    className="facility-image"
                    src={props.dashboardAssignment.facility.imageUrl}
                    onError={addDefaultSrc}
                    alt={props.dashboardAssignment.facility.facilityName}
                    data-testid="facility-image"
                />
                <div className="assignment-info">
                    <div className="assignment-info-header"> {`${props.dashboardAssignment.profession} (${props.dashboardAssignment.specialty}) - ${props.dashboardAssignment.facility.city}, ${props.dashboardAssignment.facility.state} `}</div>
                    <div className="facility-name">{props.dashboardAssignment.facility.facilityName}</div>
                    <div className="facility-address">{props.dashboardAssignment.facility.address}, {props.dashboardAssignment.facility.city}, {props.dashboardAssignment.facility.state} {props.dashboardAssignment.facility.zip}</div>
                    <div className="duration-start"> {props.dashboardAssignment.duration} Weeks. Starts {displayAsDate(props.dashboardAssignment.startDate.toString())}</div>
                    {hasContract &&
                    <div data-testid="signed-contract-link" className="signed-contract-container" onClick={() => openFile()}>
                        <img alt="signed contract" className="pdf-icon" src={pdf_icon}></img>
                        <div className="pdf-label">Signed Contract</div>
                    </div>
                    }
                    { isContractSignable &&  
                    <div id="SignContractButton" className="sign-contract-button-container">
                        <button className="sign-contract-button" onClick={() => handleGetContract()}>Review & Sign Contract</button>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}