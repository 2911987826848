export default function displayAsDate(value: string) {
    if(!value) return "";

    //Chop off the timezone component and just parse the date(time) like it was created in this timezone.
    //Ex:
    //2024-01-02T00:00:00-05:00 becomes 2024-01-02T00:00:00
    if( (new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}").test(value)))
    {
      value = value.substring(0,19);
    }
    return new Intl.DateTimeFormat("en-US", {year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(value));
}

