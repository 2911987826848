import "./ProfileGrid.scss"
import { ProfileNewButton } from "./ProfileNewButton";
import plusSignWhite from "../../assets/plus_sign_white.svg";

type Props = {
    containerHeader: string,
    itemHeaders: string[],
    items: {rows: (string | JSX.Element | null | undefined)[]}[],
    noDataMessage?: string,
    button?: ProfileNewButton
}

export default function ProfileGrid(props: Props) {
    if(props.noDataMessage?.length === 0) props.noDataMessage = `No ${props.containerHeader} data available yet.`;
    return (
        <>
            <div id="ProfileGrid">
                <div className="profile-container">
                    <div className="container-header">
                        <div>{props.containerHeader}</div>
                        {props.button !== undefined && 
                        <button className="header-button" onClick={props.button.callback}>
                            <div className="header-button-content">
                                <img src={plusSignWhite} alt=""/>
                                <div className="header-button-label-text">{props.button.label}</div>
                            </div>
                        </button>}
                    </div>
                    {(props.items.length === 0) &&
                            <div className="no-data">{props.noDataMessage}</div>
                    }
                    {props.items.length > 0 &&
                    <div className="profile-grid" style={{ "--numberOfColumns": props.itemHeaders.length } as React.CSSProperties} data-testid="profile-grid">
                        {props.itemHeaders.map((header, i) => {
                            return(
                                <div key={"header" + i} className="profile-table-header" data-testid="profile-table-header">{header}</div>
                            )
                        })}
                        {props.items.map((item, i) => { return item.rows.map((value, j) => 
                            { 
                                return (
                                    <div key={"item" + i + j} className="profile-table-cell" data-testid="profile-table-cell">{value ? value : "-"} </div>
                                )
                            })})
                        }
                    </div>
                    }
                </div>
            </div>
        </>
    )
}