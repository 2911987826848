import { ChangeEvent, useEffect, useState } from "react";
import { ReferenceFormFacilityOption } from "../../tp-core-types/ReferenceFormFacilityOption";
import { Validator } from "../../validator/Validator";
import ErrorMesage from "../../error-message/inlilne-error-message/ErrorMessage";
import { ReferenceFormCallbackPayload } from "../../tp-core-types/HcpReferences";
import "./reference-form.scss";

export type Props = {
    referenceFormPayload: ReferenceFormCallbackPayload;
    facilities: ReferenceFormFacilityOption[];
    updateFormData: (form: ReferenceFormCallbackPayload) => void;
};

export default function ReferenceForm(props: Props) {
    const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
    const [isValidEmail, setValidEmail] = useState<boolean>(true);
    const [historyId, setHistoryId] = useState("");

    const [phoneNumber, setPhoneNumber] = useState(props.referenceFormPayload.phoneNumber);

    useEffect(() => {
        setPhoneNumber(props.referenceFormPayload.phoneNumber);
    }, [props.referenceFormPayload.phoneNumber]);

    useEffect(() => {
        setHistoryId(props.referenceFormPayload.historyId);
    }, [props.referenceFormPayload.historyId, props.facilities]);

    const validateEmailAutoFill = (e: ChangeEvent<HTMLInputElement>) => {
        props.updateFormData({ ...props.referenceFormPayload, email: e.target.value });
        validateEmail(e);
    };

    const validateEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const validState = Validator.validateEmail(e.target.value) || e.target.value === "";
        setValidEmail(validState);
        props.updateFormData({ ...props.referenceFormPayload, email: e.target.value });
    };
    const validatePhone = (e: ChangeEvent<HTMLInputElement>) => {
        const validPhone = Validator.validatePhoneNumber(e.target.value);
        setIsValidPhone(validPhone);
        if (validPhone) {
            setPhoneNumber(e.target.value);
            props.updateFormData({ ...props.referenceFormPayload, phoneNumber: e.target.value });
        } else {
            props.updateFormData({ ...props.referenceFormPayload, phoneNumber: "" });
        }
    };

    const stripNonNumbers = (e: ChangeEvent<HTMLInputElement>) => {
        const regex = new RegExp(/^\d+$/);
        if (regex.test(e.target.value) || e.target.value === "") {
            setPhoneNumber(e.target.value);
        }
        if (e.target.value.length === 10) validatePhone(e);
    };

    const updateReferenceFormPhoneType = (phoneTypeString: string) => {
        props.updateFormData({ ...props.referenceFormPayload, phoneType: phoneTypeString });
    };

    return (
        <>
            <div className="reference-form-container">
                <label htmlFor={`ref-facility-${props.referenceFormPayload.referenceNumber}`}>Select Facility*</label>
                <select
                    id={`ref-facility-${props.referenceFormPayload.referenceNumber}`}
                    value={historyId ? historyId : ""}
                    onChange={(e) => {
                        props.updateFormData({ ...props.referenceFormPayload, historyId: e.target.value });
                    }}
                >
                    <option></option>
                    {props.facilities.map((f) => (
                        <option key={f.historyId} value={f.historyId}>
                            {f.facilityInformation}
                        </option>
                    ))}
                </select>

                <label htmlFor={`ref-first-name-${props.referenceFormPayload.referenceNumber}`}>First Name*</label>
                <input
                    type="text"
                    id={`ref-first-name-${props.referenceFormPayload.referenceNumber}`}
                    autoComplete="dummy-value-to-disable"
                    onChange={(e) => {
                        props.updateFormData({ ...props.referenceFormPayload, firstName: e.target.value });
                    }}
                    value={props.referenceFormPayload.firstName}
                    name="firstName"
                ></input>

                <label htmlFor={`ref-last-name-${props.referenceFormPayload.referenceNumber}`}>Last Name*</label>
                <input
                    type="text"
                    id={`ref-last-name-${props.referenceFormPayload.referenceNumber}`}
                    autoComplete="dummy-value-to-disable"
                    onChange={(e) => {
                        props.updateFormData({ ...props.referenceFormPayload, lastName: e.target.value });
                    }}
                    value={props.referenceFormPayload.lastName}
                    name="lastName"
                ></input>

                <label htmlFor={`ref-title-${props.referenceFormPayload.referenceNumber}`}>Job Title*</label>
                <input
                    type="text"
                    id={`ref-title-${props.referenceFormPayload.referenceNumber}`}
                    onChange={(e) => {
                        props.updateFormData({ ...props.referenceFormPayload, title: e.target.value });
                    }}
                    value={props.referenceFormPayload.title}
                ></input>

                <label htmlFor={`ref-phone-${props.referenceFormPayload.referenceNumber}`}>Phone Number*</label>
                <input
                    type="text"
                    id={`ref-phone-${props.referenceFormPayload.referenceNumber}`}
                    autoComplete="dummy-value-to-disable"
                    onChange={(e) => {
                        stripNonNumbers(e);
                    }}
                    value={phoneNumber}
                    onBlur={validatePhone}
                ></input>
                {!isValidPhone && <ErrorMesage message="Please enter a 10 digit phone number"></ErrorMesage>}

                <div className="ref-radio-collection">
                    <label htmlFor={`ref-phone-type-${props.referenceFormPayload.referenceNumber}`}>
                        Phone Number Type*
                    </label>
                    <div className="radio-buttons">
                        <div className="input-radio">
                            <input
                                data-testid={`ref-phone-type-${props.referenceFormPayload.referenceNumber}-mobile`}
                                id={`ref-phone-type-${props.referenceFormPayload.referenceNumber}-mobile`}
                                onChange={(e) => updateReferenceFormPhoneType("Mobile")}
                                type="radio"
                                checked={props.referenceFormPayload.phoneType === "Mobile"}
                                value="Mobile"
                                name={`phoneType-${props.referenceFormPayload.referenceNumber}`}
                            />
                            Mobile
                        </div>

                        <div className="input-radio">
                            <input
                                data-testid={`ref-phone-type-${props.referenceFormPayload.referenceNumber}-office`}
                                onChange={(e) => updateReferenceFormPhoneType("Office")}
                                id={`ref-phone-type-${props.referenceFormPayload.referenceNumber}-office`}
                                type="radio"
                                value="Office"
                                checked={props.referenceFormPayload.phoneType === "Office"}
                                name={`phoneType-${props.referenceFormPayload.referenceNumber}`}
                            />
                            Office
                        </div>
                    </div>
                </div>
                <label htmlFor={`ref-email-${props.referenceFormPayload.referenceNumber}`}>Email Address</label>
                <input
                    data-testid={`ref-email-${props.referenceFormPayload.referenceNumber}`}
                    defaultValue={props.referenceFormPayload.email}
                    type="text"
                    id={`ref-email-${props.referenceFormPayload.referenceNumber}`}
                    autoComplete="dummy-value-to-disable"
                    onBlur={validateEmail}
                    onChange={validateEmailAutoFill}
                ></input>
                {!isValidEmail && <ErrorMesage message="Please enter a valid email"></ErrorMesage>}
            </div>
        </>
    );
}
