import { useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import "./ForgotPasswordModal.scss";

type Props = {
    showForgotPasswordModal: (showForgot: boolean) => void;
};

export default function ForgotPasswordModal(props: Props) {
    const [input, setInput] = useState("");
    const [sendPasswordResetCompleted, setSendPasswordResetCompleted] = useState(false);

    function sendResetRequest() {
        TPCoreAPI.sendPasswordReset(input);
        setSendPasswordResetCompleted(true);
    }

    return (
        <div id="ForgotPasswordModal">
            <div className="forgot-container"> 
                {!sendPasswordResetCompleted && (
                <>
                    <div className="forgot-form">
                        <div className="forgot-input">
                            <label htmlFor="emailaddress">Email Address</label>
                        <input
                            type="text"
                            id="emailaddress"
                            data-testid="emailInput"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            ></input>
                        </div>
                    </div>
                    <div className="forgot-footer">
                        <button className="forgot-cancel" onClick={() => props.showForgotPasswordModal(false)}>
                            Cancel
                        </button>
                        <button
                            className="forgot-reset"
                            onClick={() => {
                                sendResetRequest();
                            } }
                        >
                            Reset Password
                        </button>
                    </div>
                </>
                )}
                {sendPasswordResetCompleted && (
                <div>
                    <div className="forgot-password-helper-text">
                        Your submissions has been received. If we have an account matching your email address, you will
                        receive an email with a link to reset your password.
                    </div>
                    <div className="forgot-footer">
                        <button className="forgot-close" onClick={() => props.showForgotPasswordModal(false)}>
                            Close
                        </button>
                    </div>
                </div>   
                )}
            </div>
        </div>
    );
}
