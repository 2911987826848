import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import isArchivedJob from "../utilities/isArchivedJob";
import "./JobSearchBenefitInfoDetails.css";

type Props = {
    jobDetails: JobDetailsModel,
};

export default function JobSearchBenefitInfoDetails (props: Props) {    
    return(
        <div data-testid="benefits-info-container">
            <div data-testid="header" className={isArchivedJob(props.jobDetails)? "header bold archived":"header bold"}>Benefits of a Travel Assignment with TRS Healthcare</div>
            <ul className="benefitsList">
                {props.jobDetails.benefitsOfTrs.map((benefit: string, index: number) => <li key={index} className="benefitsListItem">{benefit}</li>)}
            </ul>
        </div>
    );
}