import React from "react";
import { ReactNode } from "react";

export type PloppableContextType = {isPloppable: boolean}
export const PloppableContext = React.createContext<PloppableContextType>({isPloppable: false})

type Props = {
    ploppable?: boolean
    children: ReactNode
}

export function PloppableContextProvider(props: Props) {
    return (<PloppableContext.Provider value={{isPloppable: props.ploppable ?? false}}>{props.children}</PloppableContext.Provider>)
}