import { SyntheticEvent, useEffect, useState } from "react";
import { DashboardOnboarding } from "../../../tp-core-types/DashboardOnboardingContractOfferResult"
import DateService from "../../../utilities/dateService"
import displayAsDate from "../../../utilities/displayAsDate";
import pdf_icon from "../../../assets/pdf_icon.png";
import TPCoreAPI from "../../../tp-core-api/TPCoreAPI";
import { useNavigate } from "react-router-dom";
import "./DashboardOnboarding.scss"
import TPCoreAPIDocuments from "../../../tp-core-api-common/TPCoreAPIDocuments";

const getIsMobile = (): boolean => {
    return window.matchMedia("(max-width: 1250px)").matches;
}

type Props = {
    dashboardOnbording: DashboardOnboarding
}

function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
    event.currentTarget.src =
        "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
    }
    
export default function DashboardJobOnboarding(props:Props) {
    const HCPA_DOCUMENTMASTERID = 54;
    const navigate = useNavigate();
    const daysUntilStart = DateService.getDaysUntilDate(new Date(props.dashboardOnbording.startDate));
    const hasContract = props.dashboardOnbording.isSigned;
    const isContractSignable = !hasContract && props.dashboardOnbording.isSignable;

    async function openFile(){
        let waitingPage;
        try{
            waitingPage = window.open("document-loading.html")!;
            const myData= (await TPCoreAPIDocuments.getSignedContractDocument(props.dashboardOnbording.contractId, HCPA_DOCUMENTMASTERID));
            const file = window.URL.createObjectURL(myData);
            waitingPage.location.href = file;
        }
        catch{
            waitingPage?.close();
        }
        
    }
    
    const handleGetContract = async () => {
        navigate("/review-and-sign-contract", {state: {contractId: props.dashboardOnbording.contractId, documentMasterId: HCPA_DOCUMENTMASTERID, returnLocation: "/dashboard"}});
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    return (
        <div id="DashboardOnboarding" data-testid="onboarding-container">
            <div className="container-header">
                <div>You are onboarding to this assignment</div>
                <div className="days-until">{daysUntilStart} Days Until Start Date</div>
            </div>
            <div className="onboarding-body">
                <img
                    className="facility-image"
                    src={props.dashboardOnbording.facility.imageUrl}
                    onError={addDefaultSrc}
                    alt="facility"
                    data-testid="facility-image"
                />
                <div className="offer-info">
                    <div className="offer-info-header"> {`${props.dashboardOnbording.profession} (${props.dashboardOnbording.specialty}) - ${props.dashboardOnbording.facility.city}, ${props.dashboardOnbording.facility.state} `}</div>
                    <div className="facility-name">{props.dashboardOnbording.facility.facilityName}</div>
                    <div className="facility-address">{props.dashboardOnbording.facility.address}, {props.dashboardOnbording.facility.city}, {props.dashboardOnbording.facility.state} {props.dashboardOnbording.facility.zip}</div>
                    <div className="duration-start"> {props.dashboardOnbording.duration} Weeks. Starts {displayAsDate(props.dashboardOnbording.startDate)}</div>
                    {hasContract &&
                    <div data-testid="signed-contract-link" className="signed-contract-container" onClick={() => openFile()}>
                        <img alt="signed contract" className="pdf-icon" src={pdf_icon}></img>
                        <div className="pdf-label">Signed Contract</div>
                    </div>
                    }
                    { isContractSignable &&  
                    <div id="SignContractButton" className="sign-contract-button-container">
                        <button className="sign-contract-button" onClick={() => handleGetContract()}>Review & Sign Contract</button>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}